
export enum PermitStatusType {
    ACTIVE = 1,
    NOT_ACTIVE = 2,
    SUSPENDED = 3,
    CANCELED = 4
}

export enum PermitType {

    REGULAR = 0,
    GOLDEN = 1,
    STUDENT = 2,
    HANDICAPPED = 3,
    MUNICIPAL_EMPLOYEE = 4,
    ALL = -1,
}