import React from 'react';
import { useProjectDetailsContext } from '../../../../contexts/ProjectDetailsContext';
import PermitPaymentDetails from './PermitPaymentDetails';
import PermitContectDetails from './PermitContectDetails';
import PermitDatesDetails from './PermitDatesDetails';
import PermitPersonDetails from './PermitPersonDetails';
import PermitGeneralDetails from './PermitGeneralDetails';
import PermitCarDetails from './PermitCarDetails';
import PermitDetails from './PermitDetails';


const ViewPermitForm = () => {

    const { projectDetails } = useProjectDetailsContext();
    const hasPayment = projectDetails.hasPayment;

    return (
        <form>
            <div className='row'>

                <div className='col-12 col-lg-6 mb-3'>
                    <PermitPersonDetails />
                </div>

                <div className='col-12 col-lg-6 mb-3'>
                    <PermitDatesDetails />
                </div>

                <div className='col-12 col-lg-7 mb-3'>
                    <PermitContectDetails />
                </div>

                <div className='col-12 col-lg-5 mb-3'>
                    <PermitGeneralDetails />
                </div>

                <div className='col-12 mb-3'>
                    <PermitCarDetails />
                </div>

                {hasPayment ? (
                    <div className='col-12 mb-3'>
                        <PermitPaymentDetails />
                    </div>
                ) : ''}

                <div className='col-12'>
                    <PermitDetails />
                </div>
            </div>
        </form>
    );
}

/*
<div className='col-12 mb-3'>
    <FilesCard />
</div>
*/

export default ViewPermitForm;