import React, { FC } from 'react';
import { MdDownloadDone } from 'react-icons/md';
import Button from './Button';
import { ButtonColors } from '../../types/button.types';

type CreatePermitBtnType = {
  onClick: (event?: any) => void | Promise<void>,
  show: boolean,
  label?: string,
  color?: ButtonColors,
  disabled?: boolean,
  style?: object,
}

const CreatePermitBtn: FC<CreatePermitBtnType> = ({
  onClick,
  show,
  label = "צור תו",
  color = ButtonColors.PRIMARY,
  disabled = false,
  style = {} as React.CSSProperties
}) => {


  return (
    <Button
      show={show}
      key={'CreatePermitBtn'}
      disabled={disabled}
      theme={color}
      onClick={onClick}
      style={style}
      endIcon={<MdDownloadDone />}
      text={label}
    />

  );
}

export default CreatePermitBtn;