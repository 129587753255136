import React, { FC, ReactNode } from 'react';

type Props = {
    children: ReactNode;
    justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
    flexGrow?: number;
}

const ActionInnerBoxCard: FC<Props> = ({ children, justifyContent = 'flex-start', flexGrow = 0 }) => {

    let cssStyle: React.CSSProperties = {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '0.5rem',
        justifyContent: 'flex-start',
        flexGrow: 0,
    }

    cssStyle.justifyContent = justifyContent || 'flex-start';
    cssStyle.flexGrow = flexGrow || 0;

    return (
        <div style={cssStyle}>{children}</div>
    );
}

export default ActionInnerBoxCard;