import { useState } from "react";
import { useFormik } from "formik";
import { initSendMsgModalFormData } from "../data/default/sendMessageModal.data";
import { useComponentState } from "./useComponentState";
import usePermitRequest from "./api/usePermitRequest.api";
import { useProjectDetailsContext } from "../contexts/ProjectDetailsContext";
import { decodeNCRStringIfNeeded } from "../Helpers/language";
import { sendMessageVatidationSchema } from "../validation/sendMessageVatidationSchema";
import { IMessageCitizenModal } from "../interface/messageCitizenModal.interface";

export const useSendMessageModal = () => {

    const { projectDetails } = useProjectDetailsContext();
    const { componentState, setIsLoading, setIsNotLoading, setDangerAlert, removeAlert } = useComponentState();

    const messageConfig = projectDetails.messageConfig;
    const messageEmailConfig = messageConfig.messageEmailConfig;

    const { sendGeneralMessageApi } = usePermitRequest();
    const formik = useFormik<IMessageCitizenModal>({
        initialValues: { ...initSendMsgModalFormData },
        onSubmit: values => handleSendMessage(values),
        validateOnMount: true,
        validateOnChange: true,
        validationSchema: sendMessageVatidationSchema,
    });

    const { setFieldValue, resetForm } = formik;
    const [isOpenModal, setIsOpenModal] = useState(false);


    function openSendMessageModal(isRequest: boolean, identifier: number, email: string, mobileNum: string) {

        setIsOpenModal(true);
        setFieldValue('isRequest', isRequest);
        setFieldValue('identifier', identifier);
        setFieldValue('email.citizenEmail', email);
        setFieldValue('sms.mobileNum', mobileNum);
        setFieldValue('sendViaEmail', messageConfig.sendingCitizenMsgVia);
        setFieldValue('email.cityEmail', messageEmailConfig.generalEmailConfig.cityEmailAddress);
        setFieldValue('email.emailSubject', decodeNCRStringIfNeeded(messageEmailConfig.generalEmailConfig.subject));
    }

    function closeSendMessageModal() {

        resetForm();
        setIsOpenModal(false);
    }

    async function handleSendMessage(values: IMessageCitizenModal) {

        setIsLoading();

        const emailVals = values.email;
        const dataSent = { ...values };

        dataSent.email.sendCopyToCity = emailVals.sendCopyToCity;

        const response = await sendGeneralMessageApi(dataSent);

        if (response.isFailed) {

            setDangerAlert(response.message);
            return;
        }

        setIsNotLoading();
        closeSendMessageModal();
    }

    function handleClickOptionList(event: React.MouseEvent<HTMLInputElement>) {

        const isChecked = event.currentTarget.checked;
        const value = Number(event.currentTarget.value);
        const name = event.currentTarget.name;

        const arrNames = name.split('.');

        if (arrNames.length !== 2) {
            return;
        }

        const currentList = [...formik.values[arrNames[0]][arrNames[1]]];

        if (isChecked) {

            currentList.push(value);
            setFieldValue(name, [...currentList]);
            return;
        }

        const index = currentList.indexOf(value);
        currentList.splice(index, 1);
        setFieldValue(name, [...currentList]);
    }

    return {
        isOpenModal, openSendMessageModal, closeSendMessageModal,
        componentState, removeAlert, formik, handleClickOptionList
    };
} 