import React from 'react';
import style from './paging.module.css';
import { MdKeyboardArrowRight } from 'react-icons/md';

const PrevPageBtn = ({ disabled, onClick }) => {
    return (
        <button
            className={style.pagingBtn}
            type="button"
            onClick={onClick}
            disabled={disabled}
        >
            <MdKeyboardArrowRight />
        </button>
    );
}

export default PrevPageBtn;