import React from 'react';
import { RiArrowGoBackLine } from 'react-icons/ri';
import Button from '../Button';

const GoBackToListBtn = ({onClick}) => {
    const title = 'חזור לרשימה';

    return (
        <Button text={title} onClick={onClick} endIcon={<RiArrowGoBackLine />} />
    );
}

export default GoBackToListBtn;