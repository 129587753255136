import { IHistoryModalData } from "../../interface/history/IHistoryModalData.interface";

export const initHistoryModalData: IHistoryModalData = {
    isShowHistoryModal: false,
    isRequest: true,
    identifier: -1,
    recordList: [] as any[],
    isShowRecordRow: false,
    currentRecord: {},
    modalStatus: {
        isLoading: false,
        msg: '',
    } 
}