import React from 'react';
import Button from '../../../../../Components/Buttons/Button';
import { VscGitPullRequestGoToChanges } from 'react-icons/vsc';

const BtnOpenCPSModal = ({ onClick }) => {

    return (
        <Button text='שינוי סטטוס' endIcon={<VscGitPullRequestGoToChanges />} onClick={onClick} />
    );
}

export default BtnOpenCPSModal;