import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineFileSearch } from 'react-icons/ai';
import Button from './Button';
import { ButtonSizes } from '../../types/button.types';

type ViewPermitRequestBtnProps = {
    rowDbId: number,
    size?: ButtonSizes
}

const ViewPermitRequestBtn: React.FC<ViewPermitRequestBtnProps> = ({ rowDbId, size =  ButtonSizes.MEDIUM}) => {

    const url = `/permit-request/request-details/${rowDbId}`;

    return (
        <Link to={url}>
            <Button onClick={() => {}} text='צפייה בבקשה' size={size} endIcon={<AiOutlineFileSearch />} />
        </Link>
    );
}

export default ViewPermitRequestBtn;