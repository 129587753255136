import React, { FC } from 'react';
import CheckBtn from '../../../Components/Forms/check/CheckBtn';
import InputErrorMessage from '../../../Components/Forms/InputErrorMessage';
import style from '../sendEmail.module.css';
import { FormikErrors } from 'formik';
import { IMessageCitizenModal } from '../../../interface/messageCitizenModal.interface';

type Props = {
    values: IMessageCitizenModal, 
    errors: FormikErrors<IMessageCitizenModal>, 
    handleClickHasCityEmail: (event: React.MouseEvent<HTMLInputElement>) => void, 
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void, 
    handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
}

const CityEmailRow: FC<Props> = ({ values, errors, handleClickHasCityEmail, handleChange, handleBlur }) => {

    return (
        <div className='row mb-4'>
            <div className='col-12'>
                <p>
                    <CheckBtn
                        inlineFilx={true}
                        name={'email.sendCopyToCity'}
                        isChecked={values.email.sendCopyToCity}
                        value={1}
                        label={`שליחת העתק מהמייל ישלח לכתובת:`}
                        id={'sendCopyToCity'}
                        onClick={handleClickHasCityEmail}
                        key={`sendCopyToCityKey`} />

                    <input
                        className={style.cityEmail}
                        value={values.email.cityEmail}
                        name='email.cityEmail'
                        id='idcityEmail'
                        placeholder='מייל לכיתוב...'
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <InputErrorMessage message={errors?.email && errors.email?.cityEmail && errors.email.cityEmail} />
                </p>
            </div>
        </div>
    )
}

export default CityEmailRow;