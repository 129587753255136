import React from 'react';
import InputDate from '../../../../Components/Forms/DateInput';
import SelectStatusRequest from './SelectStatusRequest';
import SearchBtnLoading from '../../../../Components/Buttons/SearchBtnLoading';
import { useRequestManagmentPageContext } from '../../../../contexts/request/RequestManagmentPageContext';
import InputGroup from '../../../../Components/Forms/InputGroup';
import FormBox from '../../../../Components/Forms/FormBox';
import SelectComparingFiles from './SelectComparingFiles';

const FormFilterManagmentRequestTavim = () => {

    const { componentState, Formik, handleChengeAndOffset } = useRequestManagmentPageContext();

    const { values, handleBlur, handleSubmit } = Formik;

    return (
        <FormBox onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-12 col-xl-10"> 
                    <div className="row">
                        <div className="col-lg-6 col-xl-5">
                            <div className="row">
                                <div className="col-md-6 col-xl-4">
                                    <InputGroup
                                        label={'מספר בקשה'}
                                        name={"requestDBId"}
                                        value={values.requestDBId}
                                        handleBlur={handleBlur}
                                        handleChange={handleChengeAndOffset}
                                    />
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <InputGroup
                                        label={'תעודת זהות'}
                                        name={"personId"}
                                        value={values.personId}
                                        handleBlur={handleBlur}
                                        handleChange={handleChengeAndOffset}
                                    />
                                </div>
                                <div className="col-md-6 col-xl-4">
                                    <InputGroup
                                        label={'מספר רכב'}
                                        name={"carNumber"}
                                        value={values.carNumber}
                                        handleBlur={handleBlur}
                                        handleChange={handleChengeAndOffset} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-7">
                            <div className="row">
                                <div className="col-md-6 col-xl-3">
                                    <SelectStatusRequest
                                        value={values.requestStatus}
                                        name={'requestStatus'}
                                        handleChange={handleChengeAndOffset}
                                        handleBlur={handleBlur}
                                    />
                                </div>

                                <div className="col-md-6 col-xl-3">
                                    <SelectComparingFiles
                                        value={values.comparingFilesStatus}
                                        name={'comparingFilesStatus'}
                                        handleChange={handleChengeAndOffset}
                                        handleBlur={handleBlur}
                                    />
                                </div>

                                <div className="col-md-6 col-xl-3">
                                    <InputDate
                                        value={values.fromDate}
                                        label={'תחילת טווח'}
                                        name={"fromDate"}
                                        handleBlur={handleBlur}
                                        handleChange={handleChengeAndOffset} />
                                </div>
                                <div className="col-md-6 col-xl-3">
                                    <InputDate
                                        value={values.toDate}
                                        label={'סוף טווח'}
                                        name={"toDate"}
                                        handleBlur={handleBlur}
                                        handleChange={handleChengeAndOffset} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 col-xl-2 align-self-end mt-2">
                    <div className='d-flex justify-content-end'>
                        <SearchBtnLoading onClick={handleSubmit} isLoading={componentState.isLoading} />
                    </div>
                </div>
            </div>
        </FormBox>
    );
}

export default FormFilterManagmentRequestTavim;
