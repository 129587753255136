import React, { useState } from 'react';
import ConfirmModal from '../../../../../Components/GeneralModal/ConfirmModal';
import SelectPermitStatus from '../../../../../Components/Forms/select/SelectPermitStatus';

const ChangePermitStatusModal = ({ closingFun, permit, onApprove, isShow }) => {

  // TODO - init state
  const [newStatus, setNewStatus] = useState(permit ? permit.statusTavCode : -1);
  const title = `מספר תו: ${permit ? permit.id : -1}`;

  if (!isShow) {
    return null;
  }

  return (
    <ConfirmModal
      closingFun={closingFun}
      rejsctFunc={closingFun}
      isShow={isShow}
      fonfimFunc={() => onApprove(newStatus, permit.id)}
      title={'שינוי סטטוס תו'}
      content={(
        <div className='container'>
          {title}
          <div className='row'>
            <div className='col-4'>
              <SelectPermitStatus name={'statusTav'} value={newStatus} handleChange={(event) => setNewStatus(event.currentTarget.value)} />
            </div>
          </div>
        </div>
      )} />
  );
}

export default ChangePermitStatusModal;