import React from 'react';
import { BsImage } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import { ButtonColors } from '../../types/button.types';

const ViewFilesBtn = ({ personId, disabled, label }) => {
    const navigate = useNavigate();
    const url = `/files/${personId}`;

    return (
        <Button 
        text={label} 
        key={'ViewFilesBtn'} 
        disabled={disabled} 
        onClick={() => navigate(url)} 
        theme={ButtonColors.PRIMARY} 
        endIcon={<BsImage />} />
    );
}

ViewFilesBtn.defaultProps = {
    disabled: false,
    label: "צפייה בקבצים",
    personId: -1
};

export default ViewFilesBtn;