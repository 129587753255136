import React, { MouseEvent } from 'react';
import SearchBtnLoading from '../../../../Components/Buttons/SearchBtnLoading';
import DateInput from '../../../../Components/Forms/DateInput';
import { useExpiredPermitsPageContext } from '../../../../contexts/permit/ExpiredPermitsPageContext';
import FormBox from '../../../../Components/Forms/FormBox';
import OutlineCheckBtn from '../../../../Components/Forms/check/OutlineCheckBtn';

const FilterExpiredPermits = () => {

    const { componentState, Formik } = useExpiredPermitsPageContext();

        const { errors, values, touched, handleChange,
            handleSubmit, handleBlur, setFieldValue } = Formik;

    return (
        <div className='row'>
            <div className='col'>
                <FormBox onSubmit={handleSubmit}>
    
                        <div className="row">
                            <div className="col-9">
                                <div className="row">
                                    <div className="col col-lg-6 col-xl-4">
                                        <DateInput
                                            value={values.startDate}
                                            label='תאריך התחלה'
                                            name="startDate"
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            required={true}
                                            errorMsg={(touched.startDate && errors.startDate) ? errors.startDate : undefined}
                                        />
                                    </div>
                                    <div className="col col-lg-6 col-xl-4">
                                        <DateInput
                                            value={values.endDate}
                                            label='תאריך סוף'
                                            name="endDate"
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            required={true}
                                            errorMsg={(touched.endDate && errors.endDate) ? errors.endDate : undefined}
                                        />
                                    </div>
                                    <div className="col col-lg-6 col-xl-4 align-self-end">
                                        <OutlineCheckBtn
                                            label={'רק תווים פעילים'}
                                            id={'onlyActivePermits'}
                                            name='onlyActivePermits'
                                            value={'1'}
                                            isChecked={values.onlyActivePermits && values.onlyActivePermits === '1'}
                                            onClick={handleClick}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-3 align-self-end">
                                <div className='d-flex justify-content-end'>
                                    <SearchBtnLoading onClick={handleSubmit} isLoading={componentState.isLoading} />
                                </div>
                            </div>
                        </div>
                </FormBox>
            </div>
        </div>
    );

    function handleClick(event: MouseEvent<HTMLInputElement>) {

        const isChecked = event.currentTarget.checked;
        const value = isChecked ? event.currentTarget.value : '0';

        setFieldValue(event.currentTarget.name, value);
    }
}

export default FilterExpiredPermits;
