import React from 'react';
import { usePermitDetailsPageContext } from '../../../../contexts/permit/PermitDetailsPageContext';
import InputGroup from '../../../../Components/Forms/InputGroup';
import { PaymentMethod } from '../../../../types/paymentMethod.types';
import RadioBtnsContainer from '../../../../Components/Forms/radio/RadioBtnsContainer';
import RadioOutline from '../../../../Components/Forms/radio/RadioOutline';
import FormSection from '../../../../Components/UI/formSection/FormSection';


const PermitPaymentDetails = () => {

    const { Formik } = usePermitDetailsPageContext();
    const { values } = Formik;

    return (
        <FormSection title={"פרטי תשלום"}>
            <div className="row">
                <div className="col">
                    <RadioBtnsContainer label={'צורת תשלום'}>
                        <RadioOutline
                            label='שולם במזומן'
                            name='paymentMethod'
                            id="paymentMethod"
                            checked={values.paymentMethod === PaymentMethod.CASH ? true : false}
                            value={PaymentMethod.CASH}
                            disabled={true}
                        />
                        <RadioOutline
                            label='שולם באשראי'
                            name='paymentMethod'
                            id="paymentMethod"
                            checked={values.paymentMethod === PaymentMethod.CARD ? true : false}
                            value={PaymentMethod.CARD}
                            disabled={true}
                        />
                        <RadioOutline
                            label='לא שולם'
                            name='paymentMethod'
                            id="paymentMethod"
                            checked={values.paymentMethod === PaymentMethod.NONE ? true : false}
                            value={PaymentMethod.NONE}
                            disabled={true}
                        />
                    </RadioBtnsContainer>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col col-4">
                    <InputGroup
                        disabled={true}
                        label={'מספר אסמכתא'}
                        name={'asmachta'}
                        value={values.asmachta}
                    />
                </div>
                <div className="col col-4">
                    <InputGroup
                        disabled={true}
                        label={'סכום'}
                        name='amount'
                        value={values.amount}
                    />
                </div>
            </div>
        </FormSection>
    );


}

export default PermitPaymentDetails;