import { useState } from "react";
import { useComponentState } from "./useComponentState";
import useFiles from "./api/useFiles.api";


export function useGetFiles() {

    const { componentState, setDangerAlert, setIsLoading, setIsNotLoading } = useComponentState();
    const { getFilesByPersonIdApi } = useFiles();
  
    const [files, setFiles] = useState([]);

    async function getFiles(personId: string) {

        setIsLoading();

        const response = await getFilesByPersonIdApi(personId);

        if (response.isSuccess) {

            setFiles(response.list);
        } else {

            setDangerAlert(response.message);
        }

        setIsNotLoading();
    }

    return { files, getFiles, componentState };
}