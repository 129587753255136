import * as yup from 'yup';
import { MAX_FILE_SIZE, MAX_FILE_SIZE_MESSAGE, 
    REQUIRED_FIELD_MESSAGE, SUPPORTED_FORMATS, TYPE_FILE_MESSAGE } from '../../Errors/errorMessages';

export const uploadFileSchema = yup.object().shape({
    file: yup.mixed()
        .nonNullable()
        .required(REQUIRED_FIELD_MESSAGE)
        .test(
            "FILE_SIZE",
            MAX_FILE_SIZE_MESSAGE,
            (value) => {
                //@ts-ignore
                return !value || (value && value.size <= MAX_FILE_SIZE)
            }
        )
        .test(
            "FILE_FORMAT",
            TYPE_FILE_MESSAGE,
            (value) => {
                //@ts-ignore
                return !value || (value && SUPPORTED_FORMATS.includes(value?.type))
            }
        ),
    });
