import React, { FC } from "react";
import InputGroup from "../../../Components/Forms/InputGroup";
import style from '../LoginPage.module.css';
import Button from "../../../Components/Buttons/Button";
import { SlLogin } from "react-icons/sl";
import { ButtonSizes } from "../../../types/button.types";
import { ILoginForm } from "../../../interface/loginPage.interface";
import { FormikProps } from "formik";
import { IComponentState } from "../../../interface/IComponentState.interface";
import { CiRead } from "react-icons/ci";
import { CiUnread } from "react-icons/ci";

type TypeFormLogin = {
    Formik: FormikProps<ILoginForm>,
    componentState: IComponentState,
    handleChange: (event: any) => void,
}

const FormLogin: FC<TypeFormLogin> = ({ handleChange, componentState, Formik }) => {

    const {errors, values, touched, handleSubmit, handleBlur} = Formik;
    const isLoading = componentState.isLoading;
    const isdisable = isLoading;

    return (
        <form onSubmit={handleSubmit} className={style.form}>

            <div className="row">
                <div className="col-12">
                    <InputGroup
                        styleInput={{ fontSize: '1.5rem' }}
                        styleLabel={{ fontSize: '1.5rem' }}
                        size="l"
                        label={'שם משתמש'}
                        name={'userName'}
                        value={values.userName}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorMsg={(touched.userName && errors.userName) ? errors.userName : undefined}
                    />
                </div>

                <div className="col-12">
                    <InputGroup
                        styleInput={{ fontSize: '1.5rem' }}
                        styleLabel={{ fontSize: '1.5rem' }}
                        label={'סיסמה'}
                        name={'password'}
                        type='password'
                        size="l"
                        value={values.password}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorMsg={(touched.password && errors.password) ? errors.password : undefined}
                    />
                </div>

                <div className="col-12">
                    <InputGroup
                        styleInput={{ fontSize: '1.5rem' }}
                        styleLabel={{ fontSize: '1.5rem' }}
                        label={'מספר רשות'}
                        name={'projectId'}
                        value={values.projectId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        size="l"
                        errorMsg={(touched.projectId && errors.projectId) ? errors.projectId : undefined}
                    />
                </div>

            </div>
            <div className="row mt-3">
                <div className="col-12">
                    <Button onClick={handleSubmit} size={ButtonSizes.LARGE} type='submit' text='התחבר' disabled={isdisable} endIcon={<SlLogin />} />
                </div>
            </div>

        </form>
    );
}

export default FormLogin;
