import { isIRequestFromServer } from "./isIRequestFromServer";
import { IPermitRequestItemList } from "../../../interface/request/permitRequestListPage.interface";

export function validateGetRequestsApiResponse(data: any): boolean {

    // Validate top-level structure
    if (
        !data ||
        typeof data !== "object" ||
        typeof data.data !== "object" ||
        typeof data.data.count !== "number" ||
        !Array.isArray(data.data.requets)
    ) {
        return false;
    }

    // Validate each permit
    for (const requet of data.data.requets) {
        
        if (!isIPermitRequestItemList(requet)) return false;
    }
    return true;
}

export function isIPermitRequestItemList(obj: any): obj is IPermitRequestItemList {

    return (
        typeof obj === "object" &&
        typeof obj.comparingFilesResult === "string" &&
        isIRequestFromServer(obj.request)
    );
}