import React, { FC } from 'react';
import {TfiArchive} from 'react-icons/tfi';
import Button from '../Button';

type Props = {
    onClick: () => void,
    show?: boolean,
}

const HistoryPermitList: FC<Props> = ({ onClick, show = true }) => {
    
    return (
        <Button
            key={'HistoryPermitList'}
            onClick={onClick}
            endIcon={<TfiArchive />}
            text='הסטוריה'
            show={show}
        />
    );
}

export default HistoryPermitList;