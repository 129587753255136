import React, { FC } from 'react';
import { BiSearch } from 'react-icons/bi';

type Props = {
  onClick: (e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => void,
  isLoading: boolean,
}

const SearchBtnLoading: FC<Props> = ({ onClick, isLoading }) => {

  const title = 'חפש';
  let loadingIcom: string | React.ReactNode = '';

  if (isLoading) loadingIcom = (
    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  );
  
  return (
    <button 
    type='submit' 
    style={{display: 'flex', columnGap:"0.3rem" ,alignItems: 'center'}} 
    className={'btn btn-primary'} 
    onClick={onClick} 
    disabled={isLoading}>
      {title}
      <BiSearch size={20} />
      {loadingIcom}
    </button>
  );
}

export default SearchBtnLoading;