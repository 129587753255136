import React, { ReactNode } from 'react';


type ActionBoxCardProps = {
    children: ReactNode;
}

const cssStyle: React.CSSProperties = {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: '#cbd8e3',
    padding: '10px',
    borderRadius: '10px',
    rowGap: '0.75rem',
    columnGap: '2rem',
};

const ActionBoxCard: React.FC<ActionBoxCardProps> = ({ children }) => {

    return (
        <div style={cssStyle}>
            {children}
        </div>
    );
}

export default ActionBoxCard;