import React, { useRef, useState } from 'react'
import { FiRotateCw, FiRotateCcw } from "react-icons/fi";
import style from '../../requestDetailsPage.module.css';
import { FileTypes } from '../../../../../types/fileTypes.types';

const RequestFileViewer = ({ prevFile, nextFile, file, isActive, key }) => {

  const fileRef = useRef(undefined);
  const [deg, setDeg] = useState(0);
  const isPdf = (file.type === FileTypes.PDF_TYPE ? true : false);
  let carouselItemClass = "carousel-item " + style.fileViewerLayoutCro;

  if (isActive) {
    carouselItemClass += ' active';
  }

  return (
    <div className={carouselItemClass}>
      {isPdf ? (
        <object id={key} data={file.url} type="application/pdf" width={'100%'} height={'610rem'} className={style.pdf + ' d-block w-100'}></object>
      ) : (
        <>
          <div className={style.rotateContainersBtns}>
            <button onClick={() => rotateImage(90)} className={style.roundBtn + " " + style.rotateLeftBtn}>
              <FiRotateCw />
            </button>
            <button onClick={() => rotateImage(-90)} className={style.roundBtn + " " + style.rotateRightBtn}>
              <FiRotateCcw />
            </button>
          </div>
          <img ref={fileRef} id={key} style={{ transform: `rotate(${deg}deg)` }} className={style.image + ' d-block w-100'} src={file.url} alt={key} />
        </>
      )}
    </div>

  );

  function rotateImage(addDeg) {
    const newDeg = deg + addDeg;

    if (newDeg === 360) {

      setDeg(0);
    }

    setDeg(newDeg);
  }
}

export default RequestFileViewer;