import { useReducer } from "react";
import { HistoryModalActionKind, historyModalReducer } from "../reducers/historyModalReducer";
import { initHistoryModalData } from "../data/default/historyModal.data";
import useHistoryApi from "./api/useHistory.api";
import { IHistoryRecord } from "../interface/history/IHistoryRecord";

const GENERAL_ERROR_MESSAGE = 'בעיה להביא את הנתונים';

function useHistoryModal() {

    const getHistoryListApi = useHistoryApi();
    const [historyModalState, historyModalStateDispatch] = useReducer(historyModalReducer, initHistoryModalData);

    function openHistoryModal(isRequest: boolean, identifier: number) {

        historyModalStateDispatch({
            type: HistoryModalActionKind.OPEN_HISTORY_MODAL,
            payload: {
                isRequest: isRequest,
                identifier: identifier,
            }
        });
    }

    function closeHistoryModal() {

        historyModalStateDispatch({ type: HistoryModalActionKind.CLOSE_HISTORY_MODAL });
    }

    function goToViewHistoryRecord(recordIndex: number) {

        let record = {} as IHistoryRecord;

        if (historyModalState.recordList.length >= recordIndex) {

            record = historyModalState.recordList[recordIndex];
        }

        historyModalStateDispatch({
            type: HistoryModalActionKind.GO_TO_VIEW_RECORD,
            payload: { currentRecord: record }
        });
    }

    function goToHistoryRecordList() {

        historyModalStateDispatch({
            type: HistoryModalActionKind.GO_TO_RECORD_LIST
        });
    }

    function updateIdentifier(identifier: number) {

        historyModalStateDispatch({
            type: HistoryModalActionKind.UPDATE_IDENTIFIER,
            identifier: identifier
        });
    }

    function setIsModalLoading() {

        historyModalStateDispatch({ type: HistoryModalActionKind.IS_LOADING });
    }

    function setIsNotModalLoading(msg = '') {

        historyModalStateDispatch({ type: HistoryModalActionKind.IS_NOT_LOADING, msg: msg });
    }

    async function handleGetHistory() {

        setIsModalLoading();

        try {

            const response = await getHistoryListApi(historyModalState.isRequest, historyModalState.identifier);

            if (!response.isSuccess) {

                setIsNotModalLoading(response.msg);
                return;
            }

            historyModalStateDispatch({
                type: HistoryModalActionKind.SET_HISTORY_LIST,
                historyList: convertListRawDataFromServer(response.historyRecords)
            });

        } catch (error) {

            setIsNotModalLoading(GENERAL_ERROR_MESSAGE);
            console.log(error);
        }
    }

    function convertListRawDataFromServer(rawData: any): IHistoryRecord[] {

        if (!Array.isArray(rawData)) return [] as IHistoryRecord[];

        return rawData.map((historyRecordRaw: any, index) => {

            const statusDetails = historyRecordRaw.statusDetails ? historyRecordRaw.statusDetails : {}; 

            const taveiDaiarHistoryDetails = historyRecordRaw.taveiDaiarHistoryDetails ? historyRecordRaw.taveiDaiarHistoryDetails : {}; 

            return {
                statusDetails: {
                    id: statusDetails.id ? statusDetails.id : -1,
                    comments: statusDetails.comments ? statusDetails.comments : '',
                    date: statusDetails.date ? statusDetails.date : -1,
                    historyId: statusDetails.historyId ? statusDetails.historyId : '', 
                    issueDate: statusDetails.issueDate ? statusDetails.issueDate : -1, 
                    projectId: statusDetails.projectId ? statusDetails.projectId : -1, 
                    status: statusDetails.status ? statusDetails.status : -1, 
                    userId: statusDetails.userId ? statusDetails.userId : -1, 
                },
                taveiDaiarHistoryDetails: {
                    id: taveiDaiarHistoryDetails.id ? taveiDaiarHistoryDetails.id : -1,
                    agreeDataTransfer: taveiDaiarHistoryDetails.agreeDataTransfer ? taveiDaiarHistoryDetails.agreeDataTransfer : -1,
                    agreeTakanon: taveiDaiarHistoryDetails.agreeTakanon ? taveiDaiarHistoryDetails.agreeTakanon : -1,
                    agreeToVolunteer: taveiDaiarHistoryDetails.agreeToVolunteer ? taveiDaiarHistoryDetails.agreeToVolunteer : -1,
                    amount: taveiDaiarHistoryDetails.amount ? taveiDaiarHistoryDetails.amount : -1,
                    approvalDate: taveiDaiarHistoryDetails.approvalDate ? taveiDaiarHistoryDetails.approvalDate : -1,
                    approveUserId: taveiDaiarHistoryDetails.approveUserId ? taveiDaiarHistoryDetails.approveUserId : -1,
                    endValidityDate: taveiDaiarHistoryDetails.endValidityDate ? taveiDaiarHistoryDetails.endValidityDate : -1,
                    fromOwnershipDate: taveiDaiarHistoryDetails.fromOwnershipDate ? taveiDaiarHistoryDetails.fromOwnershipDate : -1,
                    hardCopyLeasing: taveiDaiarHistoryDetails.hardCopyLeasing ? taveiDaiarHistoryDetails.hardCopyLeasing : -1,
                    hardCopyLicense: taveiDaiarHistoryDetails.hardCopyLicense ? taveiDaiarHistoryDetails.hardCopyLicense : -1,
                    hardCopyPersonId: taveiDaiarHistoryDetails.hardCopyPersonId ? taveiDaiarHistoryDetails.hardCopyPersonId : -1,
                    insertUserId: taveiDaiarHistoryDetails.insertUserId ? taveiDaiarHistoryDetails.insertUserId : -1,
                    ownerId: taveiDaiarHistoryDetails.iownerIdd ? taveiDaiarHistoryDetails.ownerId : -1,
                    ownerTypeId: taveiDaiarHistoryDetails.ownerTypeId ? taveiDaiarHistoryDetails.ownerTypeId : -1,
                    plateType: taveiDaiarHistoryDetails.plateType ? taveiDaiarHistoryDetails.plateType : -1,
                    projectId: taveiDaiarHistoryDetails.projectId ? taveiDaiarHistoryDetails.projectId : -1,
                    receiveInMail: taveiDaiarHistoryDetails.receiveInMail ? taveiDaiarHistoryDetails.receiveInMail : -1,
                    residentId: taveiDaiarHistoryDetails.residentId ? taveiDaiarHistoryDetails.residentId : -1,
                    sentToMail: taveiDaiarHistoryDetails.sentToMail ? taveiDaiarHistoryDetails.sentToMail : -1,
                    sentToPrint: taveiDaiarHistoryDetails.sentToPrint ? taveiDaiarHistoryDetails.sentToPrint : -1,
                    startValidityDate: taveiDaiarHistoryDetails.startValidityDate ? taveiDaiarHistoryDetails.startValidityDate : -1,
                    stateId: taveiDaiarHistoryDetails.stateId ? taveiDaiarHistoryDetails.stateId : -1,
                    statusTav: taveiDaiarHistoryDetails.statusTav ? taveiDaiarHistoryDetails.statusTav : -1,
                    street: taveiDaiarHistoryDetails.street ? taveiDaiarHistoryDetails.street : -1,
                    tavDaiarId: taveiDaiarHistoryDetails.tavDaiarId ? taveiDaiarHistoryDetails.tavDaiarId : -1,
                    tavDayar: taveiDaiarHistoryDetails.tavDayar ? taveiDaiarHistoryDetails.tavDayar : -1,
                    tavGilZahav: taveiDaiarHistoryDetails.tavGilZahav ? taveiDaiarHistoryDetails.tavGilZahav : -1,
                    toOwnershipDate: taveiDaiarHistoryDetails.toOwnershipDate ? taveiDaiarHistoryDetails.toOwnershipDate : -1,
                    updateOwnershipDate: taveiDaiarHistoryDetails.updateOwnershipDate ? taveiDaiarHistoryDetails.updateOwnershipDate : -1,
                    userRegisterDate: taveiDaiarHistoryDetails.userRegisterDate ? taveiDaiarHistoryDetails.userRegisterDate : -1,
                    userUpdateDate: taveiDaiarHistoryDetails.userUpdateDate ? taveiDaiarHistoryDetails.userUpdateDate : -1,
                    vehicleColor: taveiDaiarHistoryDetails.vehicleColor ? taveiDaiarHistoryDetails.vehicleColor : -1,
                    vehicleGroupId: taveiDaiarHistoryDetails.vehicleGroupId ? taveiDaiarHistoryDetails.vehicleGroupId : -1,
                    vehicleId: taveiDaiarHistoryDetails.vehicleId ? taveiDaiarHistoryDetails.vehicleId : -1,
                    vehicleMake: taveiDaiarHistoryDetails.vehicleMake ? taveiDaiarHistoryDetails.vehicleMake : -1,
                    vehicleModelId: taveiDaiarHistoryDetails.vehicleModelId ? taveiDaiarHistoryDetails.vehicleModelId : -1,
                    vehiclePrivte: taveiDaiarHistoryDetails.vehiclePrivte ? taveiDaiarHistoryDetails.vehiclePrivte : -1,
                    year: taveiDaiarHistoryDetails.year ? taveiDaiarHistoryDetails.year : -1,
                    zone: taveiDaiarHistoryDetails.zone ? taveiDaiarHistoryDetails.zone : -1,
                    mailAppt: taveiDaiarHistoryDetails.mailAppt ? taveiDaiarHistoryDetails.mailAppt :  '',
                    mailCity: taveiDaiarHistoryDetails.mailCity ? taveiDaiarHistoryDetails.mailCity :  '',
                    mailHouse: taveiDaiarHistoryDetails.mailHouse ? taveiDaiarHistoryDetails.mailHouse :  '',
                    mailRashumNum: taveiDaiarHistoryDetails.mailRashumNum ? taveiDaiarHistoryDetails.mailRashumNum :  '',
                    mailStreet: taveiDaiarHistoryDetails.mailStreet ? taveiDaiarHistoryDetails.mailStreet :  '',
                    mailZip: taveiDaiarHistoryDetails.mailZip ? taveiDaiarHistoryDetails.mailZip :  '',
                    mobile: taveiDaiarHistoryDetails.mobile ? taveiDaiarHistoryDetails.mobile :  '',
                    reportNum: taveiDaiarHistoryDetails.reportNum ? taveiDaiarHistoryDetails.reportNum :  '',
                    appartment: taveiDaiarHistoryDetails.appartment ? taveiDaiarHistoryDetails.appartment :  '',
                    email: taveiDaiarHistoryDetails.email ? taveiDaiarHistoryDetails.email :  '',
                    house: taveiDaiarHistoryDetails.house ? taveiDaiarHistoryDetails.house :  '',
                    payment: taveiDaiarHistoryDetails.payment ? taveiDaiarHistoryDetails.payment :  '',
                    vehicleNum: taveiDaiarHistoryDetails.vehicleNum ? taveiDaiarHistoryDetails.vehicleNum :  '',
                    asmachta: taveiDaiarHistoryDetails.asmachta ? taveiDaiarHistoryDetails.asmachta :  '',
                    comments: taveiDaiarHistoryDetails.comments ? taveiDaiarHistoryDetails.comments :  '',
                    companyName: taveiDaiarHistoryDetails.companyName ? taveiDaiarHistoryDetails.companyName :  '',
                    zip: taveiDaiarHistoryDetails.zip ? taveiDaiarHistoryDetails.zip :  '',
                    extraValue1: taveiDaiarHistoryDetails.extraValue1 ? taveiDaiarHistoryDetails.extraValue1 :  '',
                    extraValue2: taveiDaiarHistoryDetails.extraValue2 ? taveiDaiarHistoryDetails.extraValue2 :  '',
                    entrance: taveiDaiarHistoryDetails.entrance ? taveiDaiarHistoryDetails.entrance :  '',
                    firstName: taveiDaiarHistoryDetails.firstName ? taveiDaiarHistoryDetails.firstName :  '',
                    historyId: taveiDaiarHistoryDetails.historyId ? taveiDaiarHistoryDetails.historyId :  '',
                    lastName: taveiDaiarHistoryDetails.lastName ? taveiDaiarHistoryDetails.lastName :  '',
                    pathScandLeasing: taveiDaiarHistoryDetails.pathScandLeasing ? taveiDaiarHistoryDetails.pathScandLeasing :  '',
                    pathScandLicense: taveiDaiarHistoryDetails.pathScandLicense ? taveiDaiarHistoryDetails.pathScandLicense :  '',
                    pathScandPersonId: taveiDaiarHistoryDetails.pathScandPersonId ? taveiDaiarHistoryDetails.pathScandPersonId :  '',
                    personId: taveiDaiarHistoryDetails.personId ? taveiDaiarHistoryDetails.personId :  '',
                    phone: taveiDaiarHistoryDetails.phone ? taveiDaiarHistoryDetails.phone : '',
                    hasPaidCash: taveiDaiarHistoryDetails.hasPaidCash ? taveiDaiarHistoryDetails.hasPaidCash : false,
                    hasPaidCredit: taveiDaiarHistoryDetails.hasPaidCredit ? taveiDaiarHistoryDetails.hasPaidCredit : false,
                    rental: taveiDaiarHistoryDetails.rental ? taveiDaiarHistoryDetails.rental : false,
                    company: taveiDaiarHistoryDetails.company ? taveiDaiarHistoryDetails.company : false,
                },
                userName: ''
            };
        });
    }

    return {
        historyModalState, updateIdentifier, handleGetHistory,
        goToHistoryRecordList, goToViewHistoryRecord, setIsModalLoading,
        closeHistoryModal, openHistoryModal, historyModalStateDispatch
    };
}

export default useHistoryModal;