import React, { FC, FocusEvent, MouseEvent } from 'react';

type TypeOutlineCheckBtn = {
    value: number | string, 
    id: string, 
    name: string, 
    onClick: (event: MouseEvent<HTMLInputElement>) => void,
    onBlur?: (e: FocusEvent<any, Element>) => void, 
    label: string, 
    isChecked?: boolean, 
    disabled?: boolean
}

const OutlineCheckBtn: FC<TypeOutlineCheckBtn> = ({ 
    value, 
    id, 
    name, 
    label, 
    onClick, 
    onBlur = () => {}, 
    isChecked = false, 
    disabled = false 
}) => {
   
    return (
        <>
            <input
                disabled={disabled}
                checked={isChecked}
                value={value}
                onBlur={onBlur}
                onClick={(e) => onClick(e)}
                onChange={(e) => { }}
                id={id}
                name={name}
                className="btn-check"
                type='checkbox'
            />
            <label
                className="btn btn-outline-success" 
                htmlFor={id}
            >
                {label}
            </label>
        </>
    );
}

export default OutlineCheckBtn;