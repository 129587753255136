import React from 'react';
import style from './forms.module.css'

type Props = {
  message: string
}

const InputErrorMessage = ({ message }: Props) => {

  if (!message) return null;

  return (
    <div className={style.inputErrorMessage}>{message}</div>
  )
}

export default InputErrorMessage;