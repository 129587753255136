import React, { FC } from 'react';
import { MdOutlineFilterListOff, MdOutlineFilterList } from "react-icons/md";
import Button from './Button';
import { ButtonColors, ButtonTypes } from '../../types/button.types';

type Props = {
    isAdvancedSearchOpen: boolean,
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
}

const AdvancedSearchBtn: FC<Props> = ({ isAdvancedSearchOpen, onClick }) => {

    const textBtn = isAdvancedSearchOpen ? 'סגור חיפוש מתקדם' : 'פתח חיפוש מתקדם';
    const icon = isAdvancedSearchOpen ? <MdOutlineFilterListOff /> : <MdOutlineFilterList />;

    return (
        <Button
            type='button'
            onClick={onClick}
            endIcon={icon}
            text={textBtn}
            outline={ButtonTypes.OUTLINE}
            theme={ButtonColors.SECONDARY} 
        />
    );
}

export default AdvancedSearchBtn;