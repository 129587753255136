import moment from "moment";
import { IPermitRequestFilterPage } from "../../interface/request/permitRequestListPage.interface";

export const initSearchRequestFormData: IPermitRequestFilterPage = {
    offset: 0,
    limit: 10,
    dateTypeToSearchBy: '1',
    fromDate: moment(new Date()).subtract(3,'d').format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    requestDBId: '',
    requestCreatedBy: '',
    requestStatus: 1,
    comparingFilesStatus: 0,
    typeOfRequest: '',
    personId: '',
    carNumber: ''
}