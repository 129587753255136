import React, { FC, ReactNode } from 'react';
import { useProjectDetailsContext } from '../../../contexts/ProjectDetailsContext';
import SelectWithSearch from './SelectWithSearch';
import { decodeNCRStringIfNeeded } from '../../../Helpers/language';
import { ISelectOption } from '../../../interface/form.interface';


interface SelectCarColorProps {
    name: string;
    value: number;
    handleChange: (name: string | number, value: string | number) => void,
    handleBlur: (e: React.FocusEvent<HTMLInputElement, Element>) => void,
    errorMsg?: string | ReactNode | undefined;
    required?: boolean;
    withEmptyField?: boolean;
}

const SelectCarColor: FC<SelectCarColorProps> = ({ 
    name, 
    value ,
    handleBlur, 
    handleChange, 
    errorMsg = '', 
    required = false,
    withEmptyField = false,
}) => {
    
    const {projectDetails} = useProjectDetailsContext();

    const options = getColorOptions();

    const valueStr = options.find(option => option.value == value);

    return (
        <SelectWithSearch
            errorMsg={errorMsg}
            name={name}
            label={'צבע רכב'}
            value={valueStr}
            required={required}
            selects={options}
            handleBlur={handleBlur}
            handleChange={handleChange} />
    );

    function getColorOptions() {

        let colors: ISelectOption[] = projectDetails.colors.map(color => {
            return {label: decodeNCRStringIfNeeded(color.name), value: color.id}; 
        });
    
        if(required) {

            colors = [{value: -1, label: 'צריך לבחור'}, ...colors];
            return colors;
        }

        if(withEmptyField) {

            colors = [{value: -1, label: ''}, ...colors];
            return colors;
        }

        return colors;
    }
}

export default SelectCarColor;