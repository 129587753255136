import moment from "moment";
import { decodeNCRStringIfNeeded } from "../../language";
import { convertMakerCodeToText } from "../convertorTablesCodeToText";

export function convExpiredPermitRowData(expiredPermitRowData: any, makers: any) {

    const id = expiredPermitRowData.id;
    const firstName = expiredPermitRowData.resident.firstName;
    const lastName = expiredPermitRowData.resident.lastName;
    const cellPhone = formatCellphoneNumber(expiredPermitRowData.resident.cellPhone);
    const additionalPhone = expiredPermitRowData.resident.phoneNum;
    const vehicleNum = expiredPermitRowData.vehicle.vehicleNum;
    const endValidityDate = expiredPermitRowData.endValidityDate;
    const fullName = `${decodeNCRStringIfNeeded(firstName)} ${decodeNCRStringIfNeeded(lastName)}`;
    const makerCode = parseInt(expiredPermitRowData.vehicle.vehicleMake);
    const makerName = convertMakerCodeToText(makerCode, makers);
    let endValidityDateStr = '';

    if(endValidityDate > 0) {
        endValidityDateStr = moment(new Date(endValidityDate)).format("DD-MM-YYYY");
    }
    
    return {
        id: id,
        fullName: fullName,
        vehicleNum: vehicleNum,
        cellPhone: cellPhone,
        additionalPhone: additionalPhone,
        endValidityDate: endValidityDateStr,
        makerName: decodeNCRStringIfNeeded(makerName),
    };
}

function formatCellphoneNumber(cellPhone) {
    
    if(!cellPhone || cellPhone.length <= 3) {
        return cellPhone;
    }

    return cellPhone.slice(0, 3) + "-" + cellPhone.slice(3);
}