import React from 'react'; 
import Style from '../viewFilesPage.module.css';

const ImageContainerViewer = ({ path, name}) => {

    return (
        <img className={Style.itemListImage} src={path} alt={name} />
    );
}

export default ImageContainerViewer;