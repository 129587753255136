import React from 'react';
import { useRequestManagmentPageContext } from '../../../../contexts/request/RequestDetailsPageContext';
import InputGroup from '../../../../Components/Forms/InputGroup';
import FormSection from '../../../../Components/UI/formSection/FormSection';

const PersonDetails = () => {
    
    const { handleChange, Formik } = useRequestManagmentPageContext();
    const { errors, values, touched, handleBlur } = Formik;

    return (
        <FormSection title={"פרטי האזרח"}>
            <div className="row">
                <div className="col-md-6 col-lg-4">
                    <InputGroup 
                        name='privateName'
                        label='שם פרטי'
                        value={values.privateName}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errorMsg={(errors.privateName && touched.privateName) ? errors.privateName : undefined}
                    />
                </div>
                <div className="col-md-6 col-lg-4">
                    <InputGroup 
                        name='familyName'
                        label='שם משפחה' 
                        value={values.familyName}
                        handleChange={handleChange}
                        handleBlur={handleBlur} 
                        errorMsg={(errors.familyName && touched.familyName) ? errors.familyName : undefined} />
                </div>
             
                <div className="col-md-6 col-lg-4">
                    <InputGroup 
                        disabled={true}
                        name='personId' 
                        label='תעודת זהות'
                        value={values.personId}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errorMsg={(errors.personId && touched.personId) ? errors.personId : undefined} />
                </div>
            </div>
          
        </FormSection>
    );
}

export default PersonDetails;