import React from 'react';
import moment from 'moment';
import { usePaymentsReportPageContext } from '../../../contexts/reports/PaymentsReportPageContext';
import DangerAlert from '../../../Components/Alerts/DangerAlert';
import PageTitle from '../../../Components/pageTitle/PageTitle';
import CharPaymentArea from './components/ChartDistribution';
import FormBox from '../../../Components/Forms/FormBox';
import DateInput from '../../../Components/Forms/DateInput';
import SearchBtnLoading from '../../../Components/Buttons/SearchBtnLoading';
import ContainerSummary from './components/ContainerSummary';
import Button from '../../../Components/Buttons/Button';
import { ButtonSizes } from '../../../types/button.types';

const DATE_FORMAT = 'YYYY-MM-DD';

const PaymentReportPage = () => {

  const { fromDate, setFromDate, toDate, setToDate, componentState,
    handleSearch } = usePaymentsReportPageContext();
  const { hasAlert, message, isLoading } = componentState

  return (
    <div>
      <DangerAlert isShow={hasAlert} message={message} />
      <PageTitle title={'דוח תווים'} />
      <FormBox>
        <div className="row">
          <div className="col-9">
            <div className="row">
              <div className="col col-lg-6 col-xl-4">
                <DateInput
                  value={fromDate}
                  label='תאריך התחלה'
                  name="fromDate"
                  handleChange={event => setFromDate(event.currentTarget.value)}
                  handleBlur={event => {}}
                  required={true}
                />
              </div>
              <div className="col col-lg-6 col-xl-4">
                <DateInput
                  label='תאריך סוף'
                  name="toDate"
                  value={toDate}
                  handleChange={event => setToDate(event.currentTarget.value)}
                  handleBlur={event => {}}
                  required={true}
                />
              </div>
            </div>
          </div>
          <div className="col-3 align-self-end">
            <div className='d-flex justify-content-end'>
              <SearchBtnLoading onClick={() => handleSearch()} isLoading={isLoading} />
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className='col' style={{ display: 'flex', gap: '0.2rem' }}>

            <Button
              onClick={() => {

                setFromDate(moment().format(DATE_FORMAT));
                setToDate(moment().format(DATE_FORMAT));
              }}
              text='תחילת היום'
              size={ButtonSizes.SMALL} />
            <Button
              onClick={() => {

                const momentOne = moment();
                momentOne.set('date', 1);

                setFromDate(momentOne.format(DATE_FORMAT));
                setToDate(moment().format(DATE_FORMAT));
              }}
              text='תחילת החודש'
              size={ButtonSizes.SMALL} />
            <Button
              onClick={() => {

                const momentOne = moment();

                momentOne.set('month', 0);
                momentOne.set('date', 1);

                setFromDate(momentOne.format(DATE_FORMAT));
                setToDate(moment().format(DATE_FORMAT));
              }}
              text='תחילת השנה'
              size={ButtonSizes.SMALL} />
          </div>
        </div>
      </FormBox>
      <div style={{ display: 'flex', gap: '1rem', padding: '1rem', overflowY: 'auto' }}>
        <ContainerSummary />
        <CharPaymentArea />
      </div>
    </div>
  );
}

export default PaymentReportPage;