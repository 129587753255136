import ResponseStatus from "../../types/responseStatus.types";
import useAxiosPrivate from "../useAxiosPrivate";


export const GET_PERMIT_HISTORY_RECORDS = "/history/permit/history-records";
export const GET_REQUEST_HISTORY_RECORDS = "/history/request/history-records";

export type TypeGetHistoryList = {
    isSuccess: boolean,
    historyRecords: any[],
    msg: string,
}

const useHistoryApi = () => {

    const axiosPrivate = useAxiosPrivate();

    async function getHistoryListApi(isRequest: boolean, identifier: number) {

        const result: TypeGetHistoryList = {
            isSuccess: true,
            historyRecords: [],
            msg: '',
        }

        let func = isRequest ? GET_REQUEST_HISTORY_RECORDS : GET_PERMIT_HISTORY_RECORDS;

        try {

            const response = await axiosPrivate.get(`${func}/${identifier}`);
            const data = response.data.data;

            if (data.status === ResponseStatus.FAILURE) {

                result.msg = "בעיה להביא את הנתונים";
                return;
            }

            result.historyRecords = data.historyRecords
        } catch (error) {

            result.isSuccess = false;
            result.msg = "בעיה להביא את הנתונים";

            console.log(error);
        }

        return result;
    }

    return getHistoryListApi;
}

export default useHistoryApi;