import React, {createContext, ReactNode, useContext, useState} from 'react';
import { UserRoleType } from '../types/types.import';
import { initProjectDetails, IUserDetailsData } from '../data/default/projectConfig.data';
import { initUserDetailsData } from '../data/default/userDetails.data';
import { IProjectDetails, ITaveiDayarSiteConfig } from '../interface/projectConfig/projectConfig.interface';

type TypeProjectDetails = {
    projectDetails: IProjectDetails, 
    loadProjectDetails: (object: any) => void, 
    userDetails: IUserDetailsData, 
    updateUserDetails: React.Dispatch<React.SetStateAction<IUserDetailsData>>,
}

export const ProjectDetailsContext = createContext<TypeProjectDetails | undefined>(undefined);

export const ProjectDetailsProvider = ({children}: {children: ReactNode}) => {
    const [projectDetails, setProjectDetails] = useState(initProjectDetails);
    const [userDetails, setUserDetails] = useState(initUserDetailsData);

    function updateUserDetails(userDetails: IUserDetailsData) {

        if(!userDetails) {
            userDetails = initUserDetailsData;
        }

        setUserDetails({
            userName: userDetails.userName ? userDetails.userName : '',
            userRole: returnIfInUserRoleOrReturnDefault(userDetails.userRole),
        })
    }

    function returnIfInUserRoleOrReturnDefault(userRole: UserRoleType) {

        if(!userRole || (
            userRole !== UserRoleType.ADMIN && 
            userRole !== UserRoleType.SECRETARY && 
            userRole !== UserRoleType.CITIZEN)
        ) {
            return UserRoleType.SECRETARY;
        }

        return userRole;
    }

    function loadProjectDetails(object: any) {

        const cityLogUrl = object.cityLogUrl ? object.cityLogUrl : '';
        const hasAdditionalAddress = object.hasAdditionalAddress ? object.hasAdditionalAddress : false;
        const listUserName = object.listUserName ? object.listUserName : [];
        const messageConfig = object.messageConfig ? object.messageConfig : {}
        const projectId = object.projectId ? object.projectId : -1;
        const userName = object.userName ? object.userName : '';
        const zones = object.zones ? object.zones : [];
        const taveiDayarSiteConfig: ITaveiDayarSiteConfig = object.taveiDayarSiteConfig ? object.taveiDayarSiteConfig : {};
        const projectName = object.projectName ? object.projectName : '';
        const hasPayment = taveiDayarSiteConfig.tranzilaSupplier === "true" ? true : false;

        const gilHaZahavOption = taveiDayarSiteConfig.gilHaZahavOption;

        const streets = object.streets ? (
            object.streets.sort((a, b) => a.name.localeCompare(b.name))
        ) : [];

        const colors = object.colors ? (
            object.colors.sort((a, b) => a.name.localeCompare(b.name))
        ) : [];

        const types = object.types ? (
            object.types.sort((a, b) => a.name.localeCompare(b.name))
        ) : [];

        const vehicleGroups = object.vehicleGroups ? (
            object.vehicleGroups.sort((a, b) => a.vehicleGroupName.localeCompare(b.vehicleGroupName))
        ) : [];

        // hard coded

        // PermitPage
        const showVehicleTypeInPermitCardPermitPage = projectId === 27 ? true : false;
        const showZonePermitPage = projectId === 18 ? true : false;
        const showPermitTypePermitPage = gilHaZahavOption;

        // RequestPage
        const showVehicleTypeInPermitCardRequestPage = projectId === 27 ? true : false;
        const showPermitTypeRequestPage = gilHaZahavOption;


        const newProjectDetails: IProjectDetails = {
            ...initProjectDetails,
            streets: streets,
            colors: colors,
            types: types,
            vehicleGroups: vehicleGroups,
            cityLogUrl: cityLogUrl,
            hasAdditionalAddress: hasAdditionalAddress,
            listUserName: listUserName,
            messageConfig: messageConfig,
            projectId: projectId,
            userName: userName,
            projectName: projectName,
            zones: zones,
            hasPayment: hasPayment,
            taveiDayarSiteConfig: taveiDayarSiteConfig,
            guiConfig: {
                permitListPageGuiConfig: {
                    showVehicleType: showVehicleTypeInPermitCardPermitPage,
                    showZone: showZonePermitPage,
                    showPermitType: showPermitTypePermitPage,
                },
                requestListPageGuiConfig: {
                    showVehicleType: showVehicleTypeInPermitCardRequestPage,
                    showPermitType: showPermitTypeRequestPage,
                },
            }
        };

        setProjectDetails(newProjectDetails);
    }

    return (
        <ProjectDetailsContext.Provider value={{projectDetails, loadProjectDetails, userDetails, updateUserDetails}}>
            {children}
        </ProjectDetailsContext.Provider>
    );
}

export const useProjectDetailsContext = () => useContext(ProjectDetailsContext);