import React from 'react'

const CheckBtnsContainer = ({ children, label, flexDirection }) => {

  let btnStyle: React.CSSProperties = { display: 'flex', flexDirection: 'row', columnGap: '1rem', flexWrap: 'wrap', rowGap: '1rem' };

  if (flexDirection === 'column') {
    btnStyle.flexDirection = 'column'
  }

  return (
    <div className="mb-3 form-check" style={{ display: 'flex', flexDirection: 'column' }}>
      <label htmlFor="hasEmail" className="form-check-label pb-1">{label}</label>
      <div style={btnStyle}>
        {children}
      </div>
    </div>
  );
}

CheckBtnsContainer.defaultProps = {

  label: '',
  flexDirection: 'row'
}

export default CheckBtnsContainer;