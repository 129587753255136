export enum  ButtonColors {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SUCCESS = 'success',
    DANGER = 'danger',
    WARNING = 'warning',
    INFO = 'info',
    LIGHT = 'light',
    DARK = 'dark',
    LINK = 'link'
}

export enum  ButtonSizes {
    SMALL = 'secondary',
    MEDIUM = 'medium',
    LARGE = 'primary',
}

export enum  ButtonTypes {
    OUTLINE = 'outline',
    FILLED = 'filled',
}