import { IMessageCitizenModal } from "../../interface/messageCitizenModal.interface";
import { SendMsgOptionType } from "../../types/types.import";

export const initSendMsgModalFormData: IMessageCitizenModal = {
    isRequest: true,
    identifier: -1,
    sendViaEmail: SendMsgOptionType.EMAIL,
    sms: {  
        chosenTextOptionCodes: [],
        mobileNum: '',
        freeText: '',
    },
    email: {
        chosenTextOptionCodes: [],
        freeText: '',
        emailSubject: '',
        cityEmail: '',
        citizenEmail: '',
        sendCopyToCity: false,
    },
}