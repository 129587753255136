import { IPermit } from "../../interface/permit/permit.interface";
import { IPermitListPageData } from "../../interface/permit/permitListPage.interface";

export enum PermitListPageKind {
    UPDATE_PRMIT_LIST = 'UPDATE_PRMIT_LIST',
}

// An interface for our actions
type PermitListPageAction =  {
    type: PermitListPageKind.UPDATE_PRMIT_LIST;
    payload: {
        permits: IPermit[],
        totalCount: number
    };
}

export const listPermitsReducer = (state: IPermitListPageData, action: PermitListPageAction) => {

    switch (action.type) {
        case PermitListPageKind.UPDATE_PRMIT_LIST:
            
            return {
                ...state, 
                permits:action.payload.permits, 
                totalCount:action.payload.totalCount
            };       
        default:
            return state;
    }
} 
