import { IContentOption, IGeneralEmailConfig, IMessageConfig, IMessageEmailConfig, IMessageSmsConfig, IRejectionEmailConfig } from "../../interface/projectConfig/messageConfig.interface";
import {
    ISystemTableColor, ISystemTableStreet,
    ISystemTableVehicleMakers, ITaveiDayarSiteConfig,
    IProjectDetails,
    ISystemTableVehicleGroup
} from "../../interface/projectConfig/projectConfig.interface";
import { SendMsgOptionType, UserRoleType } from "../../types/types.import";


export const taveiDayarSiteConfig: ITaveiDayarSiteConfig = {
    "additionalLogoPath": "",
    "colorAndType": false,
    "gilHaZahavAge": 70,
    "gilHaZahavOption": true,
    "gilHaZahavPayment": 0,
    "gilHaZahavValidityPeriod": 12,
    "handicappedOption": false,
    "handicappedPayment": 0,
    "hesSendSmsOption": false,
    "logoPath": "/pic/tavDaiar/1/Citylogo18.jpg",
    "municipalEmployeeOption": false,
    "municipalEmployeePayment": 30,
    "municipalEmployeeValidityPeriod": 12,
    "payment": 30,
    "requireDrivingLicenseImg": true,
    "smsMessage": "",
    "studentOption": false,
    "studentPayment": 0.0,
    "studentValidityPeriod": 12,
    "tranzilaSupplier": false,
    "validityPeriod": 12
}

export const generalEmailConfig: IGeneralEmailConfig = {
    cityEmailAddress: '',
    contentOptions: [] as IContentOption[],
    enableOption: false,
    subject: '',
}

export const rejectionEmailConfig: IRejectionEmailConfig = {
    cityEmailAddress: '',
    contentOptions: [] as IContentOption[],
    enableOption: false,
    subject: '',
}

export const messageEmailConfig: IMessageEmailConfig = {
    generalEmailConfig: generalEmailConfig,
    rejectionEmailConfig: rejectionEmailConfig,
}

export const messageSmsConfig: IMessageSmsConfig = {
    generalSmsConfig: {
        enableOption: false,
        contentOptions: [] as IContentOption[],
    },
    rejectionSmsConfig: {
        enableOption: false,
        contentOptions: [] as IContentOption[],
    }
}

export const initMessageConfig: IMessageConfig = {
    sendingCitizenMsgVia: SendMsgOptionType.EMAIL,
    sendingCitizenOtpCodeVia: SendMsgOptionType.EMAIL,
    messageEmailConfig: messageEmailConfig,
    messageSmsConfig: messageSmsConfig,
}

export const initGuiConfig = {
    permitListPageGuiConfig: {
        showVehicleType: false,
        showZone: false,
        showPermitType: false,
    },
    requestListPageGuiConfig: {
        showVehicleType: false,
        showPermitType: false,
    }
}

export const initProjectDetails: IProjectDetails = {
    projectId: -1,
    userName: '',
    streets: [] as ISystemTableStreet[],
    colors: [] as ISystemTableColor[],
    types: [] as ISystemTableVehicleMakers[],
    vehicleGroups: [] as ISystemTableVehicleGroup[],
    zones: [] as number[],
    listUserName: [] as string[],
    cityLogUrl: '',
    hasPayment: false,
    hasAdditionalAddress: false,
    projectName: '',
    messageConfig: initMessageConfig,
    taveiDayarSiteConfig: taveiDayarSiteConfig,
    guiConfig: initGuiConfig,
}

export interface IUserDetailsData {
    userName: string,
    userRole: UserRoleType,
}