import React from 'react';
import { useRequestManagmentPageContext } from '../../../contexts/request/RequestManagmentPageContext';
import DisplayManagmentRequestTavim from './components/DisplayManagmentRequestTavim';
import FormFilterManagmentRequestTavim from './components/FormFilterManagmentRequestTavim';
import HistoryModal from '../../../features/History/HistoryModal';
import PageTitle from '../../../Components/pageTitle/PageTitle';
import ConfirmModal from '../../../Components/GeneralModal/ConfirmModal';
import DangerAlert from '../../../Components/Alerts/DangerAlert';

const containerStyle: React.CSSProperties = { display: 'flex', flexDirection: 'column', rowGap: '2rem' };

const RequestManagementPage = () => {

    const { componentState, closeModalPreApproveReq, approveReq, confirmModalIsShown, refHistoryModal } = useRequestManagmentPageContext();


    return (
        <>
            <ConfirmModal
                title='אישור סופי של הבקשה'
                btnContinueLabel='אישור בקשה'
                content={
                    <div style={{ padding: '0.5rem', fontSize: '1.4rem' }}>
                        <p>
                            לאישור סופי של הבקשה.
                        </p>
                    </div>
                }
                isShow={confirmModalIsShown.isOpen}
                closingFun={closeModalPreApproveReq}
                rejsctFunc={closeModalPreApproveReq}
                fonfimFunc={() => approveReq()} /> 
    
            <HistoryModal ref={refHistoryModal} />
        
            <div style={containerStyle}>
                <DangerAlert isShow={componentState.hasAlert} message={componentState.message} />
                <PageTitle title={'ניהול בקשות'} />
                <FormFilterManagmentRequestTavim />
                <DisplayManagmentRequestTavim />
            </div>
        </>
    );
}

export default RequestManagementPage;