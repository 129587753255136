import React, { forwardRef, useImperativeHandle } from 'react';
import { useProjectDetailsContext } from '../../contexts/ProjectDetailsContext';
import { useSendMessageModal } from '../../Hooks/useSendMessageModal';
import GeneralModal from '../../Components/GeneralModal/GeneralModal';
import DangerAlert from '../../Components/Alerts/DangerAlert';
import EmailContainer from './components/email/EmailContainer';
import { SendMsgOptionType } from '../../types/sendMsgOption.types';
import RadioOutline from '../../Components/Forms/radio/RadioOutline';
import SmsContainer from './components/sms/SmsContainer';

const colStyle = { 'display': 'flex', 'gap': '0.5rem' };

const SendEmailModal = ({ }, ref) => {

    const { projectDetails } = useProjectDetailsContext();
    const { isOpenModal, openSendMessageModal, componentState, closeSendMessageModal,
        formik, handleClickOptionList, removeAlert } = useSendMessageModal();

    const messageConfig = projectDetails.messageConfig;
    const enableEmailOption = messageConfig.messageEmailConfig.generalEmailConfig.enableOption;
    const enableSmsOption = messageConfig.messageSmsConfig.generalSmsConfig.enableOption;
    const { hasAlert, message, isLoading } = componentState;

    useImperativeHandle(ref, () => ({
        openSendMessageModal
    }));

    if (!isOpenModal) {
        return null;
    }

    return (
        <GeneralModal
            isShow={isOpenModal}
            title='שליחת הודעה'
            closingFun={closeSendMessageModal}
            hasFooter={false}
            content={
                <div className='container'>
                    <div className='row mt-2'>
                        <div className='col-12'>
                            <DangerAlert message={message} isShow={(!isLoading && hasAlert)} onClose={removeAlert} />
                        </div>
                    </div>

                    <div className='row mt-2 mb-2'>
                        <div className='col' style={colStyle}>
                            <RadioOutline
                                onClick={handleClick}
                                disabled={!enableSmsOption}
                                name='sendViaEmail'
                                label='שליחת הודעה באמצעות מסרון'
                                id='sendViaEmailS'
                                value={SendMsgOptionType.SMS}
                                checked={SendMsgOptionType.SMS === formik.values.sendViaEmail} />
                            <RadioOutline
                                onClick={handleClick}
                                disabled={!enableEmailOption}
                                label='שליחת הודעה באמצעות אימייל'
                                name='sendViaEmail'
                                id='sendViaEmailE'
                                value={SendMsgOptionType.EMAIL}
                                checked={SendMsgOptionType.EMAIL === formik.values.sendViaEmail} />
                        </div>
                    </div>
                    <EmailContainer formik={formik} componentState={componentState} handleClickOptionList={handleClickOptionList} />
                    <SmsContainer formik={formik} componentState={componentState} handleClickOptionList={handleClickOptionList} />
                </div>
            }
        />
    );

    function handleClick(event: React.MouseEvent<HTMLInputElement>) {

        const isChecked = event.currentTarget.checked;
        const value = isChecked ? event.currentTarget.value : SendMsgOptionType.EMAIL;

        formik.setFieldValue(event.currentTarget.name, value);
    }
}

export default forwardRef(SendEmailModal);