import React from 'react';
import { useRequestManagmentPageContext } from '../../../../contexts/request/RequestDetailsPageContext';
import { useProjectDetailsContext } from "../../../../contexts/ProjectDetailsContext";
import PersonDetails from './PersonDetails';
import VehicleDetails from './VehicleDetails';
import CommentAndPic from './CommentAndPic';
import ContectInfo from './ContectInfo';
import PermitRequestDetails from './PermitRequestDetails';
import { PaymentMethod } from '../../../../types/types.import';
import PaymentDetails from './PaymentDetails';
import { useAppInfoContext } from '../../../../contexts/AppInfoContext';
import { AppUIBrackPoint } from '../../../../interface/appInfo.interface';

const RequestDetailsForm = () => {

    const { screenWidth } = useAppInfoContext();
    const { projectDetails } = useProjectDetailsContext();
    const { Formik } = useRequestManagmentPageContext();

    const { values } = Formik;

    const hasPayment = projectDetails.hasPayment;

    let paymentMethod = PaymentMethod.NONE;

    if (values.hasPaidCash) paymentMethod = PaymentMethod.CASH;
    if (values.hasPaidCredit) paymentMethod = PaymentMethod.CARD;

    const smallScreen = (screenWidth === AppUIBrackPoint.XS || screenWidth === AppUIBrackPoint.SM);

    return (
        <form>
            <div className={`row ${!smallScreen ? 'mb-3' : ''}`}>
                <div className={`col-sm-12 col-md-6 col-lg-4 ${smallScreen ? 'mb-3' : ''}`}>
                    <PersonDetails />
                </div>
                <div className={`col-sm-12 col-md-6 col-lg-8 ${smallScreen ? 'mb-3' : ''}`}>
                    <VehicleDetails />
                </div>
            </div>

            <div className={`row ${!smallScreen ? 'mb-3' : ''}`}>
                <div className={`col-sm-12 col-md-6 ${smallScreen ? 'mb-3' : ''}`}>
                    <CommentAndPic />
                </div>
                <div className={`col-sm-12 col-md-6 ${smallScreen ? 'mb-3' : ''}`}>
                    <ContectInfo />
                </div>
            </div>

            {
                hasPayment ? (
                    <div className={`row mb-3`}>
                        <div className='col-sm-12'>
                            <PaymentDetails />
                        </div>
                    </div>
                ) : ''
            }

            <div className={`row`}>
                <div className='col-sm-12'>
                    <PermitRequestDetails />
                </div>
            </div>
        </form>
    );
}

export default RequestDetailsForm;