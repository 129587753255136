import React, { FC } from 'react';

type TypeRadioOutline = {
    label: string,
    value: string | number,
    name: string,
    id: string,
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void,
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
    checked?: boolean,
    disabled?: boolean,
}

const RadioOutline: FC<TypeRadioOutline> = ({
    label,
    value,
    name,
    onClick = (e) => { },
    onBlur = (e) => { },
    id,
    checked = false,
    disabled = false,
}) => {

    return (
        <>
            <input
                defaultChecked={checked}
                value={value}
                id={id}
                name={name}
                onBlur={onBlur}
                onClick={onClick}
                className="btn-check"
                type='radio'
                autoComplete="off"
                disabled={disabled}
            />
            <label className="btn btn-outline-success" htmlFor={id}>
                {label}
            </label>
        </>
    );
}

export default RadioOutline;