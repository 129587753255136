import React, { FC } from 'react'
import CheckBtn from '../../../Components/Forms/check/CheckBtn';
import { decodeNCRStringIfNeeded } from '../../../Helpers/language';
import { IContentOption } from '../../../interface/projectConfig/messageConfig.interface';

type Props = {
    contentOptions: IContentOption[], 
    handleClick: (event: React.MouseEvent<HTMLInputElement>) => void, 
    handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void, 
    name: string,
    values: any, 
}

const TextsOptionContainer: FC<Props> = ({ contentOptions, handleClick, handleBlur, values, name }) => {

    const arrNames = name.split('.');
    let currentList = [];

    if(arrNames.length === 2) {
        
        currentList = [...values[arrNames[0]][arrNames[1]]];
    }

    return (
        <div>
            {contentOptions.map(textOption => {
                return (
                    <CheckBtn
                        onBlur={handleBlur}
                        name={name}
                        isChecked={currentList && currentList.includes(Number(textOption.code))}
                        value={textOption.code}
                        label={decodeNCRStringIfNeeded(textOption.text)}
                        id={textOption.text}
                        onClick={handleClick}
                        key={`textOptions${textOption.code}`}
                    />
                );
            })}
        </div>
    )
}

export default TextsOptionContainer;