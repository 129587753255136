import React from 'react';
import { createContext, useContext, useEffect, useReducer } from "react";
import { FormikProps, useFormik } from "formik";
import ExpiredPermitsPage from "../../Pages/Permits/expiredPermitsPage/ExpiredPermitsPage";
import { initExpiredPermitData, initExpiredPermitFormData } from "../../data/default/expiredPermitForm.data";
import { expiredPermitSchema } from "../../validation/permit/expiredPermitValidation";
import { EXPIRED_PERMITS_ACTION, expiredPermitsReducer } from "../../reducers/permits/expiredPermitsReducer";
import { convExpiredPermitRowData } from "../../Helpers/conversion/permit/convExpiredPermitRowData";
import { useProjectDetailsContext } from "../ProjectDetailsContext";
import { useComponentState } from "../../Hooks/useComponentState";
import {IExpiredPermitPageData, IExpiredPermitPageForm} from '../../interface/permit/expiredPermitPage.interface';
import { IComponentState } from "../../interface/IComponentState.interface";
import usePermitApi from '../../Hooks/api/usePermit.api';

type TypeExpiredPermitsPageContext = {
    Formik: FormikProps<IExpiredPermitPageForm>;
    handleGetExpiredPermits(values: IExpiredPermitPageForm): Promise<void>;
    expiredPermitsState: IExpiredPermitPageData;
    componentState: IComponentState;
}

export const ExpiredPermitsPageContext = createContext<TypeExpiredPermitsPageContext | undefined>(undefined);

const ExpiredPermitsPageProvider = () => {

    const { projectDetails } = useProjectDetailsContext();
    const { componentState, setDangerAlert, setIsLoading, setIsNotLoading } = useComponentState();
    const { getExpiredPermitsApi } = usePermitApi();
    const [expiredPermitsState, expiredPermitsDispatch] = useReducer(expiredPermitsReducer, initExpiredPermitData);

    const Formik = useFormik<IExpiredPermitPageForm>({
        initialValues: {...initExpiredPermitFormData},
        validateOnMount: true,
        validateOnChange: false,
        onSubmit: values => {
            handleGetExpiredPermits(values);
        }, 
        validationSchema: expiredPermitSchema,
    });

    const { values } = Formik;

    const typesMap = projectDetails.types;


    useEffect(() => {
        handleGetExpiredPermits(values);
      }, []);

    return (
        <ExpiredPermitsPageContext.Provider value={{ Formik, componentState, handleGetExpiredPermits, expiredPermitsState }}>
            <ExpiredPermitsPage />
        </ExpiredPermitsPageContext.Provider>
    );

    async function handleGetExpiredPermits(values: IExpiredPermitPageForm) {

        setIsLoading();

        const response = await getExpiredPermitsApi(values);

        if(!response.isSuccess) {

            setDangerAlert(response.message);
            return;
        }

        const permits = response.data.permits.map(expiredPermit => convExpiredPermitRowData(expiredPermit, typesMap));
        const count = response.data.count;

        expiredPermitsDispatch({
            type:EXPIRED_PERMITS_ACTION.UPDATE_LIST,
            payload:{ count: count, list: permits }
        });
        
        setIsNotLoading();
    }
}

export default ExpiredPermitsPageProvider;

export const useExpiredPermitsPageContext = () => useContext(ExpiredPermitsPageContext);