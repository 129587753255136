import React, { useState, createContext, useContext, useReducer, useMemo, useEffect, useRef, ChangeEvent } from "react";
import { FormikProps, useFormik } from "formik";
import RequestManagementPage from "../../Pages/request/requestManagmentPage/RequestManagementPage";
import { RequestStatusType, ResponseStatus } from "../../types/types.import";
import { useAuthContextContext } from "../AuthContext";
import { useAppInfoContext } from "../AppInfoContext";
import { useComponentState } from "../../Hooks/useComponentState";
import { permitRequestListPageReducer, PermitRequestPageKind } from "../../reducers/permitRequestListReducer";
import usePermitRequest from "../../Hooks/api/usePermitRequest.api";
import { AppFormNames } from "../../interface/appInfo.interface";
import { IPermitRequestFilterPage, IPermitRequestItemList, IPermitRequestListPage } from "../../interface/request/permitRequestListPage.interface";
import { IComponentState } from "../../interface/IComponentState.interface";
import { IRequestFromServer } from "../../interface/request/requestFromServer.interface";

const initDataConfirmModalIsShown = {
    isOpen: false, request: null,
}

const initRequestListData = {
    requests: [], count: 0,
}

type TypeConfirmModalIsShown = {
    isOpen: boolean;
    request: IRequestFromServer;
}

type TypeRequestManagmentPageContext = {
    Formik: FormikProps<IPermitRequestFilterPage>,
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void,
    componentState: IComponentState,
    handlerGetPermitsRequest: (values: any, offset?: number) => Promise<void>,
    handlerUpdateStatus: (requestId: number, newStatus: RequestStatusType) => Promise<void>,
    dataState: IPermitRequestListPage,
    refHistoryModal: React.RefObject<any>,
    changePage: (offset: number) => void,
    handleChengeAndOffset: (event: ChangeEvent<HTMLInputElement>) => void,
    openModalPreApproveReq: (requestItemList: IPermitRequestItemList) => void,
    closeModalPreApproveReq: () => void,
    approveReq: () => Promise<void>,
    confirmModalIsShown: TypeConfirmModalIsShown,
}

export const RequestManagmentPageContext = createContext<TypeRequestManagmentPageContext | undefined>(undefined);

const RequestManagmentPageProvider = () => {

    const { logout } = useAuthContextContext();
    const { updateInitForm, getCurrentInitForm } = useAppInfoContext();
    const { componentState, setDangerAlert, setIsLoading, setIsNotLoading } = useComponentState();
    const [confirmModalIsShown, setConfirmModalIsShown] = useState<TypeConfirmModalIsShown>(initDataConfirmModalIsShown);
    const { getPermitsRequest, updateStatus, approveRequestByReqId } = usePermitRequest();
    const [dataState, dataDispatch] = useReducer(permitRequestListPageReducer, initRequestListData);
    const refHistoryModal = useRef(null);

    const Formik = useFormik<IPermitRequestFilterPage>({
        initialValues: { ...getCurrentInitForm(AppFormNames.requestPermit, {}) },
        onSubmit: values => handlerGetPermitsRequest(values)
    });

    const { values, setFieldValue } = Formik;

    const handleChange = useMemo(() => {
        return (event: ChangeEvent<HTMLInputElement>) => {
            setFieldValue(event.target.name, event.target.value)
        };
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        handlerGetInitRequests(values, signal);

        return () => {
            console.log("clean up func");
            controller.abort();
        }
    }, []);

    return (
        <RequestManagmentPageContext.Provider value={{
            Formik,
            handleChange,
            componentState,
            handlerGetPermitsRequest, handlerUpdateStatus,
            dataState, refHistoryModal,
            changePage, handleChengeAndOffset, openModalPreApproveReq, closeModalPreApproveReq, approveReq,
            confirmModalIsShown
        }}>
            <RequestManagementPage />
        </RequestManagmentPageContext.Provider>
    );

    function handleChengeAndOffset(event: ChangeEvent<HTMLInputElement>) {

        setFieldValue('offset', 0);
        handleChange(event);
    }

    function changePage(offset: number) {

        setFieldValue('offset', offset);
        handlerGetPermitsRequest(values, offset);
    }

    async function handlerGetInitRequests(values: IPermitRequestFilterPage, signal) {

        const sentValues = { ...values };
        setIsLoading();

        updateInitForm(AppFormNames.requestPermit, sentValues);

        const response = await getPermitsRequest(sentValues, signal);

        if (response.status === ResponseStatus.FAILURE) {

            if (response.message === 'צריך להתחבר מחדש 401') {

                logout();
            }

            setDangerAlert(response.message);
            return;
        }

        dataDispatch({
            type: PermitRequestPageKind.UPDATE_LIST,
            payload: {
                count: response.data.count,
                requests: response.data.requests,
            }
        });

        setIsNotLoading();
    }

    async function handlerGetPermitsRequest(values: IPermitRequestFilterPage, offset = -1) {

        const sentValues = { ...values };
        setIsLoading();

        if (offset > -1) {
            sentValues.offset = offset;
        }

        updateInitForm(AppFormNames.requestPermit, sentValues);

        const response = await getPermitsRequest(sentValues);

        if (response.status === ResponseStatus.FAILURE) {

            if (response.message === 'צריך להתחבר מחדש 401') {

                logout();
            }

            setDangerAlert(response.message);
            return;
        }
// request
        console.log(response.data.requests);
        dataDispatch({
            type: PermitRequestPageKind.UPDATE_LIST,
            payload: {
                count: response.data.count,
                requests: response.data.requests
            }
        });

        setIsNotLoading();
    }

    async function handlerUpdateStatus(requestId: number, newStatus: RequestStatusType) {

        setIsLoading();

        const response = await updateStatus(requestId, newStatus);

        if (response.status === ResponseStatus.FAILURE) {

            if (response.message === 'צריך להתחבר מחדש 401') {
                logout();
            }

            setDangerAlert(response.message);
            return;
        }

        updateRequestInGUI(requestId, newStatus);
        setIsNotLoading();
    }

    function updateRequestInGUI(index: number, newStatus: RequestStatusType) {
        dataDispatch({
            type: PermitRequestPageKind.SWITCH_STATUS_REQUEST,
            payload: { rowId: index, status: newStatus }
        });
    }

    function openModalPreApproveReq(requestItemList: IPermitRequestItemList) {
        setConfirmModalIsShown({ isOpen: true, request: requestItemList.request });
    }

    function closeModalPreApproveReq() {
        setConfirmModalIsShown({ isOpen: false, request: null });
    }

    async function approveReq() {

        console.log(confirmModalIsShown.request)
        setIsLoading();
        closeModalPreApproveReq();

        const response = await approveRequestByReqId(confirmModalIsShown.request.id);
        setIsNotLoading();

        handlerGetPermitsRequest(values, values.offset);
    }
}

export default RequestManagmentPageProvider;

export const useRequestManagmentPageContext = () => useContext(RequestManagmentPageContext);