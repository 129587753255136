import React, { FC, useState } from 'react';
import { BsFillEyeSlashFill } from "react-icons/bs";
import { BsFillEyeFill } from "react-icons/bs";
import cssModule from './forms.module.css';

type Props = {
    hidePassword: boolean,
    showComponent: boolean,
    onClick: () => void,
    size?: 's' | 'm' | 'l',
}

const EyeIcon: FC<Props> = ({
    showComponent,
    hidePassword,
    onClick,
    size = 'm'
}) => {

    const [iconColor, setIconColor] = useState('#EAE2C6');
    if (!showComponent) return null;

    const iconSize = getIconSize(size);
    const color = '#EAE2C6';

    return (
        <span 
            className={cssModule.eyeIconSpan}
            //onMouseOut={() => setIconColor('#EAE2C6')}
            //onMouseEnter={() => setIconColor('#98D8EF')}
        >
            {hidePassword ? (
                <BsFillEyeSlashFill
                  
                    size={iconSize}
                    className={cssModule.eyeIcon}
                    onClick={onClick}

                />
            ) : (
                <BsFillEyeFill
             
                    className={cssModule.eyeIcon}
                    onClick={onClick}
                    size={iconSize}
                />
            )}
        </span>
    );

    function getIconSize(size: 's' | 'm' | 'l') {

        switch (size) {
            case 's':
                return '0.45rem';
            case 'm':
                return '0.9rem';
            case 'l':
                return '1.75rem';
            default:
                return '0.9rem';
        }
    }
}

export default EyeIcon;