import React from 'react';
import { useAddPermitPageContext } from '../../../../contexts/permit/AddPermitPageContext';
import InputGroup from '../../../../Components/Forms/InputGroup';
import FormSection from '../../../../Components/UI/formSection/FormSection';

const PermitPersonDetails = () => {
    const { Formik, handleChange, handleGetInitPermitData } = useAddPermitPageContext();

    const { errors, values, touched, handleBlur, setFieldValue } = Formik;
    const personTitle = 'פרטי האזרח';

    return (
        <FormSection title={personTitle}>
            <div className='row'>
                <div className='col-lg-6 col-xl-4'>
                    <InputGroup
                        name="privateName"
                        label="שם פרטי"
                        value={values.privateName}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        required={true}
                        errorMsg={(errors.privateName && touched.privateName) ? errors.privateName : undefined}
                    />
                </div>
                <div className='col-lg-6 col-xl-4'>
                    <InputGroup
                        name="familyName"
                        label="שם משפחה"
                        value={values.familyName}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        required={true}
                        errorMsg={(errors.familyName && touched.familyName) ? errors.familyName : undefined}
                    />
                </div>
                <div className='col-lg-6 col-xl-4'>
                    <InputGroup
                        name="personId"
                        label="תעודת זהות"
                        type="text"
                        value={values.personId}
                        handleChange={event => {
                            const name = event.target.name;
                            const value = event.target.value;
                            setFieldValue(name, value);

                            if (values.carNumber.length >= 5 && value.length === 9) {

                                handleGetInitPermitData();
                            }
                        }}
                        handleBlur={handleBlur}
                        required={true}
                        errorMsg={(errors.personId && touched.personId) ? errors.personId : undefined}
                    />
                </div>
            </div>
        </FormSection>
    );
}

export default PermitPersonDetails;