import React, { useState, useCallback, useEffect, FC } from 'react';
import Li from './Li';
import DropdownItem from './DropdownItem';

type UiListProps = {
    showNavBar: boolean;
}

const UiList: FC<UiListProps> = ({showNavBar}) => {

    const [activeNav, setActiveNav] = useState(1);
    const [isRepTabOpen, setIsRepTabOpen] = useState(false);

    const handleUserClickMouse = useCallback(event => {

        if (event.target.id !== 'reportTabId') {
            setIsRepTabOpen(prevUserText => false);
        }
    }, []);

    useEffect(() => {
        window.addEventListener("click", handleUserClickMouse);
        return () => {
            window.removeEventListener("click", handleUserClickMouse);
        };
    }, [handleUserClickMouse]);

    return (
        <div className={`collapse navbar-collapse ${showNavBar ? 'show' : ''}`} id="navbarNav">
            <ul className="navbar-nav">
                <Li nameTag='בקשות ממתינות לאישור'
                    activeNavId={1}
                    currentActiveId={activeNav}
                    link='/permit-request/requestManagementPage'
                    setActiveNav={setActiveNav} />
                <Li
                    nameTag='יצירת תו'
                    activeNavId={2}
                    link='/permits/addPermit/-1'
                    currentActiveId={activeNav}
                    setActiveNav={setActiveNav} />
                <Li
                    nameTag='ניהול תווים'
                    activeNavId={3}
                    link='/permits/permitManagementPage'
                    currentActiveId={activeNav}
                    setActiveNav={setActiveNav} />
                <li className={`nav-item dropdown ${isRepTabOpen ? 'show' : ''}`}>
                    <a
                        id='reportTabId'
                        onClick={onClickReportTab}
                        className={`nav-link dropdown-toggle ${activeNav === 4 ? 'active' : ''}`}
                        href="#" role="button" data-bs-toggle="dropdown"
                        aria-expanded={isRepTabOpen}>
                        {'דוחות  '}
                    </a>
                    <ul className={`dropdown-menu ${isRepTabOpen ? 'show' : ''}`}>
                        <DropdownItem
                            nameTag='דוח תווים'
                            link='/permits/report/permit-report'
                            setActiveNav={setActiveNav}
                            activeNavId={4}
                        />
                        <DropdownItem
                            nameTag='דוח פגי תוקף'
                            activeNavId={4}
                            link='/permits/report/expired-permits'
                            setActiveNav={setActiveNav}
                        />
                        <li><hr className="dropdown-divider" /></li>
                        <DropdownItem
                            nameTag='דוח תשלומים'
                            activeNavId={4}
                            link='/reports/payments-report'
                            setActiveNav={setActiveNav}
                        />
                    </ul>
                </li>
            </ul>
        </div>);

    function onClickReportTab() {
        setIsRepTabOpen(prevUserText => !prevUserText);
    }
}

export default UiList;
