import React from 'react';
import ComparingFilesStatus from './ComparingFilesStatus';
import { RequestStatusType } from '../../../../types/types.import';

const SpanRequestStatus = ({ reqStatusCode, statusName, comparingFStatus }) => {

    let statusBadgeClaess = 'badge ';

    if (reqStatusCode === RequestStatusType.PENDING) {

        statusBadgeClaess += 'badge bg-primary';
    } else if (reqStatusCode === RequestStatusType.APPROVED) {

        statusBadgeClaess += 'bg-light text-dark';
    } else if (reqStatusCode === RequestStatusType.REJECTED) {

        statusBadgeClaess += 'bg-danger';
    } else {

        statusBadgeClaess += 'bg-secondary';
    }

    return (
        <div style={{display: 'flex', gap: '0.125rem', fontSize: '1rem'}}>
            <span className={statusBadgeClaess} style={{ fontSize: '1rem' }}>
                {statusName}
            </span>
            <ComparingFilesStatus comparingFStatus={comparingFStatus} />
        </div>
    );
}

export default SpanRequestStatus;