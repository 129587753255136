import { paymentMethodTextHeb, PermitStatusTextHeb } from "../../interface/text";
import {PaymentMethod, PermitStatusType, HistoryRecordType, 
    RequestCreatedBy, RequestStatusType,
    PermitType} from "../../types/types.import";


export function convertCodeCreatedByToText(createdByCode: RequestCreatedBy | string): string {
    
    switch (createdByCode) {
        case RequestCreatedBy.CITIZEN:
            return 'אזרח';
        case RequestCreatedBy.SECRETARY:
            return 'קבלת קהל';
        default:
            return 'בעיה בנתונים';
    }
}

export function convertCodeStatusRequestToText(statusCode: RequestStatusType): string {

    switch (statusCode) {
        case RequestStatusType.PENDING:
            return 'ממתינה לאישור';
        case RequestStatusType.APPROVED:
            return 'בקשה אושרה';
        case RequestStatusType.REJECTED:
            return 'בקשה נדחתה';
        default:
            return 'בעיה בנתונים';
    }
}

export function convertPermitStatusCodeToText(code: PermitStatusType): PermitStatusTextHeb {
    switch (code) {
        case PermitStatusType.ACTIVE:
            return 'פעיל';
        case PermitStatusType.NOT_ACTIVE:
            return 'לא פעיל';
        case PermitStatusType.CANCELED:
            return 'בוטל';
        case PermitStatusType.SUSPENDED:
            return 'משהה';
        default:
            return 'אין נתונים';
    }
} 

export function convertPaymentMethodCodeToText(code: PaymentMethod): paymentMethodTextHeb {
    switch (code) {
        case PaymentMethod.CARD:
            return 'אשראי';
        case PaymentMethod.CASH:
            return 'מזומן';
        case PaymentMethod.NONE:
            return 'ללא תשלום';
        default:
            return 'אין נתונים';
    }
} 

export function convertHistoryStatusCodeToText(code: number): string {

    if (code === HistoryRecordType.TAV_RENEW_STATUS_SENT_TO_SERVER) {
        return 'חידוש תו';
    } else if (code === HistoryRecordType.TAV_STATUS_CREATED_BY_SYSTEM) {
        return 'יצירת בקשה/תו עי ידי מערכת';
    } else if (code === HistoryRecordType.TAV_STATUS_INSERTED_BY_BACK_OFFICE) {
        return 'יצירת ';
    } else if (code === HistoryRecordType.TAV_STATUS_INSERTED_BY_CITIZEN) {
        return 'יצירת בקשה על ידי אזרח';
    } else if (code === HistoryRecordType.TAV_STATUS_INSERTED_BY_OFFICE) {
        return 'יצירת בקשה/תו בקבלת קהל';
    } else if (code === HistoryRecordType.TAV_STATUS_SENT_TO_SERVER_6) {
        return 'אישור בקשה';
    } else if (code === HistoryRecordType.TAV_STATUS_UPDATED_BY_OFFICE) {
        return 'עידכון בקשה/תו';
    } else if (code === HistoryRecordType.SENT_EMAIL_STATUS) {
        return 'נשלח אימייל';
    } else if (code === HistoryRecordType.PEMIT_CANCELED_PORTAL) {
        return 'תו בוטל מהפורטל';
    } else if (code === HistoryRecordType.ADD_FILE_TO_REQUEST_PORTAL) {
        return 'הוספת קובץ מהפורטל';
    } else if (code === HistoryRecordType.SENT_SMS_STATUS) {
        return 'נשלח מסרון';
    } else {
        return 'אין נתונים';
    }
}

export function permitTypeCodeToText(permitType: PermitType): string {

    switch (permitType) {
        case PermitType.GOLDEN:
            return 'תו גיל זהב';
        case PermitType.REGULAR:
        case PermitType.ALL:
            return 'תו דייר';
        case PermitType.STUDENT:
            return 'סטודנט';
        case PermitType.MUNICIPAL_EMPLOYEE:
            return 'עובד עירייה';
        case PermitType.HANDICAPPED:
            return 'בעל תו נכה';
        default:
            return 'אין נתונים';
    }
}