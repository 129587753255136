import React from 'react';
import AlertCloseBtn from './AlertCloseBtn';
import { AlertColors } from '../../types/alertColors.types';
import { GrStatusGood } from 'react-icons/gr';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { AiOutlineAlert } from 'react-icons/ai';
import { BsInfoCircle } from 'react-icons/bs';


type Props = {
    type: AlertColors;
    isShow: boolean;
    message: string | React.ReactNode;
    subject?: string | React.ReactNode;
    iconStart?: React.ReactNode;
    onClose?: () => void;
}

const Alert = ({ type, message, iconStart, isShow, onClose, subject }: Props) => {

    
    let classes = '';
    let iconStartSpan: string | React.ReactNode = '';
    
    classes = getFullBootstarpClass(type);
    iconStartSpan = getDefaultIconByClass(type);

    if (!isShow) {
        return null;
    }

    if (iconStart != null) iconStartSpan = <span>{iconStart}</span>;

    return (
        <>
            {subject ? (
                <div className={classes} role="alert">
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1.2rem' }}>
                        <div style={{
                            display: 'flex', justifyContent: 'space-between',
                            alignItems: 'center', gap: '0.5rem',
                        }}>
                            {iconStartSpan}
                            <span style={{ display: 'inline', fontSize: '1.2rem', fontWeight: '500' }}>{subject}</span>
                        </div>
                        <div>
                            {onClose && <AlertCloseBtn onClick={onClose} />}
                        </div>
                    </div>
                    <div style={{ padding: '0.2rem', fontSize: '1.1rem' }}>
                        {message ? message : ''}
                    </div>
                </div>
            ) : ''}
            {!subject ? (
                <div className={classes} role="alert">
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1.2rem' }} >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
                            {iconStartSpan}
                            <span style={{ display: 'inline', fontSize: '1.1rem' }}>{message}</span>
                        </div>
                        {onClose && <AlertCloseBtn onClick={onClose} />}
                    </div>
                </div>
            ) : ''}
        </>
    );

    function getDefaultIconByClass(type: AlertColors): React.ReactNode | null {

        let icon: React.ReactNode | null = null;

        switch (type) {
            case AlertColors.SUCCESS:
                icon = <GrStatusGood size={27} />;
                break;
            case AlertColors.DANGER:
                icon = <MdOutlineErrorOutline size={27} />;
                break;
            case AlertColors.WARNING:
                icon = <AiOutlineAlert size={27} />;
                break;
            case AlertColors.INFO:
                icon = <BsInfoCircle size={27} />;
                break;
            case AlertColors.LIGHT:
            case AlertColors.DARK:
            case AlertColors.SECONDARY:
                break;
            default:
                break;
        }

        return icon;
    }

    function getFullBootstarpClass(type: AlertColors): string {

        let classes = "alert alert-primary";

        switch (type) {
            case AlertColors.SUCCESS:
                classes = "alert alert-success";
                break;
            case AlertColors.DANGER:
                classes = "alert alert-danger";
                break;
            case AlertColors.WARNING:
                classes = "alert alert-warning";
                break;
            case AlertColors.INFO:
                classes = "alert alert-info";
                break;
            case AlertColors.LIGHT:
                classes = "alert alert-light";
                break;
            case AlertColors.DARK:
                classes = "alert alert-dark";
                break;
            case AlertColors.SECONDARY:
                classes = "alert alert-secondary";
                break;
            default:
                break;
        }

        return classes;
    }
}

export default Alert;

