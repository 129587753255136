import React, { useEffect, useState } from 'react';
import { CiImageOn } from "react-icons/ci";
import { VscFilePdf } from "react-icons/vsc";
import { GrNext, GrPrevious } from "react-icons/gr";
import { useRequestManagmentPageContext } from '../../../../../contexts/request/RequestDetailsPageContext';
import RequestFileViewer from './RequestFileViewer';
import GeneralModal from '../../../../../Components/GeneralModal/GeneralModal';
import ComparisonDataWithFilesDisplay from './ComparisonDataWithFilesDisplay';
import DangerAlert from '../../../../../Components/Alerts/DangerAlert';
import LoadingDiv from '../../../../../Components/UI/loadingDiv/LoadingDiv';
import { FileTypes } from '../../../../../types/types.import';
import { initComparisonRequestDataAndFiles } from '../../../../../data/default/requestPermit.data';
import style from '../../requestDetailsPage.module.css';
import useFiles from '../../../../../Hooks/api/useFiles.api';
import { IRequest } from '../../../../../interface/request/request.interafe';


const FilesModal = ({ isShow }) => {

  const { closeFilesModal, Formik, getComparisonDataWithFiles } = useRequestManagmentPageContext();
  const { getRequestFilesApi } = useFiles();

  const { values } = Formik;

  const [files, setFiles] = useState([]);
  const [activeFile, setActiveFile] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingComparison, setIsLoadingComparison] = useState(false);
  const [message, setMessage] = useState('');

  const [comparisonData, setComparisonData] = useState(initComparisonRequestDataAndFiles);

  let content: string | React.ReactNode = '';
  let filesContainer: string | React.ReactNode = '';
  let previewFile: string | React.ReactNode = '';

  if (!isLoading) {

    previewFile = (
      <div className={style.previewFileBtnsContainet}>
        <button onClick={prevFile} className={style.noneStyleBtn + " " + style.prevBtn}>
          <GrNext />
        </button>
        {files.map((file, index) => {

          let classes = style.previewFileBtn;
          let isPdf = file.type === FileTypes.PDF_TYPE ? true : false;

          if (activeFile === index) {

            classes += ' ' + style.previewFileBtnActive;
          }

          return (
            <button
              key={`moveFile${index}`}
              type="button"
              onClick={() => setActiveFile(index)}
              className={classes}
            >
              {isPdf ? <VscFilePdf /> : <CiImageOn />}
            </button>
          );
        })}
        <button onClick={nextFile} className={style.noneStyleBtn + " " + style.nextBtn}>
          <GrPrevious />
        </button>
      </div>
    );

    filesContainer = (
      <div id="carouselExampleIndicators" className={"carousel slide " + style.filesContainer}>
        <div className="carousel-inner">
          {files.map((file, index) => {

            let isActive = false;

            if (activeFile === index) isActive = true;

            return <RequestFileViewer nextFile={nextFile} prevFile={prevFile} key={`file_${file.lastModified}_${index}`} isActive={isActive} file={file} />
          })
          }
        </div>
      </div>
    );
  }

  if (isLoading) {

    filesContainer = <LoadingDiv />;
  }

  useEffect(() => {

    if (isShow) {
      handleGetRequestFiles(values);
      handleGetComparisonDataWithFiles(values);
    }
  }, [values, isShow]);

  content = (
    <div className={style.filesModalContent}>
      <div className={style.filesModalFilesItem}>
        {previewFile}
        {filesContainer}
      </div>
      <div className={style.filesModalDetailsItem}>
        <DangerAlert isShow={message ? true : false} message={message} />
        <ComparisonDataWithFilesDisplay comparisonData={comparisonData} isLoadingComparison={isLoadingComparison} />
      </div>
    </div>
  );

  if (!isShow) {
    return null;
  }

  return (
    <GeneralModal
      isShow={isShow}
      hasFooter={false}
      title={'תמונות ופרטים'}
      closingFun={closeFilesModal}
      content={content} modalSize={'XL'}
    />
  );

  function nextFile() {

    if (activeFile >= files.length - 1) {

      setActiveFile(0);
      return;
    }

    setActiveFile(activeFile + 1);
  }

  function prevFile() {

    if (activeFile === 0) {

      setActiveFile(files.length - 1);
      return;
    }

    setActiveFile(activeFile - 1);
  }

  async function handleGetComparisonDataWithFiles(request: IRequest) {

    setMessage('');
    setIsLoadingComparison(true);

    const res = await getComparisonDataWithFiles(request)

    if (res.isFailed) {

      setMessage(res.message);
    } else {

      setComparisonData(res.comparisonRequestDataAndFiles);
    }

    setIsLoadingComparison(false);

  }

  async function handleGetRequestFiles(request: IRequest) {

    setIsLoading(true);
    const { personId, citizenIdPath, carIdPath, pathScandLeasing, extraFilesPaths } = request;

    const files = await getRequestFilesApi(personId, citizenIdPath, carIdPath, pathScandLeasing, extraFilesPaths);

    setFiles(files);

    setIsLoading(false);
  }
}



export default FilesModal;