import React from 'react';
import Style from './Navbar.module.css';
import UiList from './UiList';
import { useProjectDetailsContext } from "../../../contexts/ProjectDetailsContext";
import UserDetailsBox from './UserDetailsBox';
import { decodeNCRStringIfNeeded } from '../../../Helpers/language';

const Navbar = () => {

    const { projectDetails } = useProjectDetailsContext();
    const [showNavBar, setShowNavBar] = React.useState(false);
    const navClassStyle = "navbar navbar-expand-sm navbar-light " + Style.navbar;
    const classesNavBarnd = 'navbar-brand ' + Style.brandNavbar;

    return (
        <nav className={navClassStyle}>
            <div className="container-fluid">
                <div className={classesNavBarnd}>
                    <img className={Style.cityLogUrl} src={projectDetails.cityLogUrl} />
                    <div>
                        <div>ניהול תווי דייר</div>
                        <div className={Style.projectNameDiv}>{decodeNCRStringIfNeeded(projectDetails.projectName)}</div>
                    </div>
                </div>
                <button
                    className={`${showNavBar ? 'navbar-toggler collapsed' : 'navbar-toggler'}`}
                    onClick={() => setShowNavBar((state) => !state)}
                    type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded={`${showNavBar ? 'true' : 'false'}`}
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <UiList showNavBar={showNavBar} />
                <UserDetailsBox />
            </div>
        </nav>
    );

}

export default Navbar;