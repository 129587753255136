import React from 'react';
import RedStar from './RedStar';

type Props = {
    label: string;
    value: string | number;
    name: string;
    handleChange: (event: React.ChangeEvent<any>) => void;
    handleBlur: (e: React.FocusEvent<any>) => void;
    errorMsg?: string;
    required: boolean;
    disabled: boolean;
    rows: number;
    placeholder: string;
    inputStyle: React.CSSProperties;
};

const InputTextArea = ({ label, value, name, handleChange, handleBlur, errorMsg, required, disabled, rows, placeholder, inputStyle }: Props) => {

    let classes = 'form-control';
    const hasError = errorMsg ? true : false;

    if (hasError) {
        classes += ' is-invalid';
    }

    return (
        <>
            {required ? <RedStar /> : ''}
            {label ? <label className="form-label" htmlFor={`${name}Id`}>{label}</label> : ''}
            <textarea
                style={inputStyle}
                placeholder={placeholder}
                className={classes}
                rows={rows}
                disabled={disabled}
                value={value}
                required={required}
                name={name}
                id={`${name}Id`}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            {hasError ? <div id='asas' className="invalid-feedback"> {errorMsg} </div> : ''}
        </>
    )
}

InputTextArea.defaultProps = {
    label: '',
    disabled: false,
    required: false,
    rows: 3,
    placeholder: '',
    inputStyle: {},
}

export default InputTextArea;