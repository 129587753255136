import React, { FC } from 'react';
import { useProjectDetailsContext } from '../../../contexts/ProjectDetailsContext';
import SelectWithSearch from './SelectWithSearch';
import { decodeNCRStringIfNeeded } from '../../../Helpers/language';
import { ISelectOption } from '../../../interface/form.interface';


interface SelectVehicleGroupProps {
    name: string;
    value: number;
    handleChange: (name: string | number, value: string | number) => void,
    handleBlur: (e: React.FocusEvent<HTMLInputElement, Element>) => void,
    errorMsg?: string;
    required?: boolean;
    withEmptyField?: boolean;
}


const SelectVehicleGroup: FC<SelectVehicleGroupProps> = ({
    name,
    value,
    handleBlur,
    handleChange,
    errorMsg = '',
    required = false,
    withEmptyField = false,
}) => {

    const { projectDetails } = useProjectDetailsContext();
    const vehicleGroupList = projectDetails.vehicleGroups;

    const options = getColorOptions();

    const valueStr = options.find(option => option.value == value);

    return (
        <SelectWithSearch
            errorMsg={errorMsg}
            name={name}
            label={'סוג רכב'}
            value={valueStr ? valueStr : null}
            required={required}
            selects={options}
            handleBlur={handleBlur}
            handleChange={handleChange} />
    );

        function getColorOptions() {
    
            let options: ISelectOption[] = vehicleGroupList.map(vehicleGroup => {

                return { label: decodeNCRStringIfNeeded(vehicleGroup.vehicleGroupName), value: vehicleGroup.id }
            });

            options = [{ value: -1, label: withEmptyField ? '' : 'אחר' }, ...options];
        
            if(withEmptyField) {
                return options;
            }

            if(required) {
    
                options = [{value: -1, label: 'צריך לבחור'}, ...options];
                return options;
            }
    
            return options;
        }
}

export default SelectVehicleGroup;