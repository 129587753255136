export enum HistoryRecordType {
	TAV_STATUS_INSERTED_BY_CITIZEN = 1,
	TAV_STATUS_INSERTED_BY_OFFICE = 2,
	TAV_STATUS_UPDATED_BY_OFFICE = 3,
	TAV_STATUS_SENT_TO_SERVER_6 = 4,// TAV_STATUS_APPROVED_BY_OFFICE
	TAV_STATUS_CREATED_BY_SYSTEM = 5,
	TAV_STATUS_INSERTED_BY_BACK_OFFICE = 7,
	TAV_RENEW_STATUS_SENT_TO_SERVER = 8,
	SENT_EMAIL_STATUS = 9,
	PEMIT_CANCELED_PORTAL = 10,
	ADD_FILE_TO_REQUEST_PORTAL = 11,
	SENT_SMS_STATUS = 12,
}