import moment from "moment";
import { IPermitFilterPage } from "../../interface/permit/permitListPage.interface";

export const initSearchPermitFormData: IPermitFilterPage = {
    offset: 0,
    limit: 10,
    loginUser: '',
    from: moment(new Date()).subtract(3,'d').format("YYYY-MM-DD"),
    to: moment(new Date()).format("YYYY-MM-DD"),
    status: -1,
    zone: '',
    permitId: '',
    personId: '',
    streetId: -1,
    houseNum: '',
    houseNumTo: '',
    appatmentNum: '',
    firstName: '',
    lastName: '',
    carNumber: '',
    isAdvancedSearchOpen: false, 
    vehicleGroupId: -1,
    vehicleColorId: -1,
    vehicleMakerId: -1, 
}