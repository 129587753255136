import React, { FC, ReactNode } from 'react';
import SmallLoadingDiv from '../../loadingDiv/SmallLoadingDiv';
import style from './paging.module.css';
import PrevPageBtn from './PrevPageBtn';
import PageBtn from './PageBtn';
import NextPageBtn from './NextPageBtn';
import { useAppInfoContext } from '../../../../contexts/AppInfoContext';
import { AppUIBrackPoint } from '../../../../interface/appInfo.interface';

type PagingBubtonsType = {
    offsetInt: number,
    numOfPages: number,
    isLoading: boolean,
    changePage: (newOffset: number) => void,
}

const PagingBubtons: FC<PagingBubtonsType> = ({ offsetInt, numOfPages, isLoading, changePage }) => {

    const { screenWidth } = useAppInfoContext();

    const showLimitedPaging = (screenWidth === AppUIBrackPoint.XS || screenWidth === AppUIBrackPoint.SM);

    const numOfJumps = 3;
    const prevPages = [];
    const nextPages = [];
    let countLoop = 0;
    let loadingIcom: string | ReactNode = '';

    for (let i = offsetInt; i > 0; i--) {

        if (countLoop === numOfJumps) break;
        countLoop++;
        prevPages.unshift(i);
    }

    countLoop = 0;

    for (let i = offsetInt + 1; i < numOfPages; i++) {

        if (countLoop === numOfJumps) break;
        countLoop++;
        nextPages.push(i);
    }

    let hasPrevBtn = false;
    let hasNextBtn = false;
    let hasFirstBtn = false;
    let hasLastBtn = false;
    let hasFirstDots = false;
    let hasLastDots = false;


    if ((!showLimitedPaging) && (offsetInt - numOfJumps - 1) > 0) hasFirstDots = true;
    if ((!showLimitedPaging) && numOfPages - offsetInt - 2 > numOfJumps) hasLastDots = true;
    if ((!showLimitedPaging) && (offsetInt - numOfJumps) > 0) hasFirstBtn = true;
    if (offsetInt > 0) hasPrevBtn = true;
    if ((numOfPages - offsetInt) - 1 > 0) hasNextBtn = true;
    if ((!showLimitedPaging) && numOfPages - offsetInt - 1 > numOfJumps) hasLastBtn = true;

    if(numOfPages < 2) return null;

    if (isLoading) loadingIcom = <SmallLoadingDiv />

    return (
        <div style={{ display: 'flex', alignItems: 'flex-end', columnGap: '0.2rem' }}>

            <PrevPageBtn disabled={!hasPrevBtn} onClick={() => changePage(offsetInt - 1)} />

            {hasFirstBtn && (
                <PageBtn page={1} onClick={() => changePage(0)} />
            )}

            {hasFirstDots && <span>...</span>}

            {(!showLimitedPaging) && prevPages.map(num => {
                return <PageBtn key={`prevPages${num}`} page={num} onClick={() => changePage(num - 1)} />
            })}

            <span className={style.currentPage}>{offsetInt + 1}</span>

            {(!showLimitedPaging) && nextPages.map(num => {
                return <PageBtn key={`nextPages${num}`} page={num + 1} onClick={() => changePage(num)} />
            })}

            {hasLastDots && <span>...</span>}
            {hasLastBtn && (
                <PageBtn page={numOfPages} onClick={() => changePage(numOfPages - 1)} />
            )}

            <NextPageBtn disabled={!hasNextBtn} onClick={() => changePage(offsetInt + 1)} />
            {loadingIcom}
        </div>

    );
}

export default PagingBubtons;