import { IPermitReportPageData, IPermitReportRow } from "../../interface/permit/permitReportPage.interface";

export enum PermitReportPageActionKind {
    UPDATE_FROM = 'UPDATE_FROM',
    UPDATE_DATA = 'UPDATE_DATA'
}

// An interface for our actions
type PermitReportPageAction = {
    type: PermitReportPageActionKind.UPDATE_FROM;
    payload: {
        name: string,
        value: string
    };
} | {
    type: PermitReportPageActionKind.UPDATE_DATA;
    payload: {
        permitReportRows: IPermitReportRow[],
        totalCardPayment: number,
        totalCashPayment: number,
        totalCitizenCount: number,
        totalCount: number,
        totalPayment: number,
        footer: (string | number) [],
    };
};

export const permitReportReducer = (state: IPermitReportPageData, action: PermitReportPageAction) => {
    
    switch (action.type) {
        case PermitReportPageActionKind.UPDATE_FROM:
    
            return {...state, form: {...state.form, [action.payload.name]: action.payload.value}};
        case PermitReportPageActionKind.UPDATE_DATA:
            
            return {...state,
                permitReportRows: action.payload.permitReportRows, 
                totalCardPayment: action.payload.totalCardPayment,
                totalCashPayment: action.payload.totalCashPayment,
                totalCitizenCount: action.payload.totalCitizenCount,
                totalCount: action.payload.totalCount,
                totalPayment: action.payload.totalPayment,
                footer: action.payload.footer
            };        
        default:
            return state;
    }
} 