import React, { FC } from 'react';
import TextsOptionContainer from './TextsOptionContainer';
import InputErrorMessage from '../../../Components/Forms/InputErrorMessage';
import style from '../sendEmail.module.css';
import { IContentOption } from '../../../interface/projectConfig/messageConfig.interface';
import { IMessageCitizenModal } from '../../../interface/messageCitizenModal.interface';

type Props = {
    values: IMessageCitizenModal,
    contentOptions: IContentOption[],
    errorChecksInputAndFreeText: any,
    handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
    handleClick: (event: React.MouseEvent<HTMLInputElement>) => void,
    name: string,
}

const TextOptionsRow: FC<Props> = ({ contentOptions, errorChecksInputAndFreeText, handleBlur, handleClick, values, name }) => {

    return (
        <>
            <span className={style.rejectionOption}>רשימת תגובות מובנות</span>
            <TextsOptionContainer contentOptions={contentOptions} handleBlur={handleBlur} handleClick={handleClick} values={values} name={name} />
            <InputErrorMessage message={errorChecksInputAndFreeText} />
        </>
    );
}

export default TextOptionsRow;