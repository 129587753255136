import React, { FC, ReactNode } from 'react';
import style from './forms.module.css'

type Props = {
  children: ReactNode,
  onSubmit?: (e?: React.FormEvent<HTMLFormElement>) => void,
}

const FormBox:FC<Props> = ({ children, onSubmit = null }) => {
  return (
    <form onSubmit={onSubmit} className={style.formBox}> {children} </form>
  );
}

export default FormBox;