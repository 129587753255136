import React, { FC } from 'react';
import style from '../sendEmail.module.css';

type Props = {
  title: string
}

const SmallTitle: FC<Props> = ({ title }) => {
  return (
    <h5 className={style.smallTitle}>
      {title}
    </h5>
  );
}

export default SmallTitle;