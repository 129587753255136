import React from 'react';
import { FiUploadCloud } from 'react-icons/fi';
import { usePermitDetailsPageContext } from '../../../../contexts/permit/PermitDetailsPageContext';
import ActionBoxCard from '../../../../Components/UI/Card/ActionBoxCard';
import { Button, SaveBtn, ViewFilesBtn, GoBackBtn } from '../../../../Components/Buttons/buttuns.import';
import HistoryPermitList from '../../../../Components/Buttons/History/HistoryPermitList';
import ActionInnerBoxCard from '../../../../Components/UI/Card/ActionInnerBoxCard';

const PermitDetailsButtonsBox = () => {

    const { Formik, componentState, handleUpdatePermit, refHistoryModal,
        openUploadFileModal } = usePermitDetailsPageContext();

    const { values } = Formik;

    const isDisabled = componentState.isLoading ? true : false;
    const openHistoryModalFunc = refHistoryModal.current?.openHistoryModal ? refHistoryModal.current.openHistoryModal : null;

    return (
        <ActionBoxCard>
            <ActionInnerBoxCard>
                <SaveBtn disabled={isDisabled} onClick={event => handleUpdatePermit(event)} />
                <HistoryPermitList onClick={() => openHistoryModalFunc(false, values.permitId)} />
                <Button text='הוספת קובץ' key={'openUploadImageModal'} onClick={openUploadFileModal} endIcon={<FiUploadCloud />} />
                <ViewFilesBtn disabled={isDisabled} personId={values.personId} />
            </ActionInnerBoxCard>
            <ActionInnerBoxCard flexGrow={1} justifyContent='flex-end'>
                <GoBackBtn />
            </ActionInnerBoxCard>
        </ActionBoxCard>
    )
}

export default PermitDetailsButtonsBox;