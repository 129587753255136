import React from "react";
import GeneralModal from "./GeneralModal";
import ContinueModalBtn from "./ContinueModalBtn";
import CloseModalBtn from "./CloseModalBtn";

interface ConfirmModalProps {
    title: string;
    content: string | React.ReactNode;
    btnContinueLabel?: string;
    closingFun: () => void;
    rejsctFunc: () => void;
    fonfimFunc: () => void;
    isShow: boolean;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ 
    title, content, btnContinueLabel, closingFun, rejsctFunc, fonfimFunc, isShow 
}) => {

    if (!isShow) {
        return null;
    }

    return (
        <GeneralModal
            isShow={isShow}
            title={title}
            content={content}
            closingFun={closingFun}
            footer={(
                <div style={{display: 'flex', flexWrap: 'wrap' , gap: '0.2rem'}}>
                    <ContinueModalBtn onClick={fonfimFunc} label={btnContinueLabel} />
                    <CloseModalBtn onClick={rejsctFunc} />
                </div>
            )} 
        />
    );
}

export default ConfirmModal;