import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetFiles } from '../../Hooks/useGetFiles';
import GoBackBtn from '../../Components/Buttons/GoBackBtn';
import PageTitle from '../../Components/pageTitle/PageTitle';
import GeneralFileContainerViewer from './components/GeneralFileContainerViewer';
import ListContainer from './components/ListContainer';
import FormBox from '../../Components/Forms/FormBox';
import LoadingModal from '../../Components/LoadingModal/LoadingModal';
import DangerAlert from '../../Components/Alerts/DangerAlert';

const ViewFilesPage = () => {

    const params = useParams();
    const personId = params?.personId ? params.personId : '-1';

    const { files, getFiles, componentState } = useGetFiles();
    const [indexOfFile, setIndexOfFile] = useState(0);

    useEffect(() => {

        if (personId)
            getFiles(personId);
    }, [personId]);

    return (
        <>
            {componentState.isLoading ? <LoadingModal /> : ''}
            <DangerAlert isShow={componentState.hasAlert} message={componentState.message}/>

            <div className='row mt-3'>
                <PageTitle title={'קבצים'} />
            </div>
            <div className='row mt-3'>
                <div className='col-12'>
                    <FormBox>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <GoBackBtn />
                        </div>
                    </FormBox>
                </div>
            </div>

            <div className={`row mt-3`}>
                <div className='col-5'>
                    <div style={{
                        direction: 'ltr',
                        overflowY: 'auto',
                        height: '36rem'
                    }}>
                        <ListContainer files={files} handleSetFile={index => setIndexOfFile(index)} />
                    </div>
                </div>
                <div className='col-7'>
                    <GeneralFileContainerViewer files={files} index={indexOfFile} />
                </div>
            </div>
        </>
    );
}


export default ViewFilesPage;