import React, { FC } from 'react';
import { useProjectDetailsContext } from '../../../contexts/ProjectDetailsContext';
import SelectWithSearch from './SelectWithSearch';
import { decodeNCRStringIfNeeded } from '../../../Helpers/language';
import { ISelectOption } from '../../../interface/form.interface';


export interface ISearchSelectOption {
    value: number;
    label: string;
}

interface SelectStreetProps {
    name: string;
    value: number;
    handleChange: (name: string | number, value: string | number) => void,
    handleBlur: (e: React.FocusEvent<HTMLInputElement, Element>) => void,
    errorMsg?: string;
    required?: boolean;
}

const SelectStreet: FC<SelectStreetProps> = ({
    name,
    value,
    handleBlur,
    handleChange,
    errorMsg = '',
    required = false,
}) => {

    const { projectDetails } = useProjectDetailsContext();
    let streets: ISelectOption[] = [{ value: -1, label: 'אין אופציות' }];

    streets = projectDetails.streets.map((street, index) => {

        return { label: decodeNCRStringIfNeeded(street.name), value: street.id }
    });

    streets = [{ value: -1, label: '' }, ...streets];

    const valueStr = streets.find(option => option.value == value);

    return (
        <SelectWithSearch
            name={name}
            label={'רחוב'}
            selects={streets}
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={valueStr}
            errorMsg={errorMsg}
            required={required}
        />
    );
}

export default SelectStreet;