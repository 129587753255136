import { IPermit } from '../../../interface/permit/permit.interface';
import { ISystemTableColor, ISystemTableStreet, ISystemTableVehicleGroup, ISystemTableVehicleMakers } from '../../../interface/projectConfig/projectConfig.interface';
import { PermitType } from '../../../types/types.import';
import { decodeNCRStringIfNeeded } from '../../language';
import { convertPermitStatusCodeToText } from '../convertTypeCodeToText';
import { convertColorCodeToText, convertMakerCodeToText, convertStreetCodeToText, convertVehicleGroupCodeToText } from '../convertorTablesCodeToText';

export function convPermitRowRawDataFromServerCsv(
    data: IPermit,
    colors: ISystemTableColor[],
    makers: ISystemTableVehicleMakers[],
    street: ISystemTableStreet[],
    vehicleGroups: ISystemTableVehicleGroup[]) {

    const vehicle = data.vehicle;
    const resident = data.resident;

    const statusCode = data.statusTav;
    const colorCode = vehicle.vehicleColor;
    const makerCode = vehicle.vehicleMake;
    const vehicleGroupCode = vehicle.vehicleGroupId;
    const streetCode = resident.streetId;
    const startValidDateLong = data.startValidityDate;
    const endValidDateLong = data.endValidityDate;

    const colorName = convertColorCodeToText(colorCode, colors);
    const makerName = convertMakerCodeToText(makerCode, makers);
    const streetName = convertStreetCodeToText(streetCode, street);
    const vehicleGroupName = convertVehicleGroupCodeToText(vehicleGroupCode, vehicleGroups);

    const status = convertPermitStatusCodeToText(statusCode);

    return {
        permitId: data.id ? data.id : -1,
        personId: resident.personId ? resident.personId : '',
        fName: decodeNCRStringIfNeeded(resident.lastName),
        lName: decodeNCRStringIfNeeded(resident.firstName),
        street: decodeNCRStringIfNeeded(streetName),
        houseNum: resident.houseNum ? resident.houseNum : '',
        appatmentNum: resident.appatmentNum ? resident.appatmentNum : '',
        vehicleNum: vehicle.vehicleNum ? vehicle.vehicleNum : '',
        typeCode: '',
        descType: '',
        colorCode: colorCode,
        color: decodeNCRStringIfNeeded(colorName),
        makerCode: makerCode,
        maker: decodeNCRStringIfNeeded(makerName),
        zone: data.zone ? data.zone : -1,
        permitBy: '0',
        streetCode: streetCode,
        startDate: startValidDateLong > 0 ? new Date(startValidDateLong).toLocaleDateString() : '',
        endDate: endValidDateLong > 0 ? new Date(endValidDateLong).toLocaleDateString() : '',
        codeTypePermit: data.tavGilZahav ? data.tavGilZahav : PermitType.ALL,
        codeResAppl: '',
        mobile: resident.cellPhone ? resident.cellPhone : '',
        commet: resident.comments ? decodeNCRStringIfNeeded(resident.comments) : '',
        vehicleGroupName: decodeNCRStringIfNeeded(vehicleGroupName),
    };
}