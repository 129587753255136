import React from 'react';
import SimpleTable from '../../../../Components/UI/displayData/Table/SimpleTable';
import BtnsRow from './BtnsRow';
import SimpleCard from '../../../../Components/UI/Card/SimpleCard';
import { IHeaderData } from '../../../../Components/UI/displayData/Table/Table';
import ViewPermitBtn from '../../../../Components/Buttons/ViewPermitBtn';

const DisplayExpiredPermits = ({ list, csvHeader }) => {

    const rows = list.map(expiredPermit => {
        return {
            fullName: expiredPermit.fullName,
            vehicleNum: expiredPermit.vehicleNum,
            cellPhone: expiredPermit.cellPhone,
            additionalPhone: expiredPermit.additionalPhone,
            endValidityDate: expiredPermit.endValidityDate,
            makerName: expiredPermit.makerName,
            biewPermitBtn: <ViewPermitBtn rowDbId={expiredPermit.id} />,
        };
    });

    const header: IHeaderData[] = [
        {
            key: 'fullName',
            label: 'שם מלא',
            name: 'fullName',
        },
        {
            key: 'vehicleNum',
            label: 'מספר רכב',
            name: 'vehicleNum',
        },
        {
            key: 'cellPhone',
            label: 'סלולרי',
            name: 'cellPhone',
        }, {
            key: 'additionalPhone',
            label: 'מספר טלפון נוסף',
            name: 'additionalPhone',
        }, {
            key: 'endValidityDate',
            label: 'תאריך סיום',
            name: 'endValidityDate',
        }, {
            key: 'makerName',
            label: 'יצרן',
            name: 'makerName',
        }, {
            key: 'biewPermitBtn',
            label: 'פעולות',
            name: 'biewPermitBtn',
        }
    ];

    return (
        <>
            <BtnsRow list={list} header={csvHeader} />
            <div className='row'>
                <SimpleCard>
                    <div className='col'>
                        <SimpleTable
                            headers={header}
                            rows={rows}
                            name={'תווים'} />
                    </div>
                </SimpleCard>
            </div>
        </>
    );
}

export default DisplayExpiredPermits;
