import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import style from './modal.module.css';

type ModalOverlayProps = {
  children: ReactNode;
  modalSize: 'XS' | 'S' | 'M' | 'L' | 'XL';
}

type ModalProps = {
  children: ReactNode;
  modalSize?: 'XS' | 'S' | 'M' | 'L' | 'XL';
}

const Backdrop = () => {
  return <div className={style.backdrop}></div>
}

const ModalOverlay: React.FC<ModalOverlayProps> = ({ children, modalSize }) => {

  let classes = style.modal;

  switch (modalSize) {
    case 'XS':
      classes += ' ' + style.modalSizeXS;
      break;
    case 'S':
      classes += ' ' + style.modalSizeS;
      break;
    case 'M':
      classes += ' ' + style.modalSizeM;
      break;
    case 'L':
      classes += ' ' + style.modalSizeL;
      break;
    case 'XL':
      classes += ' ' + style.modalSizeXL;
      break;
    default:
      classes += ' ' + style.modalSizeM;
      break;
  }

  return (
    <div className={classes}>
      {children}
    </div>
  );
}

const portalElement = document.getElementById('overlays');

const Modal: React.FC<ModalProps> = ({ children, modalSize = 'M' }) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(<Backdrop />, portalElement)}
      {ReactDOM.createPortal(<ModalOverlay modalSize={modalSize}>{children}</ModalOverlay>, portalElement)}
    </React.Fragment>
  );
}

export default Modal;