import React, { ChangeEvent, ReactNode } from 'react';
import RedStar from '../RedStar';

type Props = {
    name: string; 
    label: string; 
    errorMsg: ReactNode | string | undefined; 
    selects: any[]; 
    required: boolean; 
    handleChange: (event: ChangeEvent<HTMLSelectElement>) => void; 
    handleBlur: (e: React.FocusEvent<any>) => void; 
    value: string | number; 
    disabled: boolean;
}

const SelectInput = ({ name, label, errorMsg, selects, required, handleChange, handleBlur, value, disabled }: Props) => {

    let classes = 'form-select ';
    const options = selects.map((option, index) => {

        return <option key={`select${name}_${index}`} value={option.value}>{option.name}</option>;
    });

    const hasError = errorMsg ? true : false;

    if (hasError) {
        classes += ' is-invalid';
    }
    
    return (
        <>

            <label className="form-label" htmlFor={`${name}id`}>
                {label}
                {required && <RedStar />}
            </label>

            <select
                className={classes}
                id={`${name}id`}
                name={name}
                value={value}
                onChange={handleChange}
                required={required}
                onBlur={handleBlur}
                aria-label="Default select example"
                disabled={disabled}
                >
                {options}
            </select>
            {hasError ? <div id='asas' className="invalid-feedback"> {errorMsg} </div> : ''}
        </>
    );
}

SelectInput.defaultProps = {
    disabled: false,
    errorMsg: '',
    required: false,
  //  handleChange: () => {},
    handleBlur: () => {}, 
    value: -1,
}

export default SelectInput;