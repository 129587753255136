import React, { FC } from 'react';
import FormBox from '../../../../Components/Forms/FormBox';
import DateInput from '../../../../Components/Forms/DateInput';
import SearchBtnLoading from '../../../../Components/Buttons/SearchBtnLoading';
import { IPermitReportPageData } from '../../../../interface/permit/permitReportPage.interface';

type Props = {
    dataState: IPermitReportPageData,
    updateFormInput: (event: any) => void,
    getPermitReportRows: () => void,
    isLoading: boolean
}

const PermitReportFilter: FC<Props> = ({ dataState, updateFormInput, getPermitReportRows, isLoading }) => {


    return (
        <FormBox onSubmit={onSubmitHandler}>
            <div className="row">
                <div className="col-9">
                    <div className="row">
                        <div className="col col-lg-6 col-xl-4">
                            <DateInput
                                value={dataState.form.fromDate}
                                label='תאריך התחלה'
                                name="fromDate"
                                required={true}
                                handleChange={updateFormInput}
                            />
                        </div>
                        <div className="col col-lg-6 col-xl-4">
                            <DateInput
                                label='תאריך סוף'
                                name="toDate"
                                value={dataState.form.toDate}
                                required={true}
                                handleChange={updateFormInput}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-3 align-self-end">
                    <div className='d-flex justify-content-end'>
                        <SearchBtnLoading onClick={onSubmitHandler} isLoading={isLoading} />
                    </div>
                </div>
            </div>
        </FormBox>
    );


    function onSubmitHandler(event: React.FormEvent<HTMLFormElement>) {

        event.preventDefault();
        getPermitReportRows();
    }
}

export default PermitReportFilter;