import React from 'react';
import { useAddPermitPageContext } from '../../../../contexts/permit/AddPermitPageContext';
import InputGroup from '../../../../Components/Forms/InputGroup';
import {OwnershipCarType} from '../../../../types/ownershipCar.types';
import OutlineCheckBtn from '../../../../Components/Forms/check/OutlineCheckBtn';
import FormSection from '../../../../Components/UI/formSection/FormSection';

const colStyle: React.CSSProperties = { 'display': 'flex', 'flexDirection': "column", 'rowGap': '0.5rem' }

const FilesCard = () => {

    const { Formik } = useAddPermitPageContext();

    const { errors, values, touched, validateField, handleBlur, setFieldValue } = Formik;
 
    const fileTitle = 'קבצים';
    const ownershipCarType = Number(values.ownershipCarType);
    let workStatementfileDis = values.hardCopyLeasing === '1' ? true : false;
    let personIdfileDis = values.hardCopyPersonId === '1' ? true : false;
    let carNumberfileDis = values.hardCopyLicense === '1' ? true : false;

    return (
        <FormSection title={fileTitle}>
            <div className="row">
                <div className="col-lg-6 col-xl-4" style={colStyle}>
                    <InputGroup
                        type='file'
                        label={'תעודת זהות'}
                        name={'personIdfile'}
                        required={!personIdfileDis}
                        disabled={personIdfileDis}
                        handleChange={handleFile}
                        handleBlur={handleBlur}
                        //@ts-ignore
                        errorMsg={(errors.personIdfile && touched.personIdfile) ? errors.personIdfile : undefined}
                    />
                    <OutlineCheckBtn
                        label={'תעודת זהות וספח'}
                        name='hardCopyPersonId'
                        id={'hardCopyPersonId'}
                        value={'1'}
                        isChecked={values.hardCopyPersonId && values.hardCopyPersonId === '1'}
                        onClick={handleClick}
                        onBlur={event => validateField('personIdfile')}
                        />
                </div>

                <div className="col-lg-6 col-xl-4" style={colStyle}>
                    <InputGroup
                        type='file'
                        label={'רשיון רכב'}
                        name={'carLicensefile'}
                        required={!carNumberfileDis}
                        disabled={carNumberfileDis}
                        handleChange={handleFile}
                        handleBlur={handleBlur}
                        //@ts-ignore
                        errorMsg={(errors.carLicensefile && touched.carLicensefile) ? errors.carLicensefile : undefined}
                    />
                    <OutlineCheckBtn
                        label={'רשיון רכב בתוקף'}
                        id={'hardCopyLicense'}
                        name='hardCopyLicense'
                        value={'1'}
                        isChecked={values.hardCopyLicense && values.hardCopyLicense === '1'}
                        onClick={handleClick}
                        onBlur={event => validateField('carLicensefile')}
                    />
                </div>
                {ownershipCarType === OwnershipCarType.COMPANY ? (
                    <div className='col-lg-6 col-xl-4' style={colStyle}>
                        <InputGroup
                            type='file'
                            label={'הצהרה ממקום העבודה'}
                            name={'workStatementfile'}
                            required={!workStatementfileDis}
                            disabled={workStatementfileDis}
                            handleChange={handleFile}
                            handleBlur={handleBlur}
                            //@ts-ignore
                            errorMsg={(errors.workStatementfile && touched.workStatementfile) ? errors.workStatementfile : undefined}
                        />
                        <OutlineCheckBtn
                            label={'הצהרה ממקום העבודה'}
                            id={'hardCopyLeasing'}
                            name='hardCopyLeasing'
                            value={'1'}
                            isChecked={values.hardCopyLeasing && values.hardCopyLeasing === '1'}
                            onClick={handleClick}
                            onBlur={event => validateField('workStatementfile')}
                        />
                    </div>
                ) : ''}
            </div>
        </FormSection>
    );

    function handleClick(event) {

        const isChecked = event.currentTarget.checked;
        const value = isChecked ? event.currentTarget.value : '0'
    
        setFieldValue(event.currentTarget.name, value);
    }

    function handleFile(event) {
        setFieldValue(event.currentTarget.name, event.currentTarget.files[0])
    }
}

export default FilesCard;