import React, { useState, createContext, useContext, useMemo, useEffect, useRef, FC } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { FormikProps, useFormik } from "formik";
import { useProjectDetailsContext } from "../ProjectDetailsContext";
import DateUtils from "../../Helpers/time/DateUtils";
import RequestDetailsPage from "../../Pages/request/requestDetails/RequestDetailsPage";
import { requestSchema } from "../../validation/request/permitRequestValidation";
import { RequestStatusType, ResponseStatus } from "../../types/types.import";
import { decodeNCRStringIfNeeded } from "../../Helpers/language";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import { useComponentState } from "../../Hooks/useComponentState";
import { IRequest } from "../../interface/request/request.interafe";
import { IComponentState } from "../../interface/IComponentState.interface";
import { initRequestPermitData } from "../../data/default/requestPermit.data";
import usePermitRequest, { GetComparisonDataWithFilesResultType } from "../../Hooks/api/usePermitRequest.api";

type RequestDetailsPageContextType = {
    Formik: FormikProps<IRequest>;
    handleChange: (event: React.ChangeEvent<any>) => void;
    handleUpdateRequest(): Promise<void>;
    handleApprove(): Promise<void>;
    handlePreApprove(): Promise<void>;
    handleUpdateStatus(newStatus: RequestStatusType): Promise<void>
    getComparisonDataWithFiles: (request: IRequest) => Promise<GetComparisonDataWithFilesResultType>
    getPermitRequest: any;
    successModalIsShown: boolean;
    setSuccessModalIsShown(isOpen: boolean): void;
    confirmModalIsShown: boolean;
    setConfirmModalIsShown(isOpen: boolean): void;
    handleCloseModal(): void;
    componentState: IComponentState;
    setIsLoading: () => void; 
    setIsNotLoading: () => void;
    requestId: number; 
    setRequestId: (requestId: number) => void;
    isFileModalOpen: boolean; 
    closeFilesModal: () => void; 
    openFilesModal:  () => void; 
    refSendEmailModal: React.RefObject<any>;
    refHistoryModal: React.RefObject<any>;
    greatestEndDateOfActivePermit: string; 
    setGreatestEndDateOfActivePermit: (requestId: string) => void; 
    exitingPermitWithSamePId: number; 
    setExitingPermitWithSamePId: (requestId: number) => void;
}

export const RequestDetailsPageContext = createContext<RequestDetailsPageContextType | undefined>(undefined);

const RequestDetailsPageProvider: FC = () => {

    const { projectDetails } = useProjectDetailsContext();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const params = useParams();
    const dateUtils = new DateUtils();
    const { saveRequest, approveRequest, getPermitRequest, getComparisonDataWithFiles } = usePermitRequest();
    const { componentState, setDangerAlert, setIsLoading, setIsNotLoading } = useComponentState();
    const [requestId, setRequestId] = useState(-1);
    const [successModalIsShown, setSuccessModalIsShown] = useState(false);
    const [confirmModalIsShown, setConfirmModalIsShown] = useState(false);
    const [isFileModalOpen, setIsFileModalOpen] = useState(false);
    const [greatestEndDateOfActivePermit, setGreatestEndDateOfActivePermit] = useState('');
    const [exitingPermitWithSamePId, setExitingPermitWithSamePId] = useState(0);

    const refHistoryModal = useRef(null);
    const refSendEmailModal = useRef(null);

    const Formik = useFormik<IRequest>({
        initialValues: initRequestPermitData,
        validateOnMount: true,
        validateOnChange: false,
        validationSchema: requestSchema,
        onSubmit: () => { },
    });

    const { values, setFieldValue, validateForm, setFieldTouched, setErrors } = Formik;

    const handleChange = useMemo(() => {
        return (event: React.ChangeEvent<any>) => {
            setFieldValue(event.target.name, event.target.value)
        };
    }, []);

    // onload page
    useEffect(() => {

        if (requestId > 0)
            handleOnLoadingPage();
    }, [requestId]);

    useEffect(() => {

        setRequestId(Number(params.requestId));
    }, [params.requestId]);

    async function handleOnLoadingPage() {

        setIsLoading();
        const response = await getPermitRequest(requestId);
        const taveiDayarSiteConfig = projectDetails.taveiDayarSiteConfig;
        const colorAndType = taveiDayarSiteConfig.colorAndType;

        if (response.status === ResponseStatus.FAILURE) {

            setDangerAlert(response.message);
            return;
        }

        const citizenSticker = response.data.citizenSticker;
        const endDate = response.data.endDate;
        const greatestEndDateOfActivePermit = response.data.greatestEndDateOfActivePermit;
        const exitingPermitWithSamePId = response.data.exitingPermitWithSamePId;

        const vColor = colorAndType ? citizenSticker.agreeDataTransfer : 164;
        const vType = colorAndType ? citizenSticker.agreeToVolunteer : 2096;

        setGreatestEndDateOfActivePermit(greatestEndDateOfActivePermit);
        setExitingPermitWithSamePId(exitingPermitWithSamePId);

        setFieldValue('projectId', citizenSticker.projectId);
        setFieldValue('id', citizenSticker.id);
        setFieldValue('carNum', citizenSticker.carNum);
        setFieldValue('personId', citizenSticker.personId);
        setFieldValue('phone', citizenSticker.phone);
        setFieldValue('mobile', citizenSticker.mobile);
        setFieldValue('house', citizenSticker.house);
        setFieldValue('appartment', citizenSticker.appartment, true);
        setFieldValue('street', citizenSticker.street);
        setFieldValue('email', citizenSticker.email);
        setFieldValue('hasPaidCash', citizenSticker.hasPaidCash);
        setFieldValue('hasPaidCredit', citizenSticker.hasPaidCredit);
        setFieldValue('asmachta', citizenSticker.asmachta);
        setFieldValue('moneyAmount', citizenSticker.moneyAmount);
        setFieldValue('reportNum', citizenSticker.reportNum);
        setFieldValue('insertUserId', citizenSticker.insertUserId);
        setFieldValue('tavDayar', citizenSticker.tavDayar);
        setFieldValue('hardCopyPersonId', citizenSticker.hardCopyPersonId === "1" ? 1 : 0);
        setFieldValue('hardCopyLicense', citizenSticker.hardCopyLicense === "1" ? 1 : 0);
        setFieldValue('hardCopyLeasing', citizenSticker.hardCopyLeasing === "1" ? 1 : 0);
        setFieldValue('approveUserId', citizenSticker.approveUserId);
        setFieldValue('userInsertName', citizenSticker.userInsertName);
        setFieldValue('createdBy', citizenSticker.createdBy);
        setFieldValue('status', citizenSticker.status);

        setFieldValue('privateName', decodeNCRStringIfNeeded(citizenSticker.privateName));
        setFieldValue('familyName', decodeNCRStringIfNeeded(citizenSticker.familyName));
        setFieldValue('mailCity', decodeNCRStringIfNeeded(citizenSticker.mailCity));
        setFieldValue('mailHouse', decodeNCRStringIfNeeded(citizenSticker.mailHouse));
        setFieldValue('mailStreet', decodeNCRStringIfNeeded(citizenSticker.mailStreet));
        setFieldValue('mailAppartment', decodeNCRStringIfNeeded(citizenSticker.mailAppartment));
        setFieldValue('mailMikod', decodeNCRStringIfNeeded(citizenSticker.mailMikod));
        setFieldValue('secreteryMessage', decodeNCRStringIfNeeded(citizenSticker.secreteryMessage));
        setFieldValue('endValidityDate', decodeNCRStringIfNeeded(endDate));

        setFieldValue('vColor', vColor);
        setFieldValue('vType', vType);
        setFieldValue('vehicleGroupName', citizenSticker.vehicleGroupName);
        setFieldValue('permitType', citizenSticker.tavGilZahav);
        setFieldValue('ownershipCarType', citizenSticker.vehiclePrivte);

        setFieldValue('citizenIdPath', citizenSticker.citizenIdPath);
        setFieldValue('carIdPath', citizenSticker.carIdPath);
        setFieldValue('workStatmentPath', citizenSticker.workStatmentPath);
        setFieldValue('extraFilesPaths', citizenSticker.extra3);
        setFieldValue('extra4Map', citizenSticker.extra4);

        let userRegisterDate = dateUtils.getDateAndTimeStringFromUnixTimestams(Number(citizenSticker.userRegisterDate));
        let updateDate = dateUtils.getDateAndTimeStringFromUnixTimestams(Number(citizenSticker.updateDate));
        let approvalDate = '';

        if (Number(citizenSticker.approvalDate) > 0) {

            approvalDate = dateUtils.getDateAndTimeStringFromUnixTimestams(Number(citizenSticker.approvalDate));
        }

        setFieldValue('userRegisterDate', userRegisterDate);
        setFieldValue('updateDate', updateDate);
        setFieldValue('approvalDate', approvalDate);

        setErrors({});
        setIsNotLoading();
    }

    async function handleUpdateStatus(newStatus: RequestStatusType) {
        const requestId = values.id;

        const form = new FormData();
        form.append('requestId', String(requestId));
        form.append('newStatus', String(newStatus));

        try {
            const response = await axiosPrivate.post('/request/update-status-request', form);
            const data = response.data;
            
            if (data.status === ResponseStatus.FAILURE) {

                setDangerAlert('בעיה כללית בשרת');
                return;
            }

            setFieldValue('status', newStatus);

        } catch (error) {

            console.log('error: ' + error);
        }
    }

    async function handleUpdateRequest() {

        setIsLoading();

        // validate all form.
        const jsonErrors = await validateForm();

        // update all fields as touched.
        Object.keys(values).forEach((name) => {

            setFieldTouched(name, true);
        });

        // if there is errors write to console and stop prosses.
        if (Object.keys(jsonErrors).length > 0) {

            setIsNotLoading();
            console.log(JSON.stringify(jsonErrors));
            return;
        }

        const responseData = await saveRequest(values);

        if (responseData.status === ResponseStatus.SUCCESS) {

            setSuccessModalIsShown(true);
            setIsNotLoading();
        } else {

            setDangerAlert('בעיה כללית בשרת');
        }
    }

    async function handleApprove() {

        setIsLoading();

        const response = await approveRequest(values);

        if (response.status === ResponseStatus.SUCCESS) {

            navigate('/permit-request/requestManagementPage');
            return;
        }

        setDangerAlert(response.message);
    }

    async function handlePreApprove() {

        // validate all form.
        const jsonErrors = await validateForm();

        // update all fields as touched.
        Object.keys(values).forEach((name) => {

            setFieldTouched(name, true);
        });

        // if there is errors write to console and stop prosses.
        if (Object.keys(jsonErrors).length > 0) {

            setIsNotLoading();
            console.log(JSON.stringify(jsonErrors));
            return;
        }

        setConfirmModalIsShown(true);
    }

    function handleCloseModal() {

        setSuccessModalIsShown(false);
        navigate('/permit-request/requestManagementPage');
    }

    function closeFilesModal() {
        setIsFileModalOpen(false);
    }

    function openFilesModal() {

        setIsFileModalOpen(true);
    }

    return (
        <RequestDetailsPageContext.Provider value={{
            handleChange, Formik,
            getComparisonDataWithFiles,
            handleUpdateRequest, handleApprove, handlePreApprove, handleUpdateStatus, getPermitRequest,
            successModalIsShown, setSuccessModalIsShown,
            confirmModalIsShown, setConfirmModalIsShown, handleCloseModal,
            componentState, setIsLoading, setIsNotLoading,
            requestId, setRequestId,
            isFileModalOpen, closeFilesModal, openFilesModal, refSendEmailModal, refHistoryModal,
            greatestEndDateOfActivePermit, setGreatestEndDateOfActivePermit, exitingPermitWithSamePId, setExitingPermitWithSamePId
        }}>
            <RequestDetailsPage />
        </RequestDetailsPageContext.Provider>
    );
}

export default RequestDetailsPageProvider;

export const useRequestManagmentPageContext = () => useContext(RequestDetailsPageContext);