import * as yup from 'yup';
import { onlyEnglishAndHebrewAndSpace, onlyNumbersRegExp, phoneRegExp, zipRegExp } from '../../Helpers/regExps';
import { isFirstDateGreaterOrSame, isPersonIdValid } from '../../Helpers/vatidation/validation';
import { START_DATE_GREATER_THEN_END_DATE_MESSAGE } from '../../Errors/errorMessages';
import DateUtils from '../../Helpers/time/DateUtils';


const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];
const MAX_FILE_SIZE = 5942880; // 5*1024*1024 bytes
const ONLY_DIGIT_PERMITED_MESSAGE = 'יכול להכיל ספרות בלבד';
const ONLY_LETTERS_HEBREW_OR_ENGLISH_PERMITED_MESSAGE = 'יכול להכיל אותיות בעברית ,אנגלית ורווח בלבד.';

const MAX_FILE_SIZE_MESSAGE = 'קובץ גדול מידי, ניתן לעלות עד 5 MB';
const TYPE_FILE_MESSAGE = `ניתן לעלות קבצים מסוג: ${SUPPORTED_FORMATS} בלבד.`;
const REQUIRED_FIELD_MESSAGE = 'שדה חובה!';
const MOBILE_FORMAT_MESSAGE = 'מספר סלולרי אינו תקין';
const MIKOD_MESSAGE = 'מיקוד לא תקין';

export const emailSchema = yup.object().shape({
    email: yup.string().email().required()
})

export const requestSchema = yup.object().shape({
    carNum: yup.string()
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE)
        .min(5, 'חייב להכיל לפחות 5 ספרות')
        .max(8, 'לכל היותר 8 ספרות')
        .required(REQUIRED_FIELD_MESSAGE),
    vColor: yup.number()
        .notOneOf([-1], 'אנא בחר צבע')
        .required(REQUIRED_FIELD_MESSAGE),
    vType: yup.number()
        .notOneOf([-1], 'אנא בחר דגם רכב')
        .required(REQUIRED_FIELD_MESSAGE),
    privateName: yup.string()
        .required(REQUIRED_FIELD_MESSAGE),
    familyName: yup.string()
        .required(REQUIRED_FIELD_MESSAGE),
    personId: yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .test('valid-id', 'מספר ת.ז אינו חוקי. צריך להכיל ספרת ביקורת', value => isPersonIdValid(value)),
    street: yup.number()
        .notOneOf([-1], 'אנא בחר רחוב')
        .required(REQUIRED_FIELD_MESSAGE),
    house: yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE),
    email: yup.string()
        .email('כתובת אימייל לא חוקית')
        .required(REQUIRED_FIELD_MESSAGE),
    mobile: yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .matches(phoneRegExp, MOBILE_FORMAT_MESSAGE),
    phone: yup.string()
        .matches(phoneRegExp, MOBILE_FORMAT_MESSAGE),
    mailCity: yup.string()
        .optional()
        .matches(onlyEnglishAndHebrewAndSpace, ONLY_LETTERS_HEBREW_OR_ENGLISH_PERMITED_MESSAGE),
    mailStreet: yup.string()
        .optional()
        .matches(onlyEnglishAndHebrewAndSpace, ONLY_LETTERS_HEBREW_OR_ENGLISH_PERMITED_MESSAGE),
    mailHouse: yup.string()
        .optional()
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE),
    mailAppartment: yup.string()
        .optional()
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE),
    mailMikod: yup.string()
        .optional()
        .matches(zipRegExp, MIKOD_MESSAGE),
    endValidityDate: yup.string()
    .required(REQUIRED_FIELD_MESSAGE)
    .test('end-date-greater-then-start', START_DATE_GREATER_THEN_END_DATE_MESSAGE, function(value) {
        const dateUtils = new DateUtils();


        const nowDate = dateUtils.getDateString();
        return !isFirstDateGreaterOrSame(nowDate, value);
    }),
    extraFile1: yup.mixed()
        .notRequired()
        .nullable()
        .test(
            "FILE_SIZE",
            MAX_FILE_SIZE_MESSAGE,
            (value) => {

                //@ts-ignore
                return !value || (value && value.size <= MAX_FILE_SIZE)
            }
        )
        .test(
            "FILE_FORMAT",
            TYPE_FILE_MESSAGE,
            (value) => {

                //@ts-ignore
                return !value || (value && SUPPORTED_FORMATS.includes(value?.type))
            }
        )
    ,
})