import React, { ReactNode } from 'react';
import Select from 'react-select';
import RedStar from '../RedStar';
import { ISelectOption } from '../../../interface/form.interface';

export interface IReactSelectOption {
    value: number,
    label: string,
    options: any
}

interface SelectWithSearchProps {
    label: string;
    name: string;
    value: ISelectOption;
    selects: ISelectOption[];
    handleChange: (name: string | number, value: string | number) => void,
    handleBlur: (e: React.FocusEvent<HTMLInputElement, Element>) => void,
    errorMsg?: string | ReactNode | undefined;
    required?: boolean;
    disabled?: boolean;
}

const SelectWithSearch: React.FC<SelectWithSearchProps> = ({
    name, label, value, selects, handleChange,
    errorMsg = '',
    handleBlur = undefined,
    disabled = false,
    required = false
}: SelectWithSearchProps) => {

    let classes = '';
    const placeholder = 'צריך לבחור';

    const hasError = errorMsg ? true : false;

    if (hasError) {
        classes = ' is-invalid';
    }

    return (
        <>
            <label className="form-label" htmlFor={`${name}id`}>
                {label}
                {required && <RedStar />}
            </label>
            <Select
                placeholder={placeholder}
                className={classes}
                isRtl={true}
                options={selects}
                onChange={(newValue: IReactSelectOption, actionMeta) => {
                    handleChange(actionMeta.name, newValue.value);
                }}
                onBlur={handleBlur}
                value={value}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? '#0d6efd' : (hasError ? 'red' : '#dee2e6'),
                    }),
                }}
            />
            {hasError ? <div id='asas' className="invalid-feedback"> {errorMsg} </div> : ''}
        </>
    );
}

export default SelectWithSearch;