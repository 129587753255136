import React, { ReactNode } from 'react';
import Style from './Page.module.css';

type Props = {
    children: ReactNode;
}

const Page = ({children}: Props) => {

    return (
        <main className={Style.page}>
            {children}
        </main>
    );
}

export default Page;