import React from 'react';
import { RiArrowGoBackLine } from 'react-icons/ri';
import Button from './Button';
import { ButtonColors } from '../../types/button.types';

const GoBackBtn = () => {

    return (
        <Button
            theme={ButtonColors.SECONDARY}
            onClick={onClickHandle}
            endIcon={<RiArrowGoBackLine />}
            text='חזור'
        />
    );

    function onClickHandle() {
        window.history.back();
    }
}


export default GoBackBtn;