import { Languages } from "../types/languages.types.types"
import { IPermitFilterPage } from "./permit/permitListPage.interface"
import { IPermitRequestFilterPage } from "./request/permitRequestListPage.interface"

export enum AppFormNames {
    permit = 'permit',
    requestPermit = 'requestPermit',
}


export enum AppUIBrackPoint {
    XS = 'XS',
    SM = 'SM',
    MD = 'MD',
    LG = 'LG',
    XL = 'XL',
    XXL = 'XXL',
}

export interface IAppInfoData {
    languages: Languages,
    formsInitData: {
        requestPermit: {
            updateDate: string,
            currentForm: IPermitRequestFilterPage,
            initForm: IPermitRequestFilterPage,
        },
        permit: {
            updateDate: string,
            currentForm: IPermitFilterPage,
            initForm: IPermitFilterPage,
        }
    }
}   