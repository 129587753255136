import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineFileSearch } from 'react-icons/ai';
import Button from './Button';
import { ButtonSizes } from '../../types/button.types';

type ViewPermitBtnProps = {
    rowDbId: number;
    size?: ButtonSizes;
}

const ViewPermitBtn: React.FC<ViewPermitBtnProps> = ({ rowDbId, size = ButtonSizes.MEDIUM }) => {

    const url = `/permits/permit-details/${rowDbId}`;

    return (
        <Link to={url}>
            <Button onClick={() => {}} size={size} endIcon={<AiOutlineFileSearch />} text='צפייה בתו' />
        </Link>
    );
}

export default ViewPermitBtn;