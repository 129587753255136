import React from 'react';
import SimpleCard from '../../../../Components/UI/Card/SimpleCard';
import SimpleTable from '../../../../Components/UI/displayData/Table/SimpleTable';
import BtnExportCsvPermitReport from './BtnExportCsvPermitReport';
import { IFooterCellData, IRowData } from '../../../../Components/UI/displayData/Table/Table';

const header = [
    {
        key: 'loginUser',
        label: 'שם משתמש',
        name: 'loginUser',
    }, {
        key: 'count',
        label: 'מספר תווים',
        name: 'count',
    }, {
        key: 'payment',
        label: 'סכום תשלום',
        name: 'payment',
    }, {
        key: 'paymentMethod',
        label: 'סוג תשלום',
        name: 'paymentMethod',
    }, {
        key: 'createdBy',
        label: 'נוצר על ידי',
        name: 'createdBy',
    },
];

const DisplayPermitReport = ({ footerData, permits }) => {

    const rows: IRowData[] = permits.map((permitReportRow, index) => {

        return {
            payment: permitReportRow.payment,
            key: `rowData-${index}`,
            loginUser: permitReportRow.loginUser,
            count: permitReportRow.count,
            paymentMethod: permitReportRow.paymentMethod,
            createdBy: permitReportRow.createdBy,
        };
    });

    const footer: IFooterCellData[] = [
        {
            key: 'footer1',
            label: footerData[1],
            colspan: 2
        }, {
            key: 'footer2',
            label: footerData[2],
            colspan: 1
        }, {
            key: 'footer3',
            label: footerData[3],
            colspan: 1
        }, {
            key: 'footer4',
            label: `${footerData[4]} <br /> ${footerData[5]}`,
            colspan: 1
        }, {
            key: 'footer5',
            label: `${footerData[6]} <br /> ${footerData[7]}`,
            colspan: 1
        },
    ];

    return (
        <>
            <div className='row'>
                <div className='col'>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '1rem' }}>
                        <BtnExportCsvPermitReport rows={permits} footer={footer} />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col'>
                    <SimpleCard>
                        <SimpleTable
                            headers={header}
                            rows={rows}
                            footers={footer} />
                    </SimpleCard>
                </div>
            </div>
        </>
    );


}

export default DisplayPermitReport;