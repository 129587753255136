import ResponseStatus from "../../types/responseStatus.types";
import useAxiosPrivate from "../useAxiosPrivate";

const REQUEST_FILES_API = '/files/request-files';
const FILES_BY_PERSON_ID_API = '/files';

export type getFilesByPersonIdResultType = {
    isSuccess: boolean;
    message: string;
    list: any[];
}

const useFiles = () => {

    const axiosPrivate = useAxiosPrivate();

    async function getFilesByPersonIdApi(personId: string): Promise<getFilesByPersonIdResultType> {

        const result: getFilesByPersonIdResultType = {
            isSuccess: true,
            message: '',
            list: []
        }

        try {

            const response = await axiosPrivate.get(`${FILES_BY_PERSON_ID_API}/${personId}`);

            if(!response.data || !response.data.status) {

                throw new Error('בעיה כללית בשרת');
            }

            const data = response.data;
            const list = data.data.files;

            if(data.status !== ResponseStatus.SUCCESS) {
                
                result.isSuccess = false;
                result.message = data.message;
                return result;
            }

            result.isSuccess = true;
            result.message = data.message;
            const sortedList = list
                .filter(file => file.size >= 50)
                .sort((file1, file2) => file2.lastModified - file1.lastModified);

            result.list = sortedList;
        } catch (error) {

            console.log('error ' + error);
            result.message = 'בעיה כללית בשרת';
            result.isSuccess = false;
        }

        return result;
    }

    async function getRequestFilesApi(personId: string, citizenIdPath: string, carIdPath: string, pathScandLeasing: string, extraFilesPaths: string) {

        const data = {
            citizenIdPath: '',
            carIdPath: '',
            pathScandLeasing: '',
            extraFiles: '',
            personId: personId
        };

        if (citizenIdPath != undefined) {

            data.citizenIdPath = citizenIdPath.substring(citizenIdPath.lastIndexOf('/'));
        }

        if (carIdPath != undefined) {
            data.carIdPath = carIdPath.substring(carIdPath.lastIndexOf('/'));
        }

        if (pathScandLeasing != undefined) {

            data.pathScandLeasing = pathScandLeasing.substring(pathScandLeasing.lastIndexOf('/'));
        }

        if (extraFilesPaths != undefined) {

            data.extraFiles = extraFilesPaths;
        }

        try {

            const response = await axiosPrivate.post(REQUEST_FILES_API, data);
            return response.data?.data?.files;
        } catch (error) {
            console.log('[getPermitRequest] error: ', error);
        }

    }

    return { getRequestFilesApi, getFilesByPersonIdApi };
}

export default useFiles;