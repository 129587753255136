import React from 'react';
import Style from '../viewFilesPage.module.css';
import { FileTypes } from '../../../types/fileTypes.types';
import PdfContainerViewer from './PdfContainerViewer';
import ImageContainerViewer from './ImageContainerViewer';
import { IoIosExpand } from "react-icons/io";

const ItemList = ({file, index, handleSetFile}) => {

    const isPdf = (file && file.type === FileTypes.PDF_TYPE) ? true : false;
    const path = file.url;

  return (
    <div className={Style.itemList} onClick={() => handleSetFile(index)}>
        <div className={Style.itemListTitle}>
            <span className={Style.dateStr}>
              {file.lastModifiedString}
            </span>
            <IoIosExpand className={Style.expandIcon} />
        </div>
        <div className={Style.itemListFileContainer}>
            {isPdf ? <PdfContainerViewer path={path} /> : <ImageContainerViewer path={path} name={`file index ${index}`}/>}
        </div>
    </div>
  )
}

export default ItemList;