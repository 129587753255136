import React, { FC } from 'react'
import RedStar from './RedStar';

type TypeDateInput = {
    label: string, 
    value: string, 
    name: string, 
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void, 
    handleBlur?: (event: React.FocusEvent<any>) => void, 
    errorMsg?: string, 
    required?: boolean, 
    disabled?: boolean 
}

const DateInput: FC<TypeDateInput> = ({ 
    label, value, name, handleChange, handleBlur = null, errorMsg = '', required = false, disabled = false 
}) => {
    let classes = 'form-control';
    const hasError = errorMsg ? true : false;

    if (hasError) { 
        classes += ' is-invalid'; 
    }

    return (
        <>
            <label className="form-label">
                {label}
                {required ? <RedStar /> : ''}
            </label>
            <input 
                type='date'
                className={classes}
                name={name}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled} />
            {hasError ? <div className="invalid-feedback"> {errorMsg} </div> : ''}
        </>
    )
}

export default DateInput;