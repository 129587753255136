
export enum RequestCreatedBy {
    CITIZEN = 1, 
    SECRETARY = 2,
}

export enum RequestStatusType {
    PENDING = 1,
    APPROVED = 2,
    REJECTED = 3,
    ALL = -1
}