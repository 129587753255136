import React, { FC } from 'react'
import style from './paging.module.css';
import PagingBubtons from './PagingBubtons';
import SelectLimitOfRow from '../../../Forms/selectLimitOfRows/SelectLimitOfRow';
import NumOrange from './NumOrange';
import { useAppInfoContext } from '../../../../contexts/AppInfoContext';
import { AppUIBrackPoint } from '../../../../interface/appInfo.interface';

type PagingBoxType = {
    offset: number,
    count: number,
    limit: number,
    name?: string,
    isLoading?: boolean,
    changePage?: (newOffset: number) => void,
    handleChengeLimit?: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

const PagingBox: FC<PagingBoxType> = ({
    offset = 0,
    count = 0,
    limit = 0,
    name = 'שורות',
    isLoading = false,
    changePage = () => { },
    handleChengeLimit = (event) => { },
}) => {

    const { screenWidth } = useAppInfoContext();

    const mobileLayout = (screenWidth === AppUIBrackPoint.XS || screenWidth === AppUIBrackPoint.SM);
    let label = "מס' שורות בדף";
    let totalCountLabel = `מספר ${name}`;
    const offsetInt = offset;
    const countInt = count;
    const limitInt = limit;
    const numOfPages = Math.ceil(countInt / limitInt);
    const fItem = offsetInt * limitInt + 1;
    let lItem = fItem + limitInt;

    if (countInt < lItem) lItem = countInt;

    if (screenWidth === AppUIBrackPoint.XS || screenWidth === AppUIBrackPoint.SM) {
        label = '';
        totalCountLabel = name;
    }

    return (
        <div className={style.pagingContainer}>
            <div className={style.totalCount}>
                {totalCountLabel}: <NumOrange num={countInt} />
            </div>
            <PagingBubtons
                changePage={changePage}
                isLoading={isLoading}
                numOfPages={numOfPages}
                offsetInt={offsetInt}
            />
            <div>
                <SelectLimitOfRow
                    label={label}
                    value={limit}
                    name={'limit'}
                    handleChange={handleChengeLimit} />
                {!mobileLayout ? (
                    <span>עמוד מס {offsetInt + 1} מתוך {numOfPages} |  תוצאות {fItem} - {lItem}</span>
                ) : ''}
            </div>
        </div>
    );
}

export default PagingBox;