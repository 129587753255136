import React, { FC, ReactNode } from 'react';
import { useProjectDetailsContext } from '../../../contexts/ProjectDetailsContext';
import SelectWithSearch from './SelectWithSearch';
import { decodeNCRStringIfNeeded } from '../../../Helpers/language';
import { ISelectOption } from '../../../interface/form.interface';


interface SelectCarMakerProps {
    name: string;
    value: number;
    handleChange: (name: string | number, value: string | number) => void,
    handleBlur: (e: React.FocusEvent<HTMLInputElement, Element>) => void,
    errorMsg?: string | ReactNode | undefined;
    required?: boolean;
    withEmptyField?: boolean;
}

const SelectCarMaker: FC<SelectCarMakerProps> = ({
    name,
    value,
    handleBlur,
    handleChange,
    errorMsg = '',
    required = false,
    withEmptyField = false,
}) => {

    const { projectDetails } = useProjectDetailsContext();

    let makers = getMakersOptions();
    const valueStr = makers.find(option => option.value == value);

    return (
        <SelectWithSearch
            errorMsg={errorMsg}
            name={name}
            label={'דגם רכב'}
            value={valueStr}
            required={required}
            selects={makers}
            handleBlur={handleBlur}
            handleChange={handleChange} />
    );

    function getMakersOptions() {

        let makers: ISelectOption[] = [{ value: -1, label: 'אין אופציות' }];

        makers = projectDetails.types.map(maker => {

            return { label: decodeNCRStringIfNeeded(maker.name), value: maker.id };
        });

        if (required) {

            makers = [{ value: -1, label: 'צריך לבחור' }, ...makers];
            return makers;
        }

        if (withEmptyField) {

            makers = [{ value: -1, label: '' }, ...makers];
            return makers;
        }

        return makers;
    }
}

export default SelectCarMaker;