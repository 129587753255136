import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LuLogOut } from "react-icons/lu";
import { useAuthContextContext } from '../../../contexts/AuthContext';
import Button from '../../Buttons/Button';
import UserBox from './UserBox';
import { ButtonColors } from '../../../types/button.types';
import style from './Navbar.module.css';

const UserDetailsBox = () => {

    const navigate = useNavigate();
    const { logout: logoutAuth } = useAuthContextContext( );

    return (
        <div className={style.userDetailsBox}>
            <div className={style.avatarBox}>
                <UserBox />
            </div>
            <div className={`vr ${style.line}`}></div>
            <Button title='התנתק' text='התנתק' theme={ButtonColors.DANGER} endIcon={<LuLogOut />} onClick={logingOut}/>
        </div>
    );

    function logingOut() {

        logoutAuth();
        navigate('/');
    }
}

export default UserDetailsBox;