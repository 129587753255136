export const exportToCsvColumnsPermitList = [
    {
        id: 'permitId',
        displayName: 'מספר תו'
    },
    {
        id: 'personId',
        displayName: 'מספר תעודת זהות'
    },
    {
        id: 'fName',
        displayName: 'שם פרטי'
    },
    {
        id: 'lName',
        displayName: 'שם משפחה'
    },
    {
        id: 'street',
        displayName: 'רחוב'
    },
    {
        id: 'houseNum',
        displayName: 'מספר בית'
    },
    {
        id: 'appatmentNum',
        displayName: 'מספר דירה'
    },
    {
        id: 'city',
        displayName: 'שם ישוב'
    },
    {
        id: 'zip',
        displayName: 'מיקוד'
    },
    {
        id: 'vehicleNum',
        displayName: 'מספר רכב'
    },
    {
        id: 'typeCode',
        displayName: 'קוד סוג'
    },
    {
        id: 'descType',
        displayName: 'תיאור סוג'
    },
    {
        id: 'colorCode',
        displayName: 'קוד צבע'
    },
    {
        id: 'color',
        displayName: 'צבע'
    },
    {
        id: 'makerCode',
        displayName: 'קוד יצרן'
    },
    {
        id: 'maker',
        displayName: 'יצרן'
    },
    {
        id: 'permitBy',
        displayName: 'תו לפי'
    },
    {
        id: 'zone',
        displayName: 'איזור'
    },
    {
        id: 'streetCode',
        displayName: 'קוד רחוב'
    },
    {
        id: 'startDate',
        displayName: 'תאריך התחלה'
    },
    {
        id: 'endDate',
        displayName: 'תאריך סוף'
    },
    {
        id: 'codeTypePermit',
        displayName: 'קוד סוג תו'
    },
    {
        id: 'codeResAppl',
        displayName: 'קוד סיבת חידוש'
    },
    {
        id: 'mobile',
        displayName: 'טלפון'
    },
    {
        id: 'commet',
        displayName: 'הערות'
    },
    {
        id: 'vehicleGroupName',
        displayName: 'סוג רכב'
    },
];