import React from 'react';
import { usePermitReport } from '../../../Hooks/permits/usePermitReport';
import DisplayPermitReport from './components/DisplayPermitReport';
import PageTitle from '../../../Components/pageTitle/PageTitle';
import DangerAlert from '../../../Components/Alerts/DangerAlert';
import PermitReportFilter from './components/PermitReportFilter';

const containerStyle: React.CSSProperties = { display: 'flex', flexDirection: 'column', rowGap: '1rem' };

const PermitReportPage = () => {

    const { getPermitReportRows, dataState, updateFormInput, componentState } = usePermitReport();

    return (
        <div style={containerStyle}>
            <DangerAlert isShow={componentState.hasAlert} message={componentState.message} />
            <PageTitle title={'דוח תווים'} />
            <PermitReportFilter updateFormInput={updateFormInput} dataState={dataState} isLoading={componentState.isLoading} getPermitReportRows={getPermitReportRows}/>
            <DisplayPermitReport footerData={dataState.footer} permits={dataState.permitReportRows}/>
        </div>
    );
}

export default PermitReportPage;