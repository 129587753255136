import React, { useReducer } from "react";
import { COMPONENT_STATE_ACTION, componentReducer } from "../reducers/componentReducer";
import { AlertColors } from "../types/alertColors.types";
import { IComponentState } from "../interface/IComponentState.interface";


const initData: IComponentState = {
    hasAlert: false,
    alertColor: AlertColors.SECONDARY,
    message: '',
    isLoading: false
}


export const useComponentState = () => {

    const [componentState, stateDispatch] = useReducer(componentReducer, initData);

    function setIsLoading() {
        stateDispatch({ type: COMPONENT_STATE_ACTION.LOADING });
    }

    function setIsNotLoading() {
        stateDispatch({ type: COMPONENT_STATE_ACTION.NOT_LOADING });
    }

    function setAlert(message: string | React.ReactNode, hasAlert: boolean, alertColor: AlertColors) {

        stateDispatch({
            type: COMPONENT_STATE_ACTION.SET_ALERT,
            payload: {
                hasAlert: hasAlert,
                message: message,
                alertColor: alertColor,
            }
        });
    }

    function setDangerAlert(message: string | React.ReactNode) {
        
        stateDispatch({
            type: COMPONENT_STATE_ACTION.SET_ALERT,
            payload: {
                hasAlert: true,
                message: message,
                alertColor: AlertColors.DANGER,
            }
        });
    }

    function setSuccessAlert(message: string | React.ReactNode) {
        
        stateDispatch({
            type: COMPONENT_STATE_ACTION.SET_ALERT,
            payload: {
                hasAlert: true,
                message: message,
                alertColor: AlertColors.SUCCESS,
            }
        });
    }

    function removeAlert() {
        stateDispatch({ type: COMPONENT_STATE_ACTION.REMOVE_ALERT });
    }

    return {
        componentState,
        setIsLoading,
        setIsNotLoading,
        setAlert,
        setDangerAlert,
        setSuccessAlert,
        removeAlert
    };
}