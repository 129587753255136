import React from 'react';
import { useRequestManagmentPageContext } from '../../../../contexts/request/RequestDetailsPageContext';
import RadioBtnsContainer from '../../../../Components/Forms/radio/RadioBtnsContainer';
import RadioOutline from '../../../../Components/Forms/radio/RadioOutline';
import InputGroup from '../../../../Components/Forms/InputGroup';
import FormSection from '../../../../Components/UI/formSection/FormSection';

const PaymentDetails = () => {

    const { Formik } = useRequestManagmentPageContext();

    const { values } = Formik;
 
    return (
        <FormSection title={"פרטי תשלום"}>
            <div className="row">
                <div className="col">
                    <RadioBtnsContainer label='אופן תשלום'>
                        <RadioOutline
                            label='לא שולם'
                            id={'notPaid'}
                            name={'notPaid'}
                            disabled={true}
                            checked={!values.hasPaidCash && !values.hasPaidCredit}
                            value='1'
                        />
                        <RadioOutline
                            label='שולם באשראי'
                            id={'hasPaidCredit'}
                            name={'hasPaidCredit'}
                            disabled={true}
                            checked={values.hasPaidCredit}
                            value='1'
                        />
                        <RadioOutline
                            label='שולם במזומן'
                            id={'hasPaidCash'}
                            name={'hasPaidCash'}
                            disabled={true}
                            checked={values.hasPaidCash}
                            value='1'
                        />
                    </RadioBtnsContainer>
                </div>
            </div>
            <div className="row">
                <div className="col col-4">
                    <InputGroup
                        disabled={true}
                        name={'carNumber'}
                        label='מספר אסמכתא'
                        value={values.asmachta} />
                </div>
                <div className="col col-4">
                    <InputGroup
                        disabled={true}
                        name={'carNumber'}
                        label='סכום'
                        value={values.moneyAmount} />

                </div>
            </div>
        </FormSection>
    );
}

export default PaymentDetails;