import React, { FC } from 'react';
import style from './selectLimitOfRow.module.css';

type SelectLimitOfRowType = {
    name: string,
    value: number,
    label?: string,
    handleChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void,
    handleBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void,
}

const SelectLimitOfRow: FC<SelectLimitOfRowType> = ({
    name, value, 
    label = "מס' שורות בדף:", 
    handleChange = (event) => {}, 
    handleBlur = (event) => {}
}) => {

    const id = name + "Id";

    return (
        <div className={style.formFloating}>
            <label className={style.label}>{label}</label>
            <select
            name={name}
            value={value}
            onChange={event => handleChange(event)}
            onBlur={event => handleBlur(event)} 
            className={style.select} 
            id={id}>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={30}>30</option>
                <option value={50}>50</option>
            </select>
        </div>
    );
}

export default SelectLimitOfRow;