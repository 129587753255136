import React from 'react';
import Style from './button.module.css';
import { ButtonColors, ButtonSizes, ButtonTypes } from '../../types/button.types';

type ButtonProps = {
    text: string; 
    onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>) => void; 
    theme?: ButtonColors; 
    disabled?: boolean; 
    style?: React.CSSProperties; 
    size?: ButtonSizes; 
    startIcon?: React.ReactNode; 
    endIcon?: React.ReactNode; 
    nowrap?: boolean; 
    type?: "button" | "submit" | "reset"; 
    title?: string;
    outline?: ButtonTypes; 
    isLoading?: boolean;
    show?: boolean;
}

const Button: React.FC<ButtonProps> = ({ 
    onClick, 
    text,
    theme = ButtonColors.PRIMARY, 
    disabled = false, 
    style = {}, 
    size = ButtonSizes.MEDIUM, 
    startIcon = null, 
    endIcon = null, 
    nowrap = false, 
    type = "button", 
    title = "", 
    outline = ButtonTypes.FILLED, 
    isLoading = false,
    show = true, 
}) => {

    let spanStart: string | React.ReactNode = '';
    let spanEnd: string | React.ReactNode = '';
    let classes = Style.myBtn + ' ' + getBtnClass(theme, outline, size);

    let classesIcon = Style.icon;

    if (nowrap) classes += ' ' + Style.btnNowrap;

    if (endIcon) {

        classesIcon += ' ' + Style.startIcon;

        spanEnd = (
            <span className={classesIcon}>{endIcon}</span>
        );
    }

    if (startIcon) {

        classesIcon += ' ' + Style.endIcon;

        spanStart = (
            <span className={classesIcon}>{startIcon}</span>
        );
    }

    if (isLoading) {
        spanEnd = '';
        spanStart = '';
    }

    if (!show) return null;

    return (
        <button
            style={style}
            disabled={disabled}
            type={type}
            title={title}
            onClick={e => onClick(e)}
            className={classes}>
            {spanStart}
            {isLoading ? (
                <>
                    <span role="status">נשלח... </span>
                    <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                </>
            ) : <span>{text}</span>}
            {spanEnd}
        </button>
    );

    function getBtnClass(buttonColor: ButtonColors, buttonType: ButtonTypes, size: ButtonSizes) {

        let btnClasses = 'btn btn-';
        let classeoutline = '';

        if (buttonType === ButtonTypes.OUTLINE) classeoutline = 'outline-';

        switch (buttonColor) {
            case ButtonColors.PRIMARY:
                btnClasses += classeoutline + 'primary';
                break;
            case ButtonColors.SECONDARY:
                btnClasses += classeoutline + 'secondary';
                break;
            case ButtonColors.SUCCESS:
                btnClasses += classeoutline + 'success';
                break;
            case ButtonColors.DANGER:
                btnClasses += classeoutline + 'danger';
                break;
            case ButtonColors.WARNING:
                btnClasses += classeoutline + 'warning';
                break;
            case ButtonColors.INFO:
                btnClasses += classeoutline + 'info';
                break;
            case ButtonColors.LIGHT:
                btnClasses += classeoutline + 'light';
                break;
            case ButtonColors.DARK:
                btnClasses += classeoutline + 'dark';
                break;
            case ButtonColors.LINK:
                btnClasses += classeoutline + 'link';
                break;
            default:
                btnClasses += classeoutline + 'primary';
                break;

        }

        switch (size) {
            case ButtonSizes.LARGE:
                btnClasses += ' btn-lg';
                break;
            case ButtonSizes.SMALL:
                btnClasses += ' btn-sm';
                break;
            default:
                break;
        }

        return btnClasses;
    }
}

export default Button;