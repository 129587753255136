import { IPermitRequestItemList, IPermitRequestListPage } from "../interface/request/permitRequestListPage.interface";

export enum PermitRequestPageKind {
    UPDATE_LIST = 'UPDATE_LIST',
    SWITCH_STATUS_REQUEST = 'SWITCH_STATUS_REQUEST'
}

// An interface for our actions
type PermitRequestPageAction = {
    type: PermitRequestPageKind.UPDATE_LIST;
    payload: {
        requests: IPermitRequestItemList[],
        count: number
    };
} | {
    type: PermitRequestPageKind.SWITCH_STATUS_REQUEST;
    payload: {
        rowId: number,
        status: number
    };
}


export function permitRequestListPageReducer(state: IPermitRequestListPage, action: PermitRequestPageAction) {

    switch (action.type) {
        case PermitRequestPageKind.UPDATE_LIST:

            return {
                ...state,
                requests: action.payload.requests,
                count: action.payload.count
            };

        case PermitRequestPageKind.SWITCH_STATUS_REQUEST:

            const requests = state.requests;
            const index = requests.findIndex(row => row.request.id === action.payload.rowId);

            if (index < 0) return state;

            let req = { ...requests[index].request, status: action.payload.status };
            requests[index] = { ...requests[index], request: req }
            return { ...state, requests: requests };
        default:
            return state;
    }
}