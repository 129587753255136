import React, { ReactNode } from 'react';
import Style from './formSection.module.css';


interface FormSectionProps {
    children: ReactNode; 
    title?: string | ReactNode;
}

const FormSection: React.FC<FormSectionProps> = ({children, title}) => {
    return (
        <div className={Style.formSection}>
            {title ? <div className={Style.headerTitle}>{title}</div> : ''}
            {children}
        </div>
    );
}

export default FormSection;