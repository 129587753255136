import React from 'react';
import { useRequestManagmentPageContext } from '../../../../contexts/request/RequestDetailsPageContext';
import InputGroup from '../../../../Components/Forms/InputGroup';
import FormSection from '../../../../Components/UI/formSection/FormSection';

const PermitRequestDetails = () => {

    const { Formik } = useRequestManagmentPageContext();

    const { values } = Formik;
    const readOnly = true;

    return (
        <FormSection title={'פרטי תו'}>
            <div className="row">
                <div className="col-md-6 col-lg-2">
                    <InputGroup

                        disabled={readOnly}
                        label={'מספר בקשה'}
                        name={'requestId'}
                        value={values.id} />
                </div>
                <div className="col-md-6 col-lg-2">
                    <InputGroup

                        disabled={readOnly}
                        label={'מספר תו'}
                        name={'tavDayar'}
                        value={values.tavDayar} />
                </div>

                <div className="col-md-6 col-lg-3">
                    <InputGroup

                        disabled={readOnly}
                        label={'משתמש שאישר'}
                        name={'approveUserId'}
                        value={values.approveUserId} />
                </div>
                <div className="col-md-6 col-lg-3">
                    <InputGroup

                        disabled={readOnly}
                        label={'משתמש שיצר בקשה'}
                        name={'insertUserId'}
                        value={values.insertUserId} />
                </div>
                <div className="col-md-6 col-lg-2">
                    <InputGroup

                        disabled={readOnly}
                        label={'נוצר על ידי'}
                        name={'createdBy'}
                        value={values.createdBy} />

                </div>

            </div>
            <div className="row">
                <div className="col-md-6 col-lg-3">
                    <InputGroup

                        disabled={readOnly}
                        label={'תאריך יצירה'}
                        name={'userRegisterDate'}
                        value={values.userRegisterDate} />
                </div>
                <div className="col-md-6 col-lg-3">
                    <InputGroup

                        disabled={readOnly}
                        label={'תאריך עדכון'}
                        name={'updateDate'}
                        value={values.updateDate} />
                </div>
                <div className="col-md-6 col-lg-3">
                    <InputGroup

                        disabled={readOnly}
                        label={'תאריך אישור'}
                        name={'approvalDate'}
                        value={values.approvalDate} />
                </div>
            </div>
        </FormSection>
    );
}

export default PermitRequestDetails;
