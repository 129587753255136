import React from 'react'
import InputGroup from '../../Components/Forms/InputGroup';
import { useFormik } from 'formik';
import Button from '../../Components/Buttons/Button';
import { uploadFileSchema } from './uploadFileValidation';
import { BiSend } from 'react-icons/bi'
import GeneralModal from '../../Components/GeneralModal/GeneralModal';
import DangerAlert from '../../Components/Alerts/DangerAlert';
import { ButtonColors } from '../../types/button.types';

const UploadFileModal = ({ closingFun, handleUploadFile, error, isLoading, isShow }) => {

    const { errors, touched, handleSubmit, handleBlur, setFieldValue } = useFormik({
        initialValues: { 'file': '' },
        validateOnMount: true,
        validateOnChange: false,
        validationSchema: uploadFileSchema,
        onSubmit: (values, actions) => {
            handleUploadFile(values.file);
        }
    });

    if (!isShow) {
        return null;
    }

    return (

        <GeneralModal
            isShow={isShow}
            title='הוספת קובץ'
            closingFun={closingFun}
            content={(<>
                <DangerAlert message={error} isShow={error} />
                <form className='container' onSubmit={handleSubmit}>
                    <div className='row pt-4 pb-4'>
                        <div className='col-12 col-xl-8'>
                            <InputGroup
                                type='file'
                                label='קובץ'
                                name='file'
                                handleBlur={handleBlur}
                                handleChange={event => setFieldValue('file', event.currentTarget.files[0])}
                                required={true}
                                errorMsg={(errors.file && touched.file) ? errors.file : undefined}
                            />
                        </div>
                    </div>
                </form>
            </>)}
            footer={(
                <div className='pt-2'>
                    <Button 
                    text='שלח' 
                    onClick={handleSubmit} 
                    type='submit' 
                    disabled={isLoading} 
                    theme={ButtonColors.SUCCESS}
                    endIcon={<BiSend />} 
                    />
                </div>
            )}
        />
    );
}

export default UploadFileModal;