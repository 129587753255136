import React from 'react';
import { useRequestManagmentPageContext } from '../../../../contexts/request/RequestDetailsPageContext';
import { TbDeviceAnalytics } from "react-icons/tb";
import ActionBoxCard from '../../../../Components/UI/Card/ActionBoxCard';
import { ButtonColors, RequestStatusType } from '../../../../types/types.import';
import { Button, SendMessageBtn, HistoryPermitList, GoBackBtn, ViewFilesBtn,
    CreatePermitBtn, UpdateRquestStatusBtn, SaveBtn } from '../../../../Components/Buttons/buttuns.import';
import ActionInnerBoxCard from '../../../../Components/UI/Card/ActionInnerBoxCard';

const ButtonsContainer = () => {

    const { handleUpdateRequest, handlePreApprove, handleUpdateStatus,
        refHistoryModal, componentState, Formik, openFilesModal, refSendEmailModal } = useRequestManagmentPageContext();

    const { values } = Formik;

    const requesStatus = values.status;
    let hasApproveBtn = false;
    let hasUpdateStatusBtn = false;

    let isDisabled = false;
    const openSendEmailFunc = refSendEmailModal.current?.openSendMessageModal ? refSendEmailModal.current.openSendMessageModal : null;
    const openHistoryModalFunc = refHistoryModal.current?.openHistoryModal ? refHistoryModal.current.openHistoryModal : null;


    if (componentState.isLoading) isDisabled = true;

    if (requesStatus === RequestStatusType.PENDING) {

        hasApproveBtn = true;
        hasUpdateStatusBtn = true;
    }

    if (requesStatus === RequestStatusType.APPROVED) {
        //comparisonBtn = false;
    }

    if (requesStatus === RequestStatusType.REJECTED) {
        hasUpdateStatusBtn = true;
    }

    return (
        <ActionBoxCard>
            <ActionInnerBoxCard>

                <ViewFilesBtn disabled={false} personId={values.personId} />
                <HistoryPermitList onClick={() => openHistoryModalFunc(true, values.reportNum)} />
                <SaveBtn onClick={handleUpdateRequest} label={'שמירה'} />
                <Button endIcon={<TbDeviceAnalytics />} text='השוואת קבצים וטופס' onClick={openFilesModal} />
                <SendMessageBtn disabled={!openSendEmailFunc}
                    onClick={() => openSendEmailFunc(true, values.id, values.email, values.mobile)} />

            </ActionInnerBoxCard>
            <ActionInnerBoxCard>

                <CreatePermitBtn
                    show={hasApproveBtn}
                    disabled={isDisabled}
                    onClick={() => handlePreApprove()}
                    color={ButtonColors.SUCCESS}
                    label={'אישור'} />

                <UpdateRquestStatusBtn
                    show={hasUpdateStatusBtn}
                    currentStatus={values.status}
                    onClick={newStatus => handleUpdateStatus(newStatus)}
                />

            </ActionInnerBoxCard>

            <ActionInnerBoxCard justifyContent='flex-end' flexGrow={1}>
                <GoBackBtn />
            </ActionInnerBoxCard>
        </ActionBoxCard>
    );
}

export default ButtonsContainer;