import React, { FC } from 'react';
import moment from 'moment';
import { useRequestManagmentPageContext } from '../../../../contexts/request/RequestManagmentPageContext';
import { useProjectDetailsContext } from '../../../../contexts/ProjectDetailsContext';
import { RequestStatusType, ComparingFileStatus, PaymentMethod, ButtonColors } from '../../../../types/types.import';
import {
    UpdateRquestStatusBtn, HistoryPermitList,
    ViewPermitRequestBtn, CreatePermitBtn
} from '../../../../Components/Buttons/buttuns.import';
import { RowCardItem, PaymentRowItem, RowCard } from '../../../../Components/UI/displayData/listOfRows/listOfRows.import';
import SpanRequestStatus from './SpanRequestStatus';
import { decodeNCRStringIfNeeded } from '../../../../Helpers/language';
import { IPermitRequestItemList } from '../../../../interface/request/permitRequestListPage.interface';
import { convertCodeCreatedByToText, convertCodeStatusRequestToText, permitTypeCodeToText } from '../../../../Helpers/conversion/convertTypeCodeToText';
import { useAppInfoContext } from '../../../../contexts/AppInfoContext';
import { AppUIBrackPoint } from '../../../../interface/appInfo.interface';


const DATE_FORMAT_STR = "DD/MM/YYYY, HH:mm:ss";

type Props = {
    index: number, 
    request: IPermitRequestItemList,
}

const RequestCard: FC<Props> = ({ index, request }) => {

    const { screenWidth } = useAppInfoContext();
    const { refHistoryModal, handlerUpdateStatus, openModalPreApproveReq } = useRequestManagmentPageContext();
    const { projectDetails } = useProjectDetailsContext();

    const data = convRequestRowRawDataFromServer(request);

    const showVehicleType = projectDetails.guiConfig.permitListPageGuiConfig.showVehicleType;
    const showPermitType = projectDetails.guiConfig.permitListPageGuiConfig.showPermitType;
    const hasPayment = projectDetails.hasPayment;
    const statusReqCode = data.statusRequestCode;

    const moblieLayout = (screenWidth === AppUIBrackPoint.XS || screenWidth === AppUIBrackPoint.SM);

    const hasApproveRequest = (
        data.comparingFtatus === ComparingFileStatus.COPARING_FILES_PASSED
        && statusReqCode === RequestStatusType.PENDING
        && !moblieLayout);

    const hasHistoryBtn = !moblieLayout;
    const hasUpdateBtn = (statusReqCode === RequestStatusType.PENDING || statusReqCode === RequestStatusType.REJECTED);

    const openHistoryModalFunc = refHistoryModal.current?.openHistoryModal ? refHistoryModal.current.openHistoryModal : null;

    return (
        <RowCard
            index={index}
            content={(
                <>
                    <RowCardItem label="מס' בקשה" value={data.id} />
                    <RowCardItem label='תאריך עידכון' value={data.updateDate} />
                    <RowCardItem label='מספר רכב' value={data.carNum} />
                    <RowCardItem label='תעודת זהות' value={data.personId} />
                    <RowCardItem label='שם פרטי' value={data.fName} />
                    <RowCardItem label='שם משפחה' value={data.lName} />
                    {showVehicleType ? <RowCardItem label='סוג רכב' value={data.vehicleGroupName} /> : ''}
                    {showPermitType ? <RowCardItem label='סוג תו' value={data.requestPermitType} /> : ''}
                    <PaymentRowItem isShow={hasPayment} label='תשלום' moneyAmount={data.moneyAmount} paymentMethodCode={data.paymentMethod} />
                    <RowCardItem
                        label='סטטוס בקשה'
                        value={
                            <SpanRequestStatus reqStatusCode={statusReqCode} statusName={data.status} comparingFStatus={data.comparingFtatus} />
                        }
                    />
                </>
            )}
            footer={(
                <>

                    <CreatePermitBtn
                        color={ButtonColors.SUCCESS}
                        show={hasApproveRequest}
                        onClick={() => openModalPreApproveReq(request)}
                        label={'אישור'} />

                    <UpdateRquestStatusBtn
                        show={hasUpdateBtn}
                        currentStatus={statusReqCode}
                        onClick={newStatus => handlerUpdateStatus(data.id, newStatus)}
                    />

                    <HistoryPermitList onClick={() => openHistoryModalFunc(true, data.reportNum)} show={hasHistoryBtn} />
                    <ViewPermitRequestBtn rowDbId={data.id} />
                </>
            )}
        />);


    function convRequestRowRawDataFromServer(requestData: IPermitRequestItemList) {

        const vehicleGroupList = projectDetails.vehicleGroups;
        const request = requestData.request;
        const comparingFilesResult = requestData.comparingFilesResult;

        const creationDate = moment(new Date(request.updateDate)).format(DATE_FORMAT_STR);

        let sentToSmsDate = 'לא נשלח';
        let statusRequest = '';
        let createdBy = '';

        createdBy = convertCodeCreatedByToText(request.createdBy);
        statusRequest = convertCodeStatusRequestToText(request.status);
        let paymentMethod = PaymentMethod.NONE;

        if (request.hasPaidCash) {
            paymentMethod = PaymentMethod.CASH;
        } else if (request.hasPaidCredit) {
            paymentMethod = PaymentMethod.CARD;
        }

        const requestPermitType = permitTypeCodeToText(request.tavGilZahav);
        const vehicleGroup = vehicleGroupList.find(vehicleGroup => vehicleGroup.id === request.vehicleGroupName);
        const vehicleGroupName = vehicleGroup ? decodeNCRStringIfNeeded(vehicleGroup.vehicleGroupName) : 'אין נתונים';
        
        return {
            key: request.id ? request.id : -1,
            lName: request.familyName ? decodeNCRStringIfNeeded(request.familyName) : '',
            fName: request.privateName ? decodeNCRStringIfNeeded(request.privateName) : '',
            updateDate: creationDate,
            id: request.id ? request.id : -1,
            secreteryMessage: request.secreteryMessage ? decodeNCRStringIfNeeded(request.secreteryMessage) : '',
            personId: request.personId ? request.personId : '-1',
            createdBy: createdBy,
            createdByCode: request.createdBy,
            carNum: request.carNum ? request.carNum : '-1',
            status: statusRequest,
            statusRequestCode: request.status,
            permitNumber: request.tavDayar ? request.tavDayar : -1,
            reportNum: request.reportNum ? request.reportNum : '-1',
            mobile: request.mobile ? request.mobile : '',
            sentToSmsDate: sentToSmsDate,
            paymentMethod: paymentMethod,
            moneyAmount: request.moneyAmount ? request.moneyAmount : 0,
            comparingFtatus: comparingFilesResult,
            vehicleGroupName: vehicleGroupName,
            requestPermitType: requestPermitType
        };
    }
}

export default RequestCard;