import React from 'react';
import ExportToCsvButton from '../../../../Components/Buttons/ExportToCsvButton';

const BtnsRow = ({ list, header }) => {

    if (list.length <= 0) {
        return null;
    }

    return (
        <div className='row'>
            <div className='col'>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '1rem' }}>
                    <ExportToCsvButton data={list} headers={header} fileName='expired permit' />
                </div>
            </div>
        </div>
    );
}

export default BtnsRow;