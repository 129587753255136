import * as yup from 'yup';
import { onlyNumbersRegExp, phoneRegExp } from '../../Helpers/regExps';
import { MOBILE_FORMAT_MESSAGE, ONLY_DIGIT_PERMITED_MESSAGE, 
    REQUIRED_FIELD_MESSAGE, START_DATE_GREATER_THEN_END_DATE_MESSAGE } from '../../Errors/errorMessages';
import { isPersonIdValid, isFirstDateGreater } from '../../Helpers/vatidation/validation';

export const viewPermitSchema = yup.object().shape({
    carNumber: yup.string()
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE)
        .min(5, 'חייב להכיל לפחות 5 ספרות')
        .max(8, 'לכל היותר 8 ספרות')
        .required(REQUIRED_FIELD_MESSAGE),
    vColor: yup.number()
        .notOneOf([-1,], 'אנא בחר צבע')
        .required(REQUIRED_FIELD_MESSAGE),
    vType: yup.number()
        .notOneOf([-1], 'אנא בחר דגם רכב')
        .required(REQUIRED_FIELD_MESSAGE),
    privateName: yup.string()
        .required(REQUIRED_FIELD_MESSAGE),
    familyName: yup.string()
        .required(REQUIRED_FIELD_MESSAGE),
    startValidityDate: yup.string()
        .required(REQUIRED_FIELD_MESSAGE),
    endValidityDate: yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .test('end-date-greater-then-start', START_DATE_GREATER_THEN_END_DATE_MESSAGE, function(value) {

            const { startValidityDate } = this.parent;
            return !isFirstDateGreater(startValidityDate, value);
        }),
    personId: yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .test('endDate-bofore-startDate', 'מספר ת.ז אינו חוקי. צריך להכיל ספרת ביקורת', value => isPersonIdValid(value)),
    street: yup.number()
        .notOneOf([-1], 'אנא בחר רחוב')
        .required(REQUIRED_FIELD_MESSAGE),
    houseNum: yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE),
    appartmentNum: yup.string()
        .notRequired()
        .matches(onlyNumbersRegExp, ONLY_DIGIT_PERMITED_MESSAGE),
    email: yup.string()
        .email('כתובת אימייל לא חוקית')
        .required(REQUIRED_FIELD_MESSAGE),
    mobile: yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .matches(phoneRegExp, MOBILE_FORMAT_MESSAGE),
    phone: yup.string()
        .optional()
        .matches(phoneRegExp, MOBILE_FORMAT_MESSAGE),
});
