import { IRequestFromServer } from "../../../interface/request/requestFromServer.interface";

export function isIRequestFromServer(obj: any): obj is IRequestFromServer {
    return typeof obj === 'object' &&
        typeof obj.id === 'number' &&
        typeof obj.hasPaidCash === 'boolean' &&
        typeof obj.hasPaidCredit === 'boolean' &&
        typeof obj.carNum === 'string' &&
        typeof obj.vehiclePrivte === 'number' && // Assuming OwnershipCarType is a string
        typeof obj.personId === 'string' &&
        typeof obj.familyName === 'string' &&
        typeof obj.privateName === 'string' &&
        typeof obj.email === 'string' &&
        typeof obj.phone === 'string' &&
        typeof obj.mobile === 'string' &&
        typeof obj.street === 'number' &&
        typeof obj.house === 'number' &&
        typeof obj.appartment === 'number' &&
        typeof obj.mailCity === 'string' &&
        typeof obj.mailHouse === 'string' &&
        typeof obj.mailStreet === 'string' &&
        typeof obj.mailAppartment === 'string' &&
        typeof obj.mailMikod === 'string' &&
        typeof obj.receiveInMail === 'number' &&
        typeof obj.getTavByHand === 'string' &&
        typeof obj.getTavByMail === 'string' &&
        typeof obj.moneyAmount === 'number' &&
        typeof obj.asmachta === 'string' &&
        typeof obj.reportNum === 'string' &&
        typeof obj.sentToMailDate === 'number' &&
        typeof obj.sentToPrintDate === 'number' &&
        typeof obj.sentToSmsDate === 'number' &&
        typeof obj.userInsertName === 'string' &&
        typeof obj.createdBy === 'string' &&
        typeof obj.secreteryMessage === 'string' &&
        typeof obj.status === 'number' && // Assuming RequestStatusType is a string
        typeof obj.sendToSms === 'number' &&
        typeof obj.sendToEmail === 'number' &&
        typeof obj.updateDate === 'number' &&
        typeof obj.approvalDate === 'number' &&
        typeof obj.userRegisterDate === 'number' &&
        typeof obj.approveUserId === 'number' &&
        typeof obj.projectId === 'number' &&
        typeof obj.vehicleGroupName === 'number' &&
        typeof obj.insertUserId === 'number' &&
        typeof obj.tavDayar === 'number' &&
        typeof obj.tavGilZahav === 'number' &&
        typeof obj.agreeTakanon === 'number' &&
        typeof obj.agreeDataTransfer === 'number' &&
        typeof obj.agreeToVolunteer === 'number' &&
        typeof obj.payment === 'string' &&
        typeof obj.hardCopyPersonId === 'string' &&
        typeof obj.hardCopyLicense === 'string' &&
        typeof obj.hardCopyLeasing === 'string' &&
        typeof obj.personIdFile === 'object' &&
        typeof obj.carLicenseFile === 'object' &&
        typeof obj.workStatementFile === 'object' &&
        Array.isArray(obj.extraFiles) &&
        typeof obj.citizenIdPath === 'string' &&
        typeof obj.carIdPath === 'string' &&
        typeof obj.workStatmentPath === 'string' &&
        typeof obj.extraFilesPath === 'string' &&
        typeof obj.extra1 === 'string' &&
        typeof obj.extra2 === 'string' &&
        typeof obj.extra3 === 'string' &&
        typeof obj.extra4 === 'object' && // Assuming IExtra4Map is an object
        typeof obj.extra5 === 'string' &&
        typeof obj.lastSixDigetOfPersonId === 'string';
}