import React from 'react';
import { useRequestManagmentPageContext } from '../../../../../contexts/request/RequestDetailsPageContext';
import Alert from '../../../../../Components/Alerts/Alert';
import { AlertColors } from '../../../../../types/alertColors.types';

const AlertExistingPermit = () => {

    const { greatestEndDateOfActivePermit, setGreatestEndDateOfActivePermit, Formik } = useRequestManagmentPageContext();
    const { values } = Formik;
    const isApprovedRequest = values.approvalDate ? true : false;

    if (isApprovedRequest || !greatestEndDateOfActivePermit)
        return null;

    return (
        <div className='row mt-1'>
            <div className='col-12'>
                <Alert
                    subject={`קיים תו לרכב ומספר תעודת זהות אשר עדיין בתוקף ונגמר בתאריך ${greatestEndDateOfActivePermit}`}
                    isShow={true}
                    message={`קיים תו לרכב ומספר תעודת זהות אשר עדיין בתוקף ונגמר בתאריך ${greatestEndDateOfActivePermit}, לכן תאריך סיום התו הנוכחי מתארך באתאם.`}
                    type={AlertColors.WARNING}
                    onClose={() => setGreatestEndDateOfActivePermit('')}
                />
            </div>
        </div>
    );
}

export default AlertExistingPermit;