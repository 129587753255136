export enum ServerExceptionTypes {
    GENERAL = 1,
    BAD_REQUEST = 2,
    USER_NOT_FOUND = 3,
    USER_NO_IN_PARKING = 4,
    USER_DOES_NOT_HAVE_THE_NEEDED_CREDENTIAL = 5,
    VALID_PERMIT_ALREADY_EXIST = 6,
    PENDING_REQUEST_ALREADY_EXIST = 7,
    FILES_NOT_VALID = 8,
    SYSTEM_TABLE_NOT_FOUND = 9,
    REQUESTED_DATA_IS_NOT_FOUND = 10,
    TRANZILA_PAYMENT_PROCESS_FAIL = 11,
    ENABLE_PAYMENT_TRANSFER = 12,
    GENERAL_PAYMENT_ERROR = 13,
    OTP_CODE_VERIFICATION_FAILED = 14,
    INVALID_OR_NOT_EXIST_EMAIL_IN_SYSTEM = 15,
    INVALID_OR_NOT_EXIST_MOBILE_IN_SYSTEM = 16,
}