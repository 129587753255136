import React, { FC } from 'react';
import { BiHide, BiShow } from 'react-icons/bi';
import Button from './Button';
import { RequestStatusType, ButtonColors } from '../../types/types.import';

type TypeUpdateRquestStatusBtn = {
    onClick: (newStatus: RequestStatusType) => Promise<void>,
    currentStatus: RequestStatusType,
    show: boolean,
}

const UpdateRquestStatusBtn: FC<TypeUpdateRquestStatusBtn> = ({ onClick, currentStatus, show }) => {

    let label = 'דחיה';
    let icon = <BiHide />
    let color = ButtonColors.DANGER;
    let newStatus = -1;

    if (currentStatus === RequestStatusType.PENDING) {

        newStatus = RequestStatusType.REJECTED;
    }

    if (currentStatus === RequestStatusType.REJECTED) {

        label = 'בטל דחיה';
        icon = <BiShow />;
        color = ButtonColors.SECONDARY;
        newStatus = RequestStatusType.PENDING;
    }

    return (
        <Button
            theme={color}
            onClick={() => onClick(newStatus)}
            endIcon={icon}
            text={label}
        />
    );
}

export default UpdateRquestStatusBtn;