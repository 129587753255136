import React from 'react';
import { useAddPermitPageContext } from '../../../../contexts/permit/AddPermitPageContext';
import InputTextArea from '../../../../Components/Forms/InputTextArea';
import SelectPermitType from '../../../../Components/Forms/select/SelectPermitType';
import SelectZone from '../../../../Components/Forms/select/SelectZone';
import SelectPermitStatus from '../../../../Components/Forms/select/SelectPermitStatus';
import FormSection from '../../../../Components/UI/formSection/FormSection';

const PermitGeneralDetails = () => {
    const { Formik, handleChange} = useAddPermitPageContext();

    const { handleBlur, setFieldValue, values } = Formik;
    const generalTitle = 'פרטים כללים';

    return (
        <FormSection title={generalTitle}>
            <div className="row">
                <div className='col-lg-6 col-xl-6'>
                    <InputTextArea
                        label='הערה'
                        name='comments'
                        value={values.comments}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        rows={4}
                    />
                </div>

                <div className='col-lg-6 col-xl-6'>
                    <div className="row">
                        <div className='col'>
                            <SelectPermitType
                                name={'permitType'}
                                value={values.permitType}
                                handlePermitTypeChange={(name, value) => setFieldValue(name, value)}
                                handleBlur={handleBlur}
                            />
                        </div>
                    </div>
                    {values.permitId > 0 ? (
                        <div className="row">
                            <div className='col-lg-12 col-xl-6'>
                                <SelectPermitStatus
                                    handleBlur={handleBlur}
                                    name='statusTav'
                                    label='סטטוס'
                                    handleChange={handleChange}
                                    value={values.statusTav}
                                    required={true}
                                    errorMsg={undefined}
                                />
                            </div>
                            <div className='col-lg-12 col-xl-6'>
                                <SelectZone
                                    name={'zone'}
                                    value={values.zone}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    required={true}
                                    errorMsg={undefined}
                                />
                            </div>
                        </div>
                    ) : ''}
                </div>
            </div>
        </FormSection>
    );
}

export default PermitGeneralDetails