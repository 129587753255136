import React from 'react';
import DisplayHistoryInput from './DisplayHistoryInput';
import InputCard from './InputCard';

const DisplayRequestHistory = ({ historyRecord }) => {

    return (
        <div className='col'>
            <InputCard>
                <DisplayHistoryInput
                    inputName='requestStatus' value={historyRecord.requestStatus}
                    labalName='מצב הבקשה' colSize='col-3' />
                <DisplayHistoryInput
                    inputName='userRegisterDate' value={historyRecord.userRegisterDate}
                    labalName='יוצר הפעולה' colSize='col-3' />
                <DisplayHistoryInput
                    inputName='requestType' value={historyRecord.requestType}
                    labalName='סוג תו' colSize='col-3' />
                <DisplayHistoryInput
                    inputName='dateTime' value={historyRecord.userName}
                    labalName='שם משתמש' colSize='col-3' />
            </InputCard>

            <InputCard>
                <DisplayHistoryInput
                    inputName='fName' value={historyRecord.fName}
                    labalName='שם פרטי' colSize='col-4' />
                <DisplayHistoryInput
                    inputName='lName' value={historyRecord.lName}
                    labalName='שם משפחה' colSize='col-4' />
                <DisplayHistoryInput
                    inputName='personId' value={historyRecord.personId}
                    labalName='תעודת זהות' colSize='col-4' />
            </InputCard>
            <InputCard>
                <DisplayHistoryInput
                    inputName='email' value={historyRecord.email}
                    labalName='אימייל' colSize='col-4' />
                <DisplayHistoryInput
                    inputName='mobile' value={historyRecord.mobile}
                    labalName='סלולרי' colSize='col-4' />
                <DisplayHistoryInput
                    inputName='phone' value={historyRecord.phone}
                    labalName='טלפון' colSize='col-4' />

                <DisplayHistoryInput
                    inputName='streetName' value={historyRecord.streetName}
                    labalName='רחוב' colSize='col-4' />
                <DisplayHistoryInput
                    inputName='house' value={historyRecord.house}
                    labalName='בית' colSize='col-4' />
                <DisplayHistoryInput
                    inputName='appartment' value={historyRecord.appartment}
                    labalName='דירה' colSize='col-4' />
            </InputCard>
            <InputCard>
                <DisplayHistoryInput
                    inputName='asmachta' value={historyRecord.asmachta}
                    labalName='אסמכתא' colSize='col-3' />
                <DisplayHistoryInput
                    inputName='hasPaidCredit' value={historyRecord.hasPaidCredit}
                    labalName='שולם באשראי' colSize='col-3' />
                <DisplayHistoryInput
                    inputName='hasPaidCash' value={historyRecord.hasPaidCash}
                    labalName='שולם במזומן' colSize='col-3' />
                <DisplayHistoryInput
                    inputName='amount' value={historyRecord.amount}
                    labalName='סכום תשלום' colSize='col-3' />
            </InputCard>
            <div className='row'>
                <div className='col'>
            <InputCard>
                <DisplayHistoryInput
                    inputName='email' value={historyRecord.vehicleNum}
                    labalName='מספר רכב' colSize='col-6' />
                <DisplayHistoryInput
                    inputName='makerText' value={historyRecord.makerText}
                    labalName='סוג רכב' colSize='col-6' />
                <DisplayHistoryInput
                    inputName='colorText' value={historyRecord.colorText}
                    labalName='צבע רכב' colSize='col-6' />
            </InputCard>
                </div>
                <div className='col'>
            <InputCard>
                <DisplayHistoryInput
                    inputName='userRegisterDate' value={historyRecord.userRegisterDate}
                    labalName='תאריך יצירת בקשה' colSize='col-6' />
                <DisplayHistoryInput
                    inputName='userUpdateDate' value={historyRecord.userUpdateDate}
                    labalName='תאריך עידכון' colSize='col-6' />
                <DisplayHistoryInput
                    inputName='userUpdateDate' value={historyRecord.userUpdateDate}
                    labalName='תאריך אישור הבקשה' colSize='col-6' />
                <DisplayHistoryInput
                    inputName='createdBy' value={historyRecord.createdBy}
                    labalName='נוצר על ידי' colSize='col-6' />
            </InputCard>
                </div>
            </div>

        </div>

    );
}

export default DisplayRequestHistory;