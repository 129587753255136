import React from 'react';
import { AiOutlineCloudServer } from "react-icons/ai";
import ActionBoxCard from '../../../../Components/UI/Card/ActionBoxCard'
import GoBackBtn from '../../../../Components/Buttons/GoBackBtn'
import CreatePermitBtn from '../../../../Components/Buttons/CreatePermitBtn'
import { useAddPermitPageContext } from '../../../../contexts/permit/AddPermitPageContext';
import Button from '../../../../Components/Buttons/Button';
import ActionInnerBoxCard from '../../../../Components/UI/Card/ActionInnerBoxCard';

const PermitDetailsButtonsBox = () => {

    const { componentState, handleAddPermit, handleGetInitPermitData } = useAddPermitPageContext();

    let isDisabled = false;

    if (componentState.isLoading) isDisabled = true;

    return (
        <ActionBoxCard>
            <ActionInnerBoxCard>
                <Button onClick={handleGetInitPermitData} text='בדיקת נתונים עם השרת' endIcon={<AiOutlineCloudServer />} />
                <CreatePermitBtn show={true} disabled={isDisabled} onClick={handleAddPermit} />
            </ActionInnerBoxCard>
            <ActionInnerBoxCard flexGrow={1} justifyContent='flex-end'>
                <GoBackBtn />
            </ActionInnerBoxCard>
        </ActionBoxCard>
    );
}

export default PermitDetailsButtonsBox;