import React from 'react';
import { useProjectDetailsContext } from '../../contexts/ProjectDetailsContext';
import Radio from '../../Components/Forms/radio/Radio';
import { CheckBoxColors } from '../../types/checkBoxColors.types';
//import { IExtra4Map } from '../../interface/permitRequest.interface';


const CheckBoxesForPardesHannaKarkur = ({ extra4Map, handleChange }) => {

  const { projectDetails } = useProjectDetailsContext();

  const projectId = projectDetails.projectId;

  if (projectId !== 18) {
    return null;
  }

  return (
    <div className='row'>
      <div className="col-12">
        {extra4Map && extra4Map.isCityResident ? (
          <Radio
            isChecked={extra4Map.isCityResident === '1'}
            label='תושב.ת המועצה / בעלי עסקים'
            value={extra4Map.isCityResident}
            onClick={null}
            id="isCityResidentId"
            name='isCityResident'
            color={CheckBoxColors.SUCCESS}
            disabled={true}
          />
        ) : ''}
      </div>
      <div className="col-12 mt-2">
        {extra4Map && extra4Map.needParkingInMunicipalPardesHannaKarkur ? (
          <Radio
            isChecked={extra4Map.needParkingInMunicipalPardesHannaKarkur === '1'}
            label='עובד.ת מועצה או ביה"ס אלונים'
            value={extra4Map.needParkingInMunicipalPardesHannaKarkur}
            onClick={null}
            id="needParkingInMunicipalPardesHannaKarkurId"
            name='needParkingInMunicipalPardesHannaKarkur'
            color={CheckBoxColors.SUCCESS}
            disabled={true}
          />
        ) : ''}
        {extra4Map.agriculturalSchoolFaculty ? (

          <Radio
            isChecked={extra4Map.agriculturalSchoolFaculty === '1'}
            label='סגל ביה"ס חקלאי'
            value={extra4Map.agriculturalSchoolFaculty}
            onClick={null}
            id="agriculturalSchoolFacultyId"
            name='agriculturalSchoolFaculty'
            color={CheckBoxColors.SUCCESS}
            disabled={true}
          />

        ) : ''}

        {extra4Map.collectionOrWaterDepartmentEmployees ? (

          <Radio
            isChecked={extra4Map.collectionOrWaterDepartmentEmployees === '1'}
            label='עובדי מחלקת גבייה/מים'
            value={extra4Map.collectionOrWaterDepartmentEmployees}
            onClick={null}
            id="collectionOrWaterDepartmentEmployeesId"
            name='collectionOrWaterDepartmentEmployees'
            color={CheckBoxColors.SUCCESS}
            disabled={true}
          />

        ) : ''}

        {extra4Map.planningAndConstructionCommitteeEmployees ? (

          <Radio
            isChecked={extra4Map.planningAndConstructionCommitteeEmployees === '1'}
            label='עובד.ת ועדה לתכנון ובנייה'
            value={extra4Map.planningAndConstructionCommitteeEmployees}
            onClick={null}
            id="planningAndConstructionCommitteeEmployeesId"
            name='planningAndConstructionCommitteeEmployees'
            color={CheckBoxColors.SUCCESS}
          />

        ) : ''}

        {extra4Map.kindergartenElaAndAgosEmployees ? (

          <Radio
            isChecked={extra4Map.kindergartenElaAndAgosEmployees === '1'}
            label='עובד.ת גן אלה/אגוז'
            value={extra4Map.kindergartenElaAndAgosEmployees}
            onClick={null}
            id="kindergartenElaAndAgosEmployeesId"
            name='kindergartenElaAndAgosEmployees'
            color={CheckBoxColors.SUCCESS}
          />

        ) : ''}
      </div>
    </div>
  );
}

export default CheckBoxesForPardesHannaKarkur;