import React from 'react';
import Table, { IRowData } from '../../../Components/UI/displayData/Table/Table';
import DateUtils from '../../../Helpers/time/DateUtils';
import { convertHistoryStatusCodeToText } from '../../../Helpers/conversion/convertTypeCodeToText';
import Button from '../../../Components/Buttons/Button';

const header = [
    {
        key: 'statusName',
        label: 'סוג פעולה',
        name: 'statusName',
    }, {
        key: 'dateTime',
        label: 'זמן פעולה',
        name: 'dateTime',
    }, {
        key: 'userName',
        label: 'משתמש',
        name: 'userName',
    }, {
        key: 'button',
        label: 'צפייה',
        name: 'button',
    },
];

const HistoryRecordTable = ({ history, goToViewHistoryRecord }) => {

    const rows: IRowData[] = convertToIRowData(history);

    return (
        <div style={{
            marginTop: '0.5rem',
            padding: '0.5rem',
            borderRadius: '10px',
            backgroundColor: '#EDEEF7',
            fontSize: '1rem'
        }}>
            <Table headers={header} rows={rows} />
        </div>
    );

    function convertToIRowData(historyRecords: any[]): IRowData[] {

        return historyRecords.map((historyRecord, index) => {

            const dateUtils = new DateUtils();
            const status = historyRecord.statusDetails.status
            const statusName = convertHistoryStatusCodeToText(status);
            const dateTime = dateUtils.getDateAndTimeStringFromUnixTimestams(historyRecord.statusDetails.date);

            const retVal: IRowData = {
                key: `historyRecord-${index}`,
                statusName: statusName,
                dateTime: dateTime,
                userName: historyRecord.userName,
                button: <Button text='צפייה בהסטוריה' onClick={() => goToViewHistoryRecord(index)} />,
            };

            return retVal;
        });
    }
}

export default HistoryRecordTable;