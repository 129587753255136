import axios from "./axios";
import { UserRoleType } from "../types/userRole.types";
import ResponseStatus from "../types/responseStatus.types";


export const LOGIN = '/secretary/login';

export const GET_SYSTEM_TABLES = '/systemTables/get-backoffice-data-config';

export type TypeLoginResult = {
    accessToken: string,
    refreshToken: string,
    userName: string,
    userRole: UserRoleType,
}

export async function loginApi(projectId: number, userName: string, password: string): Promise<TypeLoginResult> {

    const result: TypeLoginResult = {
        accessToken: '',
        refreshToken: '',
        userName: '',
        userRole: UserRoleType.CITIZEN,
    }

    const dataToSend = {
        userName: userName,
        projectId: projectId,
        password: password,
    };

    const response = await axios.post(LOGIN, dataToSend);
    const responseData = response.data ? response.data : { status : ResponseStatus.FAILURE};

    if (responseData.status !== ResponseStatus.SUCCESS) {

        throw new Error(`status is not ${ResponseStatus.SUCCESS}, response.data.message- ${responseData.message}`);
    }

    result.accessToken = responseData.data.accessToken ? responseData.data.accessToken : '';
    result.refreshToken = responseData.data.refreshToken ? responseData.data.refreshToken : '';
    result.userName = responseData.data.userName ? responseData.data.userName : '';
    result.userRole = responseData.data.userRole ? responseData.data.userRole : UserRoleType.SECRETARY;

    return result;
}