import { IHistoryModalData } from "../interface/history/IHistoryModalData.interface";
import { IHistoryRecord } from "../interface/history/IHistoryRecord";

export enum HistoryModalActionKind {
    CLOSE_HISTORY_MODAL = 'CLOSE_HISTORY_MODAL',
    OPEN_HISTORY_MODAL = 'OPEN_HISTORY_MODAL',
    SET_HISTORY_LIST = 'SET_HISTORY_LIST',
    UPDATE_IDENTIFIER = 'UPDATE_IDENTIFIER',
    UPDATE_IS_REQUEST = 'UPDATE_IS_REQUEST',
    GO_TO_RECORD_LIST = 'GO_TO_RECORD_LIST',
    GO_TO_VIEW_RECORD = 'GO_TO_VIEW_RECORD',
    IS_LOADING = 'IS_LOADING',
    IS_NOT_LOADING = 'IS_NOT_LOADING',
}

// An interface for our actions
type HistoryModalActionAction = {
    type: HistoryModalActionKind.CLOSE_HISTORY_MODAL;
} | {
    type: HistoryModalActionKind.UPDATE_IDENTIFIER;
    identifier: number;
} | {
    type: HistoryModalActionKind.SET_HISTORY_LIST;
    historyList: IHistoryRecord[];
} | {
    type: HistoryModalActionKind.GO_TO_RECORD_LIST;
}| {
    type: HistoryModalActionKind.GO_TO_VIEW_RECORD;
    payload: {
        currentRecord: IHistoryRecord,
    };
}| {
    type: HistoryModalActionKind.IS_LOADING;
}| {
    type: HistoryModalActionKind.IS_NOT_LOADING;
    msg: string;
}| {
    type: HistoryModalActionKind.OPEN_HISTORY_MODAL;
    payload: {
        isRequest: boolean,
        identifier: number,
    };
};


export const historyModalReducer = (state: IHistoryModalData, action: HistoryModalActionAction) => {

    switch (action.type) {
        case HistoryModalActionKind.CLOSE_HISTORY_MODAL:

            return {...state, isShowHistoryModal: false};
        case HistoryModalActionKind.UPDATE_IDENTIFIER:

            return {
                ...state, identifier: action.identifier
            };
        case HistoryModalActionKind.SET_HISTORY_LIST:

            return {
                ...state,
                recordList: action.historyList,
                currentRecord: {},
                isShowRecordRow: false,
                modalStatus: { isLoading: false, msg: '' }
            };
        case HistoryModalActionKind.GO_TO_RECORD_LIST:

            return {
                ...state,
                currentRecord: {},
                isShowRecordRow: false
            };
        case HistoryModalActionKind.GO_TO_VIEW_RECORD:

            return {
                ...state,
                currentRecord: action.payload.currentRecord,
                isShowRecordRow: true
            };
        case HistoryModalActionKind.IS_LOADING:

            return {
                ...state,
                modalStatus: { isLoading: true, msg: '' }
            };
        case HistoryModalActionKind.IS_NOT_LOADING:

            return {
                ...state,
                modalStatus: { isLoading: false, msg: action.msg }
            };
        case HistoryModalActionKind.OPEN_HISTORY_MODAL:
         
            return {
                ...state,
                isShowHistoryModal: true,
                isRequest: action.payload.isRequest,
                identifier: action.payload.identifier,
                recordList: [],
                currentRecord: {},
                isShowRecordRow: false,
                modalStatus: { isLoading: false, msg: '' }
            };

        default:
            return state;
    }
}