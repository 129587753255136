import React, { FC } from 'react';
import Table, { IFooterCellData, IHeaderData, IRowData } from './Table';

type TypeSimpleTable = {
    headers: IHeaderData[],
    rows: IRowData[],
    footers?: IFooterCellData[],
    name?: string,
}

const SimpleTable: FC<TypeSimpleTable> = ({
    rows,
    headers,
    footers = [] as IFooterCellData[],
    name = 'שורות',
}) => {

    const rowsCount = Array.isArray(rows) ? rows.length : 0;
    const headersCount = Array.isArray(headers) ? headers.length : 0;

    return (
        <>
            {rowsCount < 1 && headersCount < 1 ? (
                <div style={{ fontSize: '1.5rem', marginBottom: '1rem' }}>
                    מספר {name} <span style={{ 'fontWeight': 'bold', color: 'orange' }}>{rowsCount}</span>
                </div>
            ) : ''}
            <Table headers={headers} rows={rows} footers={footers} />
        </>
    );
}

export default SimpleTable;