import React from 'react';
import { usePermitsListPageContext } from '../../../../contexts/permit/PermitsListPageContext';
import PermitCard from './PermitCard';
import ListOfRows from '../../../../Components/UI/displayData/listOfRows/ListOfRows';
import ExportToCsvBtn from './ExportToCsvBtn';

const DisplayManagementPermit = () => {

    const { permitListPageData, Formik, changePage, componentState, refHistoryModal, getRowForCsv } = usePermitsListPageContext();
    const openHistoryModalFunc = refHistoryModal.current?.openHistoryModal ? refHistoryModal.current.openHistoryModal : null;
    const { values, setFieldValue } = Formik;
    const permits = permitListPageData.permits;
    const totalCount = permitListPageData.totalCount;
    const offset = values.offset;
    const limit = values.limit;
    const startIndex = offset * limit + 1;


    const paigingData = {
        offset: offset,
        limit: limit,
        totleCount: totalCount,
        changePage: changePage,
        isLoading: componentState.isLoading
    }

    const rows = permits.map((permit, index) => {

        return <PermitCard rawData={permit} key={index} index={startIndex + index} openHistory={openHistoryModalFunc} />
    });

    return (

        <>
            <div className='row'>
                <div className='col'>
                    <ExportToCsvBtn permitsSize={permits.length} getRowForCsv={getRowForCsv} />
                </div>
            </div>

            <ListOfRows
                rows={rows}
                name={'תווים'}
                paigingData={paigingData}
                handleChenge={event => setFieldValue('limit', event.currentTarget.value)}
            />
        </>
    );
}

export default DisplayManagementPermit;