import React from 'react';
import { VscSearchStop } from "react-icons/vsc";

const cssStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '15rem',
    columnGap: '0.5rem',
    fontSize: '2rem',
}

const NoDataFoundWithFilter = () => {

    const noContentDisplayMessage = 'לא נימצאו נתונים שתואמים את החיפוש';

    return (
        <div style={cssStyle}>
            <div>
                {noContentDisplayMessage} 
            </div>
            <div><VscSearchStop /></div>
        </div>
    );
}

export default NoDataFoundWithFilter;