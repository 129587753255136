import React, { FC } from 'react';
import { useProjectDetailsContext } from '../../../../contexts/ProjectDetailsContext';
import { HistoryPermitList, ViewPermitBtn } from '../../../../Components/Buttons/buttuns.import';
import { RowCard, RowCardItem, PaymentRowItem } from '../../../../Components/UI/displayData/listOfRows/listOfRows.import';
import BtnOpenCPSModal from './changePermitStatusModal/BtnOpenCPSModal';
import { usePermitsListPageContext } from '../../../../contexts/permit/PermitsListPageContext';
import SpanPermitStatus from './SpanPermitStatus';
import { IPermit, IResident, IVehicle } from '../../../../interface/permit/permit.interface';
import { convertPermitStatusCodeToText, permitTypeCodeToText } from '../../../../Helpers/conversion/convertTypeCodeToText';
import { decodeNCRStringIfNeeded } from '../../../../Helpers/language';
import { IPermitPageCard } from '../../../../interface/permit/permitListPage.interface';
import { PermitStatusType, PermitType } from '../../../../types/permit.types';
import { PaymentMethod } from '../../../../types/paymentMethod.types';
import { useAppInfoContext } from '../../../../contexts/AppInfoContext';
import { AppUIBrackPoint } from '../../../../interface/appInfo.interface';

type PermitCardProps = {
    rawData: IPermit,
    index: number,
    openHistory: (isShow: boolean, permitId: number) => void,
}

const PermitCard: FC<PermitCardProps> = ({ rawData, index, openHistory }) => {

    const { screenWidth } = useAppInfoContext();
    const { projectDetails } = useProjectDetailsContext();
    const { openChangeStatusModal } = usePermitsListPageContext();
    const showVehicleType = projectDetails.guiConfig.permitListPageGuiConfig.showVehicleType;
    const data = convPermitRowRawDataFromServer(rawData);

    const moblieLayout = (screenWidth === AppUIBrackPoint.XS || screenWidth === AppUIBrackPoint.SM);
    const showHistoryBtn = !moblieLayout;
    
    const hasPayment = projectDetails.hasPayment;
    const gilHaZahavOption = projectDetails.taveiDayarSiteConfig.gilHaZahavOption;
    const permitStatusCode = data.statusTavCode;

    return (
        <RowCard
            index={index}
            content={(
                <>
                    <RowCardItem label='מספר תו' value={data.id} />
                    <RowCardItem label='תאריך התחלה' value={data.startDate} />
                    <RowCardItem label='תאריך סוף' value={data.endDate} />
                    <RowCardItem label='מספר רכב' value={data.vehicleNum} />
                    <RowCardItem label='מספר זהות' value={data.personId} />
                    <RowCardItem label='שם פרטי' value={data.fName} />
                    <RowCardItem label='שם משפחה' value={data.lName} />
                    {showVehicleType ? <RowCardItem label='סוג רכב' value={data.vehicleGroupName} /> : null}
                    {gilHaZahavOption ? <RowCardItem label='סוג תו' value={data.permitTypeName} /> : null}
                    <PaymentRowItem isShow={hasPayment} label='תשלום' paymentMethodCode={data.paymentMethodCode} moneyAmount={data.moneyAmount} />
                    <RowCardItem
                        label='סטטוס'
                        value={<SpanPermitStatus statusName={data.statusTav} permitStatusCode={permitStatusCode} />}
                    />
                </>
            )}
            footer={(
                <>
                    <BtnOpenCPSModal onClick={() => openChangeStatusModal(data)} />
                    <HistoryPermitList show={showHistoryBtn} onClick={() => openHistory(false, data.id)} />
                    <ViewPermitBtn rowDbId={data.id} />
                </>
            )}
        />
    );

    function convPermitRowRawDataFromServer(data: IPermit): IPermitPageCard {

        const vehicleGroups = projectDetails.vehicleGroups
        const vehicle: IVehicle = data.vehicle;
        const resident: IResident = data.resident;

        const startValidityLong = data.startValidityDate ? data.startValidityDate : -1;
        const endValidityLong = data.endValidityDate ? data.endValidityDate : -1;
        const statusCode = data.statusTav ? data.statusTav : PermitStatusType.ACTIVE;
        const status = convertPermitStatusCodeToText(statusCode);
        const vehicleGroupId = vehicle.vehicleGroupId ? vehicle.vehicleGroupId : -1;
        const permitTypeCode = data.tavGilZahav ? data.tavGilZahav : PermitType.ALL;
        const vehicleGroupName = vehicleGroups.find(vehicleGroup => vehicleGroup.id === vehicleGroupId)?.vehicleGroupName || '';

        return {
            startDate: startValidityLong > 0 ? new Date(startValidityLong).toLocaleDateString() : '',
            endDate: endValidityLong > 0 ? new Date(endValidityLong).toLocaleDateString() : '',
            lName: resident.lastName ? decodeNCRStringIfNeeded(resident.lastName) : '',
            fName: resident.firstName ? decodeNCRStringIfNeeded(resident.firstName) : '',
            personId: resident.personId ? resident.personId : '',
            vehicleNum: vehicle.vehicleNum ? vehicle.vehicleNum : '',
            ownerId: data.ownerId ? data.ownerId : -1,
            id: data.id ? data.id : -1,
            statusTav: status,
            statusTavCode: statusCode,
            paymentMethodCode: data.paymentMethod ? data.paymentMethod : PaymentMethod.NONE,
            moneyAmount: data.payment ? data.payment : 0,
            vehicleGroupName: decodeNCRStringIfNeeded(vehicleGroupName),
            permitTypeName: permitTypeCodeToText(permitTypeCode),
        };
    }
}

export default PermitCard;