import axios from "../api/axios";
import { useAuthContextContext} from '../contexts/AuthContext';
import { useProjectDetailsContext } from "../contexts/ProjectDetailsContext";

const useRefreshToken = () => {

    const { authState, setAccessToken } = useAuthContextContext();
    const { loadProjectDetails } = useProjectDetailsContext();

    const { refreshToken } = authState;

    const refresh = async () => {

        const config = {
            headers:{
                Authorization: "Bearer " + refreshToken,
                'Content-Type': 'application/json',
          }
        };

        const response = await axios.get('/secretary/login/refreshToken', config);
        const newAccessToken = response.data.data.newAccessToken
        
        setAccessToken(newAccessToken);
        
        config.headers.Authorization = "Bearer " + newAccessToken
        const response2 = await axios.get('/systemTables/get-backoffice-data-config', config);

        loadProjectDetails(response2.data.data.bean);

        return newAccessToken;
    }

    return refresh;
}

export default useRefreshToken;
