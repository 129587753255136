import React from 'react';
import Stlye from './pageTitle.module.css';

type Props = {
  title: string | React.ReactNode;
}

const PageTitle = ({ title }: Props) => {
  return (
    <div className={Stlye.title}>{title}</div>
  );
}

export default PageTitle;