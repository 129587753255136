import React from 'react';
import { IoMdClose } from 'react-icons/io';
import Style from './alers.module.css';

type Props = {
    onClick: () => void;
}

function AlertCloseBtn({onClick}: Props) {

    return (
        <span className={Style.btnCloseAlet} onClick={onClick}>
            <IoMdClose className={Style.btnCloseAletIcon} />
        </span>
    );
}

export default AlertCloseBtn;