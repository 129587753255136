import React from 'react';
import Alert from '../../../../../Components/Alerts/Alert';
import { useAddPermitPageContext } from '../../../../../contexts/permit/AddPermitPageContext';
import { AlertColors } from '../../../../../types/alertColors.types';

const AlertExistingPermit = () => {

    const { greatestEndDateOfActivePermit, setGreatestEndDateOfActivePermit } = useAddPermitPageContext();

    if (!greatestEndDateOfActivePermit)
        return null;

    return (
        <div className='row mt-1'>
            <div className='col-12'>
                <Alert
                    subject={`קיים תו לרכב ומספר תעודת זהות אשר עדיין בתוקף ונגמר בתאריך ${greatestEndDateOfActivePermit}`}
                    isShow={true}
                    message={`קיים תו לרכב ומספר תעודת זהות אשר עדיין בתוקף ונגמר בתאריך ${greatestEndDateOfActivePermit}, לכן תאריך סיום התו הנוכחי מתארך באתאם.`}
                    type={AlertColors.WARNING}
                    onClose={() => setGreatestEndDateOfActivePermit('')}
                />
            </div>
        </div>
    );
}

export default AlertExistingPermit;