export const initLoginData = {
    projectId: 1,
    userName: '',
    password: ''
}

export const initDammyLoginData = {
    projectId: 51,
    userName: 'plola51',
    password: 'lola51'
}