import moment from "moment";
import { IExpiredPermitPageForm, IExpiredPermitPageData } from "../../interface/permit/expiredPermitPage.interface";

export const initExpiredPermitFormData: IExpiredPermitPageForm = {
    limit: 1000,
    offset: 0,
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
    onlyActivePermits: '0',
}

export const initExpiredPermitData: IExpiredPermitPageData = {
    count: 0,
    list: [],
    csvHeader: [
        { displayName: "שם מלא", id: "fullName" },
        { displayName: "מס רכב", id: "vehicleNum" },
        { displayName: "טלפון", id: "cellPhone" },
        { displayName: "מספר טלפון נוסף", id: "additionalPhone" },
        { displayName: "תאריך סיום", id: "endValidityDate" },
        { displayName: "יצרן", id: "makerName" },
    ]
}