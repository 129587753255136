import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { AppInfoActionKind, appInfoReducer } from "../reducers/appInfoReducer";
import { initAppInfoData } from "../data/default/appInfo.data";
import moment from "moment";
import { AppFormNames, AppUIBrackPoint, IAppInfoData } from "../interface/appInfo.interface";

type TypeAppInfoContext = {
    appInfoState: IAppInfoData,
    updateInitForm: (initFormName: any, formData: any) => void,
    getCurrentInitForm: (initFormName: any, defaultInitFormName: any) => any,
    screenWidth: AppUIBrackPoint
}

export const AppInfoContext = createContext<TypeAppInfoContext | undefined>(undefined);

export const AppInfoProvider = ({ children }: { children: React.ReactNode }) => {

    const [appInfoState, appInfoStateDispatch] = useReducer(appInfoReducer, initAppInfoData);
    const [screenWidth, setScreenWidth] = useState(getScreenAppUIBrackPoint());

    useEffect(() => {

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <AppInfoContext.Provider value={{ appInfoState, updateInitForm, getCurrentInitForm, screenWidth }}>
            {children}
        </AppInfoContext.Provider>
    );

    function getCurrentInitForm(initFormName: AppFormNames, defaultInitFormName: any) {

        if (appInfoState.formsInitData[initFormName] && appInfoState.formsInitData[initFormName].currentForm) {

            const currentFormUpdateTime = moment(appInfoState.formsInitData[initFormName].updateDate, 'YYYY-MM-DD HH:mm:ss');
            const nowTime = moment(new Date());
            currentFormUpdateTime.add(10, 'minutes');
            const isSameOrAfter = nowTime.isAfter(currentFormUpdateTime);

            if (isSameOrAfter) {

                return appInfoState.formsInitData[initFormName].initForm;
            }

            return appInfoState.formsInitData[initFormName].currentForm
        }

        return defaultInitFormName;
    }

    function updateInitForm(initFormName: AppFormNames, formData: any) {
        appInfoStateDispatch({
            type: AppInfoActionKind.UPDATE_INIT_FORM,
            payload: {
                formName: initFormName,
                formData: formData
            }
        });
    }

    function handleResize() {

        setScreenWidth(getScreenAppUIBrackPoint());
    }

    function getScreenAppUIBrackPoint(): AppUIBrackPoint {

        const screenWidth = window.innerWidth;

        if (screenWidth < 576) {

            return AppUIBrackPoint.SM;
        } else if (screenWidth < 768) {

            return AppUIBrackPoint.MD;
        } else if (screenWidth < 992) {

            return AppUIBrackPoint.LG;
        } else if (screenWidth < 1200) {

            return AppUIBrackPoint.XL;
        }

        return AppUIBrackPoint.XXL;
    }
}

export const useAppInfoContext = () => useContext(AppInfoContext);