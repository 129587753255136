import React, { FC, useState } from 'react';
import RedStar from './RedStar';
import InfoCircle from './InfoCircle';
import EyeIcon from './EyeIcon';

type Props = {
    name: string;
    label?: string;
    value?: any;
    styleInput?: React.CSSProperties;
    styleLabel?: React.CSSProperties;
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    handleClearInput?: (name: string) => void | undefined;
    type?: 'text' | 'number' | 'file' | 'email' | 'password';
    size?: 's' | 'm' | 'l';
    disabled?: boolean;
    required?: boolean;
    infoMsg?: string;
    errorMsg?: string;
}

const InputGroup: FC<Props> = ({
    name,
    label = '',
    value = '',
    styleInput = {},
    styleLabel = {},
    handleChange = null,
    handleBlur = null,
    handleClearInput = null,
    type = 'text',
    size = 'm',
    disabled = false,
    required = false,
    infoMsg = '',
    errorMsg = '',
}) => {

    const [hidePassword, setHidePassword] = useState(true);
    let classes = 'form-control';
    const hasError = errorMsg ? true : false;

    if (size === 's') {
        classes += ' form-control-sm';
    } else if (size === 'l') {
        classes += ' form-control-lg';
    }

    if (hasError) {
        classes += ' is-invalid';
    }

    return (
        <div>
            {label ? (
                <label className="form-label" style={styleLabel}>
                    {label}
                    {required ? <RedStar /> : ''}
                    {infoMsg ? <>&nbsp;<InfoCircle infoMsg={infoMsg} /></> : ''}
                </label>
            ) : ''}

            {type === 'file' ? (
                <input type={type} onBlur={handleBlur} className={classes}
                    onChange={handleChange} name={name} disabled={disabled} style={styleInput} />
            ) : (
                <div style={{ position: 'relative' }}>
                    <input
                        className={classes}
                        type={(type === 'password' && !hidePassword) ? 'text' : type}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name={name}
                        value={value}
                        disabled={disabled}
                        style={{ ...styleInput }} />
                    <EyeIcon size={size} hidePassword={hidePassword} onClick={() => setHidePassword(prev => !prev)} showComponent={type === 'password'} />
                </div>
            )}
            {hasError ? <div className="invalid-feedback"> {errorMsg} </div> : ''}
        </div>
    );
}

export default InputGroup;