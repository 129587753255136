import React, { CSSProperties, ReactNode } from 'react';

type SimpleCardProps = {
    children: ReactNode;
    style?: CSSProperties;
}

const SimpleCard: React.FC<SimpleCardProps> = ({ children, style = {} }) => {

    return (
        <div style={style} className='card'>
            <div className='card-body' style={{
                display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', backgroundColor: '#FAFAFA'
            }}>
                {children}
            </div>
        </div>
    );
}

export default SimpleCard;