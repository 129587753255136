import React, { forwardRef, ReactNode, useEffect, useImperativeHandle } from 'react';
import HistoryRecordTable from './components/HistoryRecordTable';
import DisplayHistoryRecord from './components/DisplayHistoryRecord';
import LoadingDiv from '../../Components/UI/loadingDiv/LoadingDiv';
import GeneralModal from '../../Components/GeneralModal/GeneralModal';
import useHistoryModal from '../../Hooks/useHistoryModal';

const HistoryModal = ({ }, ref) => {

  const { handleGetHistory, goToHistoryRecordList, goToViewHistoryRecord,
    closeHistoryModal, historyModalState, openHistoryModal } = useHistoryModal();

  const showHistoryModal = historyModalState.isShowHistoryModal;
  const isRequest = historyModalState.isRequest;
  const isLoadingHistoryModal = historyModalState.modalStatus.isLoading;

  useImperativeHandle(ref, () => ({
    openHistoryModal
  }));

  useEffect(() => {

    if (showHistoryModal) {
      handleGetHistory();
    }
  }, [showHistoryModal]);

  if (!showHistoryModal) {
    return null;
  }

  let content: string | ReactNode = 'אין הסטוריה';

  if (isLoadingHistoryModal) content = <LoadingDiv />;

  if (!isLoadingHistoryModal) {

    content = <HistoryRecordTable history={historyModalState.recordList} goToViewHistoryRecord={goToViewHistoryRecord} />;

    if (historyModalState.isShowRecordRow) {
      content = (
        <DisplayHistoryRecord
          historyRecDataRaw={historyModalState.currentRecord}
          GoBackFunc={goToHistoryRecordList}
          isRequest={isRequest} />
      );
    }
  }

  return (
    <GeneralModal
      isShow={showHistoryModal}
      content={content}
      closingFun={closeHistoryModal}
      title={'רשימת הסטוריה'}
      hasFooter={false}
    />
  );

}

export default forwardRef(HistoryModal);