import React, { useState, createContext, useContext, useMemo, useEffect, useRef } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import moment from "moment";
import { useFormik } from "formik";
import { useProjectDetailsContext } from "../ProjectDetailsContext";
import { initPermitData } from "../../data/default/permit.data";
import usePermitApi from "../../Hooks/api/usePermit.api";
import useUploadFileModal from "../../features/uploadFile/useUploadFileModal";
import ViewPermit from "../../Pages/Permits/viewPermit/ViewPermit";
import { viewPermitSchema } from "../../validation/permit/viewPermitValidation";
import { decodeNCRStringIfNeeded } from "../../Helpers/language";
import { useComponentState } from "../../Hooks/useComponentState";
import { IPermitForm } from "../../interface/permit/permitForm.interface";
import { ResponseStatus } from "../../types/types.import";


type TypePermitDetailsPageContext = {
    handleChange: any,
    Formik: any,
    handleUpdatePermit: any,
    handleCloseModal: any,
    successModalIsShown: any,
    componentState: any,
    uploadFileModal: any,
    closeUploadFileModal: any,
    openUploadFileModal: any,
    handleUploadFile: any,
    errorFileModal: any,
    isLoadingFileModal: any,
    refHistoryModal: React.RefObject<any>,
}

export const PermitDetailsPageContext = createContext<TypePermitDetailsPageContext | undefined>(undefined);

const PermitDetailsPageProvider = () => {

    const navigate = useNavigate();
    const params = useParams();

    const { projectDetails } = useProjectDetailsContext();
    const { componentState, setDangerAlert, setIsLoading, setIsNotLoading } = useComponentState();
    const { uploadFileModal, setUploadFileModal, uploadFileToServer, errorFileModal, isLoadingFileModal } = useUploadFileModal();
    const [successModalIsShown, setSuccessModalIsShown] = useState(false);
    const [hasRefresh, setHadRefresh] = useState(false);
    const { getPermitApi, updatePermitApi } = usePermitApi();
    const refHistoryModal = useRef(null);
    const permitId = Number(params.permitId);

    const vehicleGroupName = getPrivateVehicleGroupName();

    const Formik = useFormik<IPermitForm>({
        initialValues: { ...initPermitData, vehicleGroupName: vehicleGroupName },
        validateOnMount: true,
        validateOnChange: false,
        validationSchema: viewPermitSchema,
        onSubmit: () => { }
    });

    const { values, setFieldValue, validateForm, resetForm, setFieldTouched } = Formik;

    const handleChange = useMemo(() => {
        return (event) => {
            setFieldValue(event.target.name, event.target.value)
        };
    }, []);


    // onload page
    useEffect(() => {

        if (permitId < 0 && hasRefresh) {

            resetForm();
            return;
        }

        if (permitId > 0)
            refreshPermitDetails(permitId);

    }, [permitId]);

    async function refreshPermitDetails(permitId) {

        setIsLoading()
        setHadRefresh(true);

        const { data, status, message } = await getPermitApi(permitId);
        const { permit, resident, vehicle } = data;


        if (status === ResponseStatus.FAILURE) {

            setDangerAlert(message);
            return;
        }

        setFieldValue('carNumber', vehicle.vehicleNum);
        setFieldValue('vType', permit.vehicle.vehicleMake);
        setFieldValue('vColor', permit.vehicle.vehicleColor);
        setFieldValue('vehicleGroupName', permit.vehicle.vehicleGroupId);

        setFieldValue('projectId', permit.projectId);
        setFieldValue('asmachta', permit.asmachta);
        setFieldValue('amount', permit.payment);
        setFieldValue('paymentMethod', permit.paymentMethod)
        setFieldValue('approveUserId', permit.approveUserId);
        setFieldValue('permitId', permit.id);
        setFieldValue('approveUserId', permit.approveUserId);
        setFieldValue('userInsertName', permit.userInsertName);
        setFieldValue('createdBy', permit.extra1);
        setFieldValue('zone', permit.zone);
        setFieldValue('status', permit.status);
        setFieldValue('statusTav', permit.statusTav);
        setFieldValue('permitType', permit.tavGilZahav);
        setFieldValue('loginUser', permit.loginUser);

        setFieldValue('privateName', decodeNCRStringIfNeeded(resident.firstName));
        setFieldValue('familyName', decodeNCRStringIfNeeded(resident.lastName));
        setFieldValue('comments', decodeNCRStringIfNeeded(resident.comments));
        setFieldValue('personId', resident.personId);
        setFieldValue('phone', resident.phoneNum);
        setFieldValue('mobile', resident.cellPhone);
        setFieldValue('houseNum', decodeNCRStringIfNeeded(resident.houseNum));
        setFieldValue('appartmentNum', resident.appatmentNum);
        setFieldValue('street', resident.streetId);
        setFieldValue('email', resident.email);
        setFieldValue('ownershipCarType', vehicle.companyEntity === true ? 0 : 1);

        let issueDate = '';
        let startValidityDate = permit.startValidityDate;
        let endValidityDate = permit.endValidityDate;
        let approvalDate = permit.approvalDate;
        let updateDate = permit.vehicleOwnership.updateDate;
        const DATE_FORMAT_STR = "DD/MM/YYYY, HH:mm:ss";
        const DATE_FORMAT_INPUT = 'YYYY-MM-DD';

        if (Number(permit.issueDate) > 0) {

            issueDate = moment(permit.issueDate).format(DATE_FORMAT_STR);
        }

        if (Number(permit.startValidityDate) > 0) {

            startValidityDate = moment(permit.startValidityDate).format(DATE_FORMAT_INPUT);
        }

        if (Number(permit.endValidityDate) > 0) {

            endValidityDate = moment(permit.endValidityDate).format(DATE_FORMAT_INPUT);
        }

        if (Number(permit.approvalDate) > 0) {

            approvalDate = moment(permit.approvalDate).format(DATE_FORMAT_STR);
        }

        if (Number(permit.vehicleOwnership.updateDate) > 0) {

            updateDate = moment(permit.vehicleOwnership.updateDate).format(DATE_FORMAT_STR);
        }

        setFieldValue('issueDate', issueDate);
        setFieldValue('endValidityDate', endValidityDate);
        setFieldValue('startValidityDate', startValidityDate);
        setFieldValue('approvalDate', approvalDate);
        setFieldValue('updateDate', updateDate);

        setIsNotLoading();
    }

    async function handleUpdatePermit(event) {

        event.preventDefault();
        setIsLoading();

        const jsonErrors = await validateForm();

        const errorName = Object.keys(jsonErrors);

        if (errorName.length > 0) {

            errorName.forEach(name => {
                setFieldTouched(name, true);
            });

            setIsNotLoading();
            return;
        }

        const responseData = await updatePermitApi(values);

        setIsNotLoading();

        if (responseData.status === ResponseStatus.SUCCESS) {
            setSuccessModalIsShown(true);
        } else {

            setDangerAlert(responseData.message);
        }

    }

    function handleCloseModal(url) {

        setSuccessModalIsShown(false);
        navigate(url);
    }

    function closeUploadFileModal() {
        setUploadFileModal(false);
    }

    function openUploadFileModal() {
        setUploadFileModal(true);
    }

    async function handleUploadFile(personId, file) {
        const response = await uploadFileToServer(personId, file);
    }

    function getPrivateVehicleGroupName() {

        const vehicleGroupList = projectDetails.vehicleGroups;

        const val = vehicleGroupList.find(v => v.vehicleGroupName === '&#1508;&#1512;&#1496;&#1497;');
   
        return val ? val.id : -1;
    }

    return (
        <PermitDetailsPageContext.Provider value={{
            handleChange, Formik,
            handleUpdatePermit,
            handleCloseModal, successModalIsShown,
            componentState,
            uploadFileModal, closeUploadFileModal, openUploadFileModal, handleUploadFile,
            errorFileModal, isLoadingFileModal,
            refHistoryModal
        }}>
            <ViewPermit />
        </PermitDetailsPageContext.Provider>
    );
}

export default PermitDetailsPageProvider;

export const usePermitDetailsPageContext = () => useContext(PermitDetailsPageContext);