import React from 'react';
import { useRequestManagmentPageContext } from '../../../../contexts/request/RequestDetailsPageContext';
import useEndDateDefualtValInput from '../../../../Hooks/useEndDateDefualtValInput';
import InputTextArea from '../../../../Components/Forms/InputTextArea';
import SelectPermitType from '../../../../Components/Forms/select/SelectPermitType';
import CheckBtnsContainer from '../../../../Components/Forms/check/CheckBtnsContainer';
import OutlineCheckBtn from '../../../../Components/Forms/check/OutlineCheckBtn';
import InputGroup from '../../../../Components/Forms/InputGroup';
import DateInput from '../../../../Components/Forms/DateInput';
import FormSection from '../../../../Components/UI/formSection/FormSection';

const CommentAndPic = () => {

    const { calcDefualtDate } = useEndDateDefualtValInput();
    const { handleChange, setIsLoading, setIsNotLoading, Formik } = useRequestManagmentPageContext();

    const { errors, touched, handleBlur, setFieldValue, values } = Formik;
    const isApprovedRequest = values.approvalDate ? true : false;

    return (
        <FormSection title={"פרטים כללים"}>
            <div className="row">
                <div className='col-md-6 col-lg-8'>
                    <InputTextArea
                        rows={4}
                        label={'הערה'}
                        name={'secreteryMessage'}
                        value={values.secreteryMessage}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorMsg={(errors.secreteryMessage && touched.secreteryMessage) ? errors.secreteryMessage : undefined}
                    />
                </div>

                {!isApprovedRequest ? (
                    <div className="col-md-6 col-lg-4">
                        <div className="row">
                            <div className="col-12">
                                <SelectPermitType
                                    name={'tavGilZahav'}
                                    required={true}
                                    handleBlur={handleBlur}
                                    handlePermitTypeChange={handleChangeType}
                                    value={values.tavGilZahav}
                                    errorMsg={(errors.tavGilZahav && touched.tavGilZahav) ? errors.tavGilZahav : undefined}
                                />
                            </div>

                            <div className='col-12'>
                                <DateInput
                                    label='סוף תאריך'
                                    name='endValidityDate'
                                    value={values.endValidityDate}
                                    required={true}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    errorMsg={(errors.endValidityDate && touched.endValidityDate) ? errors.endValidityDate : undefined}
                                />
                            </div>
                        </div>
                    </div>
                ) : ''
                }

                <div className='row mt-2'>
                    <div className="col-md-12 col-lg-6">
                        <InputGroup
                            type='file'
                            label={'קובץ'}
                            name={'extraFile1'}
                            handleChange={handleFile}
                            handleBlur={handleBlur}
                            errorMsg={(errors.extraFile1 && touched.extraFile1) ? errors.extraFile1 : undefined}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className="col-12">
                        <CheckBtnsContainer label='קבצים'>
                            <OutlineCheckBtn
                                label='תעודת זהות וספח'
                                id={'hardCopyPersonId'}
                                name={'hardCopyPersonId'}
                                isChecked={values.hardCopyPersonId === 1}
                                onClick={handleClick}
                                value={1}
                            />
                            <OutlineCheckBtn
                                label='רשיון רכב בתוקף'
                                id={'hardCopyLicense'}
                                name={'hardCopyLicense'}
                                isChecked={values.hardCopyLicense === 1}
                                onClick={handleClick}
                                value={1}
                            />
                            <OutlineCheckBtn
                                label='הצהרה ממקום העבודה'
                                id={'hardCopyLeasing'}
                                name={'hardCopyLeasing'}
                                isChecked={values.hardCopyLeasing === 1}
                                onClick={handleClick}
                                value={1}
                            />
                        </CheckBtnsContainer>
                    </div>
                </div>
            </div>
        </FormSection>
    );


    function handleClick(event: React.MouseEvent<HTMLInputElement>) {
        
        const isChecked = event.currentTarget.checked;
        setFieldValue(event.currentTarget.name, isChecked ? 1 : 0);
    }


    async function handleChangeType(name: string, value: number): Promise<void> {

        let defDateVal = '';

        if (value >= 0) {

            setIsLoading();
            defDateVal = await calcDefualtDate(value);
            setIsNotLoading();
        }

        setFieldValue(name, value);
        setFieldValue('endValidityDate', defDateVal);
    }

    function handleFile(event) {

        const value = event.currentTarget.files[0];

        setFieldValue(event.currentTarget.name, value);
    }
}

export default CommentAndPic;