import React from 'react';
import { useRequestManagmentPageContext } from '../../../../contexts/request/RequestManagmentPageContext';
import RequestCard from './RequestCard';
import ListOfRows from '../../../../Components/UI/displayData/listOfRows/ListOfRows';

const DisplayManagmentRequestTavim = () => {

    const { componentState, Formik, dataState, changePage } = useRequestManagmentPageContext();

    const { values } = Formik;

    const permitRequestItemList = dataState.requests;
    const offset = values.offset;
    const limit = values.limit;
    const startIndex = offset * limit + 1;
   
    let rows = [];

    const paigingData = {
        offset: values.offset,
        limit: values.limit,
        totleCount: dataState.count,
        changePage: changePage,
        isLoading: componentState.isLoading
    }

    rows = permitRequestItemList.map((requestPermit, index) => {

        return (
            <RequestCard index={startIndex + index} key={`RequestCard-${index}`} request={requestPermit} />
        );
    });


    return (
        <ListOfRows 
            rows={rows}
            name='בקשות' 
            paigingData={paigingData} 
            handleChenge={event => Formik.setFieldValue('limit', event.currentTarget.value)}
        />
    );
}

export default DisplayManagmentRequestTavim;