import axios from "../api/axios";
import { useAuthContextContext } from '../contexts/AuthContext';
import { useProjectDetailsContext } from "../contexts/ProjectDetailsContext";
import translateErrorCode from "../Helpers/errors/translateErrorCode";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useComponentState } from "./useComponentState";
import { ILoginForm } from "../interface/loginPage.interface";
import { GET_SYSTEM_TABLES, loginApi } from "../api/login.api";

export type TypeResultLogin = {
    isSuccess: boolean,
    message: string | ReactNode,
}

const useLogin = () => {

    const navigate = useNavigate();
    const { loadProjectDetails, updateUserDetails } = useProjectDetailsContext();
    const { componentState, removeAlert, setDangerAlert, setIsLoading, setIsNotLoading } = useComponentState();
    const { login: authLogin } = useAuthContextContext();

    async function loginAndLoadSysTables(values: ILoginForm) {

        try {

            setIsLoading();
            const accessToken = await login(values);

            const isLoadded = await retrieveSystemTables(accessToken);

            if(!isLoadded) {

                setDangerAlert('טבלאות מערכת לא ניתענו');
                return;
            }

            setIsNotLoading();
            navigate('/permit-request/requestManagementPage');

        } catch (error) {

            let message: string | ReactNode = 'בעיה כללית בשרת';

            if (error.response) {

                message = translateErrorCode(error, { BAD_REQUEST: 'כניסה למערכת נכשלה - השרת לא קיבל את כל הנתונים' });
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

            setDangerAlert(message);
        }
    }

    async function retrieveSystemTables(accessToken: string): Promise<true> {

        const config = {
            headers: {
                'Authorization': "Bearer " + accessToken,
                'Content-Type': 'application/json'
            }
        };

        const response2 = await axios.get(GET_SYSTEM_TABLES, config);

        const bean = { ...response2.data.data.bean };

        loadProjectDetails(bean);
        
        return true;
    }

    async function login(values: ILoginForm): Promise<string> {

        const responseLogin = await loginApi(values.projectId, values.userName, values.password);

        const { accessToken, refreshToken, userName, userRole } = responseLogin;

        const userDetails = {
            userName: userName,
            userRole: userRole,
        }

        authLogin(accessToken, refreshToken);
        updateUserDetails(userDetails);

        return accessToken;
    }

    return { loginAndLoadSysTables, componentState, removeAlert, setDangerAlert, setIsLoading, setIsNotLoading };
}


export default useLogin;