import React from 'react';
import Alert from './Alert';
import { AlertColors } from '../../types/alertColors.types';

type Props = {
    isShow: boolean;
    message: string | React.ReactNode;
    onClose?: () => void;
}

const DangerAlert = ({ message, isShow, onClose }: Props) => {

    return <Alert type={AlertColors.DANGER} message={message} isShow={isShow} onClose={onClose} />;
}

export default DangerAlert;