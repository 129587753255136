import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";
import moment from "moment";
import PaymentReportPage from "../../Pages/reports/paymentsReport/PaymentReportPage";
import { useComponentState } from "../../Hooks/useComponentState";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import { GET_PAYMENTS_REPORT } from "../../api/paymentReportApi";
import translateErrorCode from '../../Helpers/errors/translateErrorCode';
import { IComponentState } from "../../interface/IComponentState.interface";

type TypePaymentsReportPageContext = {
    fromDate: string, 
    setFromDate: (fromDate: string) => void, 
    toDate: string, 
    setToDate: (toDate: string) => void, 
    componentState: IComponentState, 
    removeAlert: () => void, 
    handleSearch(signal?: any): Promise<void>,
    displayByPieChart: number, 
    setDisplayByPieChart: (num: number) => void, 
    pRecordsChartArea: any[]
}


export const PaymentsReportPageContext = createContext<TypePaymentsReportPageContext | undefined>(undefined);

const PaymentsReportPageProvider = () => {

    const axiosPrivate = useAxiosPrivate();
    const { componentState, removeAlert, setDangerAlert, setIsLoading, setIsNotLoading } = useComponentState();
    const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [pRecordsChartArea, setPRecordsChartArea] = useState([]);
    const [displayByPieChart, setDisplayByPieChart] = useState(1);
    
    useEffect(() => {
        
        const controller = new AbortController();
        const signal = controller.signal;
        handleSearch(signal);

        return () => {
            console.log("clean up func");
            controller.abort();
        }
    }, []);

    async function handleSearch(signal = undefined) {

        const controller = new AbortController();
        const withAbortSig = signal ? true : false;

        if (!withAbortSig) {
            signal = controller.signal;
        }

        const dataToSend = { 
            from: fromDate, 
            to: toDate 
        };

        try {
            setIsLoading();

            const response = await axiosPrivate.post(GET_PAYMENTS_REPORT, dataToSend, { signal: signal });

            const jsonData = response.data;

            const pRecordsChartAreaTemp = convertRawDataFromServerToChartArea(jsonData.paymentsRecordsGroupByDate);

            setPRecordsChartArea(pRecordsChartAreaTemp);
            setIsNotLoading();
        } catch (error) {

            let message: string | ReactNode = 'בעיה כללית בשרת';

            if (error.response) {

                message = translateErrorCode(error);
            } else if (error.request) {
                console.log(error.request);
            } else { // Something happened in setting up the request that triggered an Error

                message = error.message;
                console.log('user canceled the request', error.message);
            }

            setDangerAlert(message);
        }
    }

    function convertRawDataFromServerToChartArea(datas: any[]) {

        if (!datas || !Array.isArray(datas)) return [];

        return datas.map(data => {
            return {
                name: data.dateAndTime,
                cashAmount: data.cashAmount,
                cashNumberOfUnits: data.cashNumberOfUnits,
                creditCardAmount: data.creditCardAmount,
                creditCardNumberOfUnits: data.creditCardNumberOfUnits,
            }
        });
    }

    return (
        <PaymentsReportPageContext.Provider value={{
            fromDate, setFromDate, toDate, setToDate, componentState, removeAlert, handleSearch,
            displayByPieChart, setDisplayByPieChart, pRecordsChartArea
        }}>
            <PaymentReportPage />
        </PaymentsReportPageContext.Provider>
    );
}

export default PaymentsReportPageProvider;

export const usePaymentsReportPageContext = () => useContext(PaymentsReportPageContext);