import React from 'react';
import Style from '../viewFilesPage.module.css';
import { FileTypes } from '../../../types/fileTypes.types';

const GeneralFileContainerViewer = ({ files, index }) => {

    if (!files || !(Array.isArray(files) && files.length > index))
        return index;

    const file = files[index];

    const isPdf = (file && file.type === FileTypes.PDF_TYPE) ? true : false;

    return (
        <div className={Style.bigContainer}>
            {isPdf ? (
                <object data={file.url} type="application/pdf" className={Style.pdf}></object>
            ) : (
                <img style={{width: '100%'}} className={Style.image} src={file.url} alt={file.lastModifiedString} />
            )}
        </div>
    );
}

export default GeneralFileContainerViewer;