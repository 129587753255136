import React, { FC } from 'react';
import { useProjectDetailsContext } from '../../../../contexts/ProjectDetailsContext';
import { SendMsgOptionType } from '../../../../types/sendMsgOption.types';
import InputTextArea from '../../../../Components/Forms/InputTextArea';
import SmallTitle from '../SmallTitle';
import TextOptionsRow from '../TextOptionsRow';
import InputGroup from '../../../../Components/Forms/InputGroup';
import style from '../../sendEmail.module.css';
import { RiMailSendLine } from 'react-icons/ri';
import Button from '../../../../Components/Buttons/Button';
import { IComponentState } from '../../../../interface/IComponentState.interface';
import { FormikProps } from 'formik';
import { IMessageCitizenModal } from '../../../../interface/messageCitizenModal.interface';

type Props = {
  formik: FormikProps<IMessageCitizenModal>,
  componentState: IComponentState,
  handleClickOptionList: (event: React.MouseEvent<HTMLInputElement>) => void,
}

const SmsContainer: FC<Props> = ({ formik, componentState, handleClickOptionList }) => {

  const { projectDetails } = useProjectDetailsContext();
  const messageConfig = projectDetails.messageConfig;
  const messageSmsConfig = messageConfig.messageSmsConfig;

  const { values, handleBlur, handleChange, errors, touched } = formik;
  const { isLoading } = componentState;
  const textOptions = messageSmsConfig.generalSmsConfig.contentOptions;

  let errorChecksInputAndFreeText = '';

  if (touched?.sms && (touched.sms.chosenTextOptionCodes || touched.sms.freeText)) {

    if (errors?.sms?.freeText) errorChecksInputAndFreeText = errors.sms.freeText;
  }

  const sendEmailBtnDisabled = !formik.isValid || isLoading;

  if (SendMsgOptionType.SMS !== formik.values.sendViaEmail) {
    return null;
  }

  return (
    <>
      <div className={`container ${style.emailContainer}`}>

        <div className='row'>
          <div className='col-4'>
            <InputGroup
              name='citizenMobileNum'
              size='s'
              label='מספר סלולרי של האזרח'
              value={values.sms.mobileNum} disabled={true}
            />
          </div>
        </div>

        <div className='row mt-1'>
          <div className='col-12'>
            <SmallTitle title='גוף המסרון' />
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <TextOptionsRow
              handleBlur={handleBlur}
              handleClick={handleClickOptionList}
              contentOptions={textOptions}
              values={values}
              errorChecksInputAndFreeText={errorChecksInputAndFreeText}
              name={'sms.chosenTextOptionCodes'}
            />
          </div>
        </div>

        <div className='row mt-2'>
          <div className='col-12'>
            <InputTextArea
              placeholder='מלל חופשי...'
              name='sms.freeText'
              value={values.sms.freeText}
              rows={3}
              inputStyle={{ fontSize: '1.2rem' }}
              handleBlur={handleBlur}
              handleChange={handleChange} />
          </div>
        </div>

      </div>
      <div className='row mt-2 mb-2'>
        <div className='col-12'>
          <Button disabled={sendEmailBtnDisabled} text='שלח מסרון' endIcon={<RiMailSendLine />} onClick={formik.handleSubmit} />
        </div>
      </div>
    </>
  );

}

export default SmsContainer;