import React, { FC } from 'react';
import style from '../forms.module.css';

type Props = {
    label: string,
    value: any,
    name: string,
    id: string,
    isChecked: boolean,
    onClick: (event: React.MouseEvent<HTMLInputElement>) => void,
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void,
    disabled?: boolean,
    inlineFilx?: boolean,
}

const CheckBtn: FC<Props> = ({
    value, id, name, onClick, label, isChecked,
    onBlur = () => { },
    disabled = false,
    inlineFilx = false
}) => {

    const inlineFilxStyle = inlineFilx ? 'inline-flex' : 'flex';

    return (
        <div className={style.checkBoxForm} style={{ display: inlineFilxStyle }}>
            <input
                className={style.formCheckInput}
                type="checkbox"
                value={value}
                id={id}
                name={name}
                checked={isChecked}
                disabled={disabled}
                onBlur={onBlur}
                onClick={onClick}
                onChange={(e) => { }}
            />
            <label className={style.formCheckLabel} htmlFor={id}>
                {label}
            </label>
        </div>
    );
}


export default CheckBtn;