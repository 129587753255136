import React from 'react';
import { usePermitDetailsPageContext } from '../../../contexts/permit/PermitDetailsPageContext';
import { GeneralModal as SuccessModal, ContinueModalBtn } from '../../../Components/GeneralModal/modal.import'
import UploadFileModal from '../../../features/uploadFile/UploadFileModal';
import HistoryModal from '../../../features/History/HistoryModal';
import LoadingModal from '../../../Components/LoadingModal/LoadingModal';
import PageTitle from '../../../Components/pageTitle/PageTitle';
import PermitDetailsButtonsBox from './components/PermitDetailsButtonsBox';
import { PermitStatusType } from '../../../types/permit.types';
import DangerAlert from '../../../Components/Alerts/DangerAlert';
import ViewPermitForm from './components/ViewPermitForm';
import { convertPermitStatusCodeToText } from '../../../Helpers/conversion/convertTypeCodeToText';

const ViewPermit = () => {
  const { handleCloseModal, refHistoryModal,
    componentState, successModalIsShown, Formik, uploadFileModal, closeUploadFileModal,
    handleUploadFile, errorFileModal, isLoadingFileModal } = usePermitDetailsPageContext();

  const { values } = Formik;

  const statusName = convertPermitStatusCodeToText(values.statusTav);
  const colorCode = (PermitStatusType.ACTIVE === values.statusTav) ? '#008000' : '#ff0000';

  const url_to_nav = '/permits/permitManagementPage';

  const statusTag = <span style={{ color: colorCode }}>{statusName}</span>

  const pageTitle = (<span> {'צפייה בתו מספר ' + values.permitId + ' '} ({statusTag}) </span>);

  return (
    <>

      <UploadFileModal
        isShow={uploadFileModal}
        error={errorFileModal}
        isLoading={isLoadingFileModal}
        closingFun={closeUploadFileModal}
        handleUploadFile={file => handleUploadFile(values.personId, file)} />

      <HistoryModal ref={refHistoryModal} />

      <SuccessModal
        isShow={successModalIsShown}
        title='תו נשמר בהצלחה'
        content='לאחר שמירת תו תופנה לדף רשימת תווים'
        footer={<ContinueModalBtn onClick={() => handleCloseModal(url_to_nav)} label={'חזור לרשימת התווים'} />}
        closingFun={() => handleCloseModal(url_to_nav)} />

      {componentState.isLoading ? <LoadingModal /> : ''}

      <div className='row'>
        <div className='col-12'>
          <DangerAlert isShow={componentState.hasError} message={componentState.errorMessage} />
        </div>
      </div>
      <div className='row mt-1 mb-1'>
        <div className='col-12'>
          <PageTitle title={pageTitle} />
        </div>
      </div>
      <div className='row mb-3'>
        <div className='col-12'>
          <PermitDetailsButtonsBox />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <ViewPermitForm />
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-12'>
          <PermitDetailsButtonsBox />
        </div>
      </div>
    </>
  );
}

export default ViewPermit;