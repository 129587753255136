import React from 'react';
import {PermitStatusType} from '../../../types/permit.types';
import SelectInput from './SelectInput';


type SelectPermitStatusProps = {
  name: string;
  value: any;
  handleChange: (value: any) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  required: boolean;
  disabled: boolean;
  errorMsg: string;
}

const SelectPermitStatus = ({ errorMsg, required, disabled, handleChange, handleBlur, value, name }: SelectPermitStatusProps) => {

  const selects = [
    { name: 'פעיל', value: PermitStatusType.ACTIVE },
    { name: 'לא פעיל', value: PermitStatusType.NOT_ACTIVE },
    { name: 'בוטל', value: PermitStatusType.CANCELED },
    { name: 'מושהה', value: PermitStatusType.SUSPENDED }
  ];

  return (
    <SelectInput
      handleChange={handleChange}
      name={name}
      label={'סטטוס תו'} 
      value={value}
      selects={selects}
      disabled={disabled}
      required={required}
      handleBlur={handleBlur}
      errorMsg={errorMsg}
    />
  );
}

SelectPermitStatus.defaultProps = {
  value: '',
  required: false,
  disabled: false,
  errorMsg: undefined,
  handleChange: () => { },
  handleBlur: () => { },
  name: '',
  label: 'איזור'
}

export default SelectPermitStatus;