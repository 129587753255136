import React, { ChangeEvent } from 'react';
import { useProjectDetailsContext } from '../../../contexts/ProjectDetailsContext';
import SelectInput from './SelectInput';

interface PermitType {
    key: string;
    value: number;
    name: string;
}

interface SelectPermitTypeProps {
    name: string;
    errorMsg?: string;
    required?: boolean;
    handlePermitTypeChange: (name: string, value: number) => void;
    handleBlur: (e: React.FocusEvent<any>) => void;
    value: number;
}

const SelectPermitType: React.FC<SelectPermitTypeProps> = ({ name, errorMsg, required, handlePermitTypeChange, handleBlur, value }) => {

    const { projectDetails } = useProjectDetailsContext();
    const taveiDayarSiteConfig = projectDetails.taveiDayarSiteConfig;

    let permitTypes: PermitType[] = [
        { key: 'permitTypes1', value: -1, name: 'צריך לבחור...' },
        { key: 'permitTypes2', value: 0, name: 'רגיל' },
    ];

    if (taveiDayarSiteConfig.gilHaZahavOption) {
        permitTypes.push({ key: 'permitTypes3', value: 1, name: 'זהב' })
    }

    if (taveiDayarSiteConfig.studentOption) {
        permitTypes.push({ key: 'permitTypes4', value: 2, name: 'סטודנט' })
    }
    
    // TODO handicap is code 3, need to check.


    if (taveiDayarSiteConfig.municipalEmployeeOption) {
        permitTypes.push({ key: 'permitTypes5', value: 4, name: 'עובד עירייה' })
    }

    return (
        <SelectInput
            name={name}
            label={'סוג תו'}
            selects={permitTypes}
            required={required}
            handleBlur={handleBlur}
            handleChange={(event: ChangeEvent<HTMLSelectElement>) => {
                
                const value = parseInt(event.currentTarget.value);
                const name = event.currentTarget.name;
                handlePermitTypeChange(name, value);
            }}
            value={value}
            errorMsg={errorMsg}
        />
    );
}

export default SelectPermitType;