import React from 'react';
import {PermitStatusType} from '../../../../types/permit.types';

const SpanPermitStatus = ({ permitStatusCode, statusName }) => {

    let statusBadgeClaess = '';

    if (permitStatusCode === PermitStatusType.ACTIVE) {

        statusBadgeClaess = 'badge bg-success';
    } else if (permitStatusCode === PermitStatusType.NOT_ACTIVE) {

        statusBadgeClaess = 'badge bg-danger';
    } else if (permitStatusCode === PermitStatusType.SUSPENDED) {

        statusBadgeClaess = 'badge bg-danger';
    } else if (permitStatusCode === PermitStatusType.CANCELED) {

        statusBadgeClaess = 'badge bg-danger';
    } else {

        statusBadgeClaess = 'bg-light text-dark';
    }

    return (
        <span className={statusBadgeClaess} style={{ fontSize: '1rem' }}>{statusName}</span>
    );
}

export default SpanPermitStatus;